<template>
  <v-container fluid>
    <v-card
      class="d-flex justify-center flex-column mx-auto my-6 flat"
      width="374"
      color="#fff"
    >
      <img :src="require('@/assets/logo.svg')" alt="Logo" class="logo" />
      <v-card-title class="d-flex justify-center pa-0 mt-6"
        >自動ログイン</v-card-title
      >
      <v-card-text class="d-flex justify-center flex-column">
        <div class="text-center" v-show="this.isProcessing">
          自動ログインしています。
        </div>
        <div class="text-center" v-show="this.isFailure">
          自動ログインに失敗しました。
        </div>
      </v-card-text>
      <v-card-text class="d-flex justify-center flex-column">
        <a style="color:#000;" href="#" @click.prevent.stop="toLoginPage()">ログイン画面へ</a>
      </v-card-text>
    </v-card>
  </v-container>
</template>
  
<script>
import { signInWithCustomToken , getAuth, browserSessionPersistence , setPersistence } from "firebase/auth";
import { httpsCallable, getFunctions } from "firebase/functions";

export default {
  props: ['collisionId'],
  data() {
    return {
      isProcessing: true,
      isFailure: false
    };
  },
  // 初期表示時データ読み込み
  async created() {
    // ローディング開始
    this.$root.loading = true;

    var data = {
      funcName: "autoLoginAuthentication",
      collisionId: this.collisionId,
    }
    // メールアドレス認証
    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";
    const schoolAidHomePublic = httpsCallable(functions, "schoolAidHomePublic");
    const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");

    // 自動ログイン認証処理
    var token = null;
    var paramNextPage = null;
    await schoolAidHomePublic(data).then((ret) => {
      token = ret.data.token;
      paramNextPage = ret.data.nextPage;
      this.isProcessing = false,
      this.isFailure = false;
    }).catch(() => {
      this.isProcessing = false,
      this.isFailure = true;
      this.$root.loading = false;
    });

    // トークンチェック
    if(token == null){
      this.isProcessing = false,
      this.isFailure = true;
      return;
    }

    const auth = getAuth();
    await setPersistence(auth, browserSessionPersistence);

    // firebase認証処理
    var uid = null;
    await signInWithCustomToken(auth, token).then((data) => {
      uid = data.user.uid;
    }).catch(() => {
      this.isProcessing = false,
      this.isFailure = true;
      this.$root.loading = false;
    });

    console.log(uid);

    // ユーザー情報取得
    await schoolAidHomeLogined({funcName:"getLoginInfo"}).then((res) => {
      this.$root.loading = false;
      var userKbn = res.data.userKbn;
      var userNm = res.data.userNm;
      var consentFlg = res.data.consentFlg;
      var mailAddressAuthFlg = res.data.mailAddressAuthFlg;
      var adminFlg = res.data.adminFlg;
      var schoolName = res.data.schoolName;
      this.$root.needCourseSelect = res.data.needCourseSelect;
      this.$root.adminFlg = res.data.adminFlg;
      this.$root.students = res.data.students;
      this.$root.uploadPath = "temp/user/{uid}/{uuid}".replace("{uid}", uid);

      if (userKbn !== undefined && consentFlg !== undefined && mailAddressAuthFlg !== undefined) {

        // 学校名をヘッダにセット
        this.$root.siteName = schoolName;
        // ユーザー区分に応じて背景色を変更
        this.$root.userKbn = userKbn;
        this.$root.userNm = userNm;

        if(consentFlg === true){
          // ログインフラグを立てる
          this.$root.isLogin = true;
          var nextPage = "";
          
          // ユーザー区分に応じた制御
          switch (userKbn) {
            case "0":
              nextPage = "StudentTopPage";
              this.$root.isStudentMenu = true;
              break;
            case "1":
              nextPage = "GuardianTopPage";
              this.$root.isParentMenu = true;
              break;
            case "8":
              nextPage = "TeacherTopPage";  
              this.$root.isKyoinMenu = true;
              if(adminFlg == true){
                this.$root.menuPattern = 2;
              } else {
                this.$root.menuPattern = 1;
              }
              break;
            case "9":
              nextPage = "TeacherTopPage";
              this.$root.isKyoinMenu = true;
              this.$root.menuPattern = 2;
              break;
            default:
              break;
          }

          // トップページ以外の場合設定
          if(paramNextPage && paramNextPage != "TopPage") {
            nextPage = paramNextPage;
          }
          // 利用規約同意済
          this.$router.push({
            name: nextPage
          });
        } else {
          // 利用規約未同意
          this.$router.push({
            name: "TermsOfService"
          }); 
        }
      } else {
        alert("システムエラー");
      }
    }).catch((e) => {
      alert(e.message);
      this.$root.loading = false;
    });

    // ローディング終了
    this.$root.loading = false;
  },
  methods: {
    // ログイン画面へ
    toLoginPage() {
      this.$router.push("/");
    },
  },
};
</script>

<style>
.logo {
  display: block;
  position: relative;
  overflow: hidden;
}
</style>