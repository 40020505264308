<template>
    <v-card-text class="d-flex justify-center flex-column">
        <!-- ヘッダー -->
        <v-sheet tile height="54" class="d-flex">
            <v-row dense>
                <v-col cols="4" class="d-flex justify-start">
                    <!-- 前月へボタン -->
                    <v-btn color="orange" @click="changeMonth(true)" :disabled="nendoStartFlg">
                        前月へ
                    </v-btn>
                </v-col>
                <v-col cols="4" class="d-flex justify-center">
                    {{ year }} 年 {{ month }} 月
                </v-col>
                <v-col cols="4" class="d-flex justify-end">
                    <!-- 次月へボタン -->
                    <v-btn color="orange" @click="changeMonth(false)" :disabled="nextLockFlg">
                        次月へ
                    </v-btn>
                </v-col>
            </v-row>
        </v-sheet>

        <v-sheet>
            <!-- カレンダー上部ラベル -->
            <div class="d-flex justify-space-around">
                <div v-for="w in dayOfWeek"
                    :key="w.value"
                    :class="w.color"
                    >
                    {{ w.label }}
                </div>
            </div>
            <!-- カレンダー本体 -->
            <v-calendar ref="calendar" v-model="focus" locale="ja-jp" hide-header>
                <template v-slot:day-label="{ date, future, weekday }">
                    <div v-bind:class="cellStyle(date, future, weekday)">
                        <!-- 処理対象セルのみ表示 -->
                        <v-row no-gutters>
                            <v-col class="d-flex justify-center">
                                {{dayFormat(date)}}
                                <v-tooltip top v-if="getData(date).tbcFlg == true">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon small v-bind="attrs" v-on="on" color="red">mdi-information-outline</v-icon>
                                    </template>
                                    <span>出欠と早退の確定区分に齟齬があります。確認してください。</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col class="d-flex">
                                <div class="caption ml-1">出欠⇒</div>
                                <div class="body-1 ml-1" v-if="getData(date).syukketuApprovalFlg == false">{{getData(date).syukketuKbnNm}}</div>
                                <div class="body-1 ml-1" v-else-if="getData(date).syukketuApprovalFlg == true">{{getData(date).syukketuApprovalKbnNm}}</div>
                                <div class="body-1 ml-1" v-else>ー</div>
                                :
                                <div class="body-1 red--text" v-if="getData(date).syukketuInputFlg == true && getData(date).syukketuApprovalFlg == false">
                                    {{getData(date).syukketuStatus}}
                                </div>
                                <div class="body-1" v-else>
                                    {{getData(date).syukketuStatus}}
                                </div>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col class="d-flex">
                                <div class="caption ml-1">早退⇒</div>
                                <div class="body-1 ml-1" v-if="getData(date).sotaiApprovalFlg == false">{{getData(date).sotaiKbnNm}}</div>
                                <div class="body-1 ml-1" v-else-if="getData(date).sotaiApprovalFlg == true">{{getData(date).sotaiApprovalKbnNm}}</div>
                                <div class="body-1 ml-1" v-else>ー</div>
                                :
                                <div class="body-1 red--text" v-if="getData(date).sotaiInputFlg == true && getData(date).sotaiCancelFlg == false && getData(date).sotaiApprovalFlg == false">
                                    {{getData(date).sotaiStatus}}
                                </div>
                                <div class="body-1" v-else>
                                    {{getData(date).sotaiStatus}}
                                </div>
                            </v-col>
                        </v-row>
                        <v-row no-gutters>
                            <v-col class="d-flex justify-start">
                                <v-btn x-small color="red lighten-4" class="ml-2 mb-2" @click="onClickSyukketuApproval(date)" :disabled="!activeCell(date, future)">
                                    出欠
                                </v-btn>
                            </v-col>
                            <v-col class="d-flex justify-end">
                                <v-btn x-small color="blue lighten-4" class="mr-2 mb-2" @click="onClickSoutaiApproval(date)" :disabled="!activeCell(date, future)">
                                    早退
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </template>
            </v-calendar>
        </v-sheet>

        <!-- 出欠確定モーダル -->
        <v-dialog
            v-model="attendanceApprovalDialog"
            max-width="500"
            min-height="260"
            max-height="260"
            persistent
            scrollable
        >
            <v-card
                class="d-flex flex-column mx-auto my-auto flat"
                color="#fff"
                min-height="260"
            >
                <v-card-actions class="my-0">
                    <v-row dense>
                        <v-col cols="2" justify="center">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn small v-bind="attrs" v-on="on" color="grey" @click="attendanceApprovalDialog = false">
                                        <v-icon color="white">mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>閉じる</span>
                            </v-tooltip>
                        </v-col>
                        <v-col cols="8" class="d-flex justify-center">
                            出欠確定({{ this.studentNm }})
                        </v-col>
                        <v-col cols="2" justify="center">
                        </v-col>
                    </v-row>
                </v-card-actions>
                <CalendarAttendanceApproval
                    v-if="attendanceApprovalDialog"
                    :studentId=studentId
                    :param_yyyymmdd=modalCallingDate
                    @changeSyukketuApproval="changeSyukketuApproval"
                />
            </v-card>
        </v-dialog>

        <!-- 早退確定モーダル -->
        <v-dialog
            v-model="leavingApprovalDialog"
            max-width="500"
            min-height="260"
            max-height="260"
            persistent
            scrollable
        >
            <v-card
                class="d-flex flex-column mx-auto my-auto flat"
                color="#fff"
                min-height="260"
            >
                <v-card-actions class="my-0">
                    <v-row dense>
                        <v-col cols="2" justify="center">
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn small v-bind="attrs" v-on="on" color="grey" @click="leavingApprovalDialog = false">
                                        <v-icon color="white">mdi-close</v-icon>
                                    </v-btn>
                                </template>
                                <span>閉じる</span>
                            </v-tooltip>
                        </v-col>
                        <v-col cols="8" class="d-flex justify-center">
                            早退確定({{ this.studentNm }})
                        </v-col>
                        <v-col cols="2" justify="center">
                        </v-col>
                    </v-row>
                </v-card-actions>
                <CalendarLeavingApproval
                    v-if="leavingApprovalDialog"
                    :studentId=studentId
                    :param_yyyymmdd=modalCallingDate
                    @changeSotaiApproval="changeSotaiApproval"
                />
            </v-card>
        </v-dialog>
    </v-card-text>
</template>
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import { format, parse } from 'date-fns';
import { ja } from 'date-fns/locale';
import CalendarAttendanceApproval from './CalendarAttendanceApproval.vue'
import CalendarLeavingApproval from './CalendarLeavingApproval.vue'

export default {
    props: ['studentId', 'studentNm', 'param_yyyymmdd'],
    components: {
        CalendarAttendanceApproval,
        CalendarLeavingApproval,
    },
    watch: {
        focus: function(newVal, oldVal) {
            console.log("change focus", newVal, oldVal);
            var now = new Date();
            var newValDate = parse(newVal, 'yyyy-MM-dd', new Date());
            if(now.getFullYear() == newValDate.getFullYear() && now.getMonth() == newValDate.getMonth()){
                this.nextLockFlg = true;
            } else {
                this.nextLockFlg = false;
            }
        },
    },
    data: () => ({
        // ヘッダー用変数
        // 指定年
        year: "",
        // 指定月
        month: "",
        // 指定日(YYYY-MM-DD)
        focus: "",
        // 次月へロックフラグ
        nextLockFlg: false,
        // カレンダー表示時点の指定日
        targetDate:"",
        // 年度開始月フラグ
        nendoStartFlg: false,

        // カレンダーヘッダーラベル
        dayOfWeek: [
            { value: 0, label: "日", color: "red--text" },
            { value: 1, label: "月", color: "" },
            { value: 2, label: "火", color: "" },
            { value: 3, label: "水", color: "" },
            { value: 4, label: "木", color: "" },
            { value: 5, label: "金", color: "" },
            { value: 6, label: "土", color: "blue--text" },
        ],
        // カレンダー日付セルスタイル
        dayOfWeekStyle: ['sun','','','','','','sat'],
        // カレンダー表示データ
        dateList: [],

        // 出欠確定用
        attendanceApprovalDialog: false,
        // 早退確定用
        leavingApprovalDialog: false,
        // 確定モーダル呼出用日付
        modalCallingDate:"",
    }),
    async created() {
        console.log("CalendarEditer created", this.param_yyyymmdd);
        var date = parse(this.param_yyyymmdd, 'yyyy-MM-dd', new Date());
        // 呼出時点の指定日を退避
        this.targetDate = format(date, 'yyyy-MM-dd', { locale: ja });
        this.focus = format(date, 'yyyy-MM-dd', { locale: ja });
        this.year = format(date, 'yyyy', { locale: ja });
        this.month = format(date, 'M', { locale: ja });
        await this.reload();
    },
    methods: {
        // 指定月の登録状況を取得
        async reload(){
            console.log("CalendarEditer reload");
            this.$root.loading = true;
            const functions = getFunctions(this.$firebase);
            functions.region = "asia-northeast1";
            const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");

            const data = {
                funcName: "loadCalendarEditer",
                studentId: this.studentId,
                yyyymmdd: this.focus,
            };

            await schoolAidHomeLogined(data).then((res) => {
                this.dateList = res.data.dateList;
                this.nendoStartFlg = res.data.nendoStartFlg;
            }).catch((e) =>{
                console.log(e);
                this.$root.loading = false;
            });

            // ローディング終了
            this.$root.loading = false;
        },
        // カレンダー制御用 start *********************************************************************
        // カレンダーの月変更
        async changeMonth(flg){
            if(flg){
                this.$refs.calendar.prev();
            } else {
                this.$refs.calendar.next();
            }
            console.log("changeMonth", this.focus);
            await this.reload();
            var buff = parse(this.focus, 'yyyy-MM-dd', new Date());
            this.year = format(buff, 'yyyy', { locale: ja });
            this.month = format(buff, 'M', { locale: ja });
        },
        // セルの日付書式設定
        dayFormat: function(date){
            var buff = parse(date, 'yyyy-MM-dd', new Date());
            // 月初判定
            if(buff.getDate() == 1){
                return format(buff, 'M/d', { locale: ja })
            } else {
                return format(buff, 'd', { locale: ja })
            }
        },
        // 表示情報取得
        getData(date) {
            for(const row of this.dateList){
                if(row.yyyymmdd == date){
                    return row;
                }
            }
            var ret = {
                // 出欠のパラメータ
                syukketuInputFlg:false,
                syukketuApprovalFlg:false,
                syukketuStatus:"ー",
                syukketuKbnNm:"ー",
                syukketuApprovalKbnNm:"ー",
                
                // 早退のパラメータ
                sotaiInputFlg:false,
                sotaiCancelFlg:false,
                sotaiApprovalFlg:false,
                sotaiStatus:"ー",
                sotaiKbnNm:"ー",
                sotaiApprovalKbnNm:"ー",
            }
            return ret;
        },
        // セルの背景色制御
        cellStyle(date, future, weekday){
            if(future) {
                return 'lock';
            } else {
                var existsFlg = false;
                for(const row of this.dateList){
                    if(row.yyyymmdd == date)existsFlg = true;
                }
                if(existsFlg){
                    return this.dayOfWeekStyle[weekday];
                } else {
                    return 'lock';
                }
            }
        },
        // セル内の表示制御
        activeCell(date, future){
            if(future) {
                return false;
            } else {
                var existsFlg = false;
                for(const row of this.dateList){
                    if(row.yyyymmdd == date)existsFlg = true;
                }
                if(existsFlg){
                    return true;
                } else {
                    return false;
                }
            }
        },
        // 出欠確定モーダル呼出
        onClickSyukketuApproval(date) {
            console.log("onClickSyukketuApproval", date);
            this.modalCallingDate = date;
            this.attendanceApprovalDialog = true;
        },
        // 出欠確定モーダルからのフィードバック
        changeSyukketuApproval(updateDate, approvalFlg, approvalKbnNm) {
            console.log("changeSyukketuApproval", this.targetDate, updateDate, approvalFlg, approvalKbnNm);
            if(this.targetDate == updateDate) {
                this.$emit('parentReload');
            }
            this.reload();
            this.attendanceApprovalDialog = false;
        },
        // 早退確定モーダル呼出
        onClickSoutaiApproval(date) {
            console.log("onClickSoutaiApproval", date);
            this.modalCallingDate = date;
            this.leavingApprovalDialog = true;
        },
        // 早退確定モーダルからのフィードバック
        changeSotaiApproval(updateDate, approvalFlg, approvalKbnNm) {
            console.log("changeSotaiApproval", this.targetDate, updateDate, approvalFlg, approvalKbnNm);
            if(this.targetDate == updateDate) {
                this.$emit('parentReload');
            }
            this.reload();
            this.leavingApprovalDialog = false;
        },
        // カレンダー制御用 end *********************************************************************
    },
};
</script>

<style scoped>
.lock {
    background: rgba(97, 97, 97) !important;
}
.sun {
    background: rgba(255, 204, 188) !important;
}
.sat {
    background: rgba(207, 216, 220) !important;
}
</style>