<template>
    <ValidationObserver ref="obs" v-slot="{ invalid }">
      <v-tabs fixed-tabs>
        <v-tab href="#input">登録</v-tab>
        <v-tab href="#history">履歴</v-tab>

        <!-- 登録タブ -->
        <v-tab-item value="input">
          <v-card-text class="d-flex justify-center flex-column">
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <ValidationProvider name="早退事由" rules="required|max:50" v-slot="{ errors }">
                  <v-text-field
                    v-model="leavingReason"
                    prepend-icon="$cet_required"
                    label="早退事由"
                    placeholder="早退事由"
                    :error-messages="errors[0]"
                    outlined
                    dense
                    :counter="50"
                    hide-details="auto"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <ValidationProvider name="早退事由(補足)" rules="max:50" v-slot="{ errors }">
                  <v-text-field
                    v-model="leavingReasonEtc"
                    label="早退事由(補足)"
                    placeholder="早退事由(補足)"
                    :error-messages="errors[0]"
                    outlined
                    dense
                    :counter="50"
                    hide-details="auto"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <ValidationProvider
                  name="教員用メモ"
                  rules="required"
                  v-slot="{ errors }"
                  :skipIfEmpty="false"
                >
                  <v-select
                    label="教員用メモ（保護者側へは非通知）"
                    item-text="label"
                    item-value="statusKbn"
                    v-model="selectedComStatus"
                    :items="comStatus"
                    :error-messages="errors[0]"
                    outlined
                    hide-details="auto"
                  ></v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col dense cols="12" class="d-flex">
                <v-menu
                  ref="menu"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="leavingTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                <template v-slot:activator="{ on, attrs }">
                  <ValidationProvider name="下校開始予定時刻" rules="required" v-slot="{ errors }">
                    <v-text-field
                      v-model="leavingTime"
                      prepend-icon="$cet_required"
                      label="下校開始予定時刻"
                      placeholder="下校開始予定時刻"
                      :error-messages="errors[0]"
                      readonly
                      outlined
                      dense
                      clearable
                      hide-details="auto"
                      v-bind="attrs"
                      v-on="on"
                      ></v-text-field>
                  </ValidationProvider>
                </template>
                <v-time-picker
                  v-if="menu2"
                  v-model="leavingTime"
                  :allowed-hours="allowedHours"
                  :allowed-minutes="allowedStep"
                  full-width
                  @click:minute="$refs.menu.save(leavingTime)"
                  ></v-time-picker>
                </v-menu>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="green" v-bind="attrs" v-on="on" small class="ml-2 black--text" @click="setNowTime">現在時刻を設定</v-btn>
                  </template>
                  <span>早退予定時刻に現在時刻(10分刻み)を設定します</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <v-checkbox
                  class="mt-0"
                  v-model="cancelFlg"
                  label="早退予定の取り消し"
                  hide-details="auto" />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <ValidationProvider name="コメント" rules="max:150" v-slot="{ errors }">
                  <v-text-field
                    v-model="comment"
                    label="コメント"
                    placeholder="コメント"
                    :error-messages="errors[0]"
                    outlined
                    dense
                    :counter="150"
                    hide-details="auto"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <v-checkbox class="mt-0" :disabled="!parentMailAuthFlg" v-model="leavingMailFlg" label="保護者へ通知する" hide-details="auto" />
              </v-col>
            </v-row>
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <ValidationProvider name="通知タイトル" rules="max:30" v-slot="{ errors }">
                  <v-text-field class="mb-3"
                    v-model="mailTitle"
                    label="通知タイトル"
                    :disabled="!leavingMailFlg"
                    :error-messages="errors[0]"
                    outlined
                    dense
                    :counter="30"
                    hide-details="auto"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="accent" class="black--text" @click="sotaiSave()"
            :disabled="pastLockFlg ? pastLockFlg : invalid" width="100%">
              更新
            </v-btn>
          </v-card-actions>
        </v-tab-item>
        <!-- 履歴タブ -->
        <v-tab-item value="history">
          <v-list>
            <v-list-item-group  v-if="history.length">
              <v-list-item v-for="row in history" :key="row.postDateTime" :style="changeListColor(row)">
                <v-list-item-content>
                  <v-list-item-subtitle>
                    更新日時：{{ row.postDate }}
                    <v-icon x-large v-if="row.userKbn == '1'">$parent</v-icon>
                    <v-icon x-large v-if="row.userKbn == '8'">$teacher</v-icon>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>更新者：{{ row.userNm }}</v-list-item-subtitle>
                  <v-list-item-subtitle class="wrap-text" v-if="row.leavingReason">早退事由：{{ row.leavingReason }}</v-list-item-subtitle>
                  <v-list-item-subtitle class="wrap-text" v-if="row.leavingReasonEtc">早退事由(補足)：{{ row.leavingReasonEtc }}</v-list-item-subtitle>
                  <v-list-item-subtitle class="wrap-text" v-if="row.comStatusName">保護者への連絡：{{ row.comStatusName }}</v-list-item-subtitle>
                  <v-list-item-subtitle class="wrap-text" v-if="row.leavingTime">予定開始予定時刻：{{ row.leavingTime }}</v-list-item-subtitle>
                  <v-list-item-subtitle class="wrap-text" v-if="row.comment">コメント：{{ row.comment }}</v-list-item-subtitle>
                  <v-list-item-subtitle class="wrap-text" v-if="row.userKbn=='8' && row.leavingMailFlg">通知タイトル：{{ row.mailTitle }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <v-list-item-group  v-if="!history.length" color="primary">
              <v-list-item>履歴はありません</v-list-item>
            </v-list-item-group>
          </v-list>
        </v-tab-item>
      </v-tabs>
    </ValidationObserver>
</template>
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import { format, parse } from 'date-fns';
import { ja } from 'date-fns/locale';
export default {
  props: ['studentId', 'parentMailAuthFlg', 'param_yyyymmdd'],
  data() {
    return {
      yyyymmdd: "",
      cancelFlg: false,
      leavingReason: "",
      leavingReasonEtc: "",
      selectedComStatus: { label: "連絡済(お迎えなし)", statusKbn: 4 }, //初期値
      comStatus: [
        { label: "連絡済(お迎えなし)", statusKbn: 4 },
        { label: "連絡済(お迎えあり)", statusKbn: 3 },
        { label: "保護者からの連絡", statusKbn: 1 },
        { label: "連絡取れず", statusKbn: 2 },
        { label: "未連絡", statusKbn: 0 },
      ],
      leavingTime: "",
      comment: "",
      mailTitle: "",
      history: "",
      leavingMailFlg: false,
      isListLoding: false,
      pastLockFlg: false,

      menu2: false,
    };
  },
  // 初期表示時データ読み込み
  async created() {
    console.log("LeavingRegist created");
    var date = parse(this.param_yyyymmdd, 'yyyy-MM-dd', new Date());
    this.yyyymmdd = format(date, 'yyyy-MM-dd', { locale: ja });
    this.leavingMailFlg = this.parentMailAuthFlg;
    await this.reload();
  },
  methods: {
    // タイムピッカーの入力制限設定
    allowedHours: v => 5 < v && v < 24,
    allowedStep: m => m % 10 === 0,
    // ページ再読込
    async reload() {
      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      const data = {
        funcName: "loadLeavingRegist",
        yyyymmdd: this.yyyymmdd,
        studentId: this.studentId
      };
      await schoolAidHomeLogined(data).then((res) => {
        this.leavingReason = res.data.leavingReason;
        this.leavingReasonEtc = res.data.leavingReasonEtc;
        this.selectedComStatus = res.data.comStatusKbn;
        this.leavingTime = res.data.leavingTime;
        this.cancelFlg = res.data.cancelFlg;
        this.history = res.data.history;
        this.pastLockFlg = res.data.pastLockFlg;
      }).catch((e) =>{
        console.log(e.message);
        this.error = e.message;
        this.$root.loading = false;
      });
      // ローディング終了
      this.$root.loading = false;
    },
    // 早退設定保存
    async sotaiSave() {
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      var data = {
        funcName: "saveLeavingRegist",
        yyyymmdd: this.yyyymmdd,
        studentId: this.studentId,  
        leavingReason: this.leavingReason,
        leavingReasonEtc: this.leavingReasonEtc,
        comStatusKbn: this.selectedComStatus,
        leavingTime: this.leavingTime,
        cancelFlg: this.cancelFlg,
        comment: this.comment,
        leavingMailFlg: this.leavingMailFlg,
        mailTitle : this.mailTitle
      };
      await schoolAidHomeLogined(data).then((res) => {
        // ローディング非表示
        this.$root.loading = false;
        console.log(res.data);
        this.$emit('changeSotai', this.studentId, this.cancelFlg, (this.cancelFlg)?"キャンセル済":"登録済");
      }).catch((e) =>{
        console.log(e.message);
        this.error = e.message;
        this.$root.loading = false;
      });
      await this.reload();
    },
    setNowTime() {
      var dt = new Date();
      var minutes = dt.getMinutes();
      if(minutes > 50) {
        dt.setHours(dt.getHours() + 1);
        dt.setMinutes(0);
      } else {
        var offsetMinutes = minutes % 10;
        dt.setMinutes(dt.getMinutes() + 10 - offsetMinutes);
      }
      this.leavingTime = format(dt, "HH:mm", { locale: ja });
    },
    // 履歴の明細色変更
    changeListColor(row){
      if(row.userKbn == '1') {
        return 'background-color: #FFF8E1';
      } else if(row.userKbn == '8') {
        return 'background-color: #E8EAF6';
      }
    }
  },
};
</script>

<style scoped>
.wrap-text{
  white-space: normal;
}
</style>