<template>
    <div>
        <v-navigation-drawer v-model="primaryMenu" fixed temporary>
            <v-list>
                <v-list-item v-for="item in primaryMenuItems" :key="item.id" link>
                    <!-- サブテキスト設定時 -->
                    <v-tooltip right v-if="item.text">
                        <template v-slot:activator="{ on, attrs }">
                            <v-list-item-content v-bind="attrs" v-on="on" @click="item.action">
                                <v-list-item-title>
                                    <v-icon>{{ item.icon }}</v-icon>
                                    {{ item.name }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <span>{{ item.text }}</span>
                    </v-tooltip>
                    
                    <!-- サブテキスト未設定時 -->
                    <v-list-item-content @click="item.action" v-if="!item.text">
                        <v-list-item-title>
                            <v-icon>{{ item.icon }}</v-icon>
                            {{ item.name }}
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
export default {
    data() {
        return {
            primaryMenu: false,

            primaryMenuItems: [
                // {
                //     id: 0,
                //     icon: "mdi-file-document-multiple-outline",
                //     name: "模試のきろく",
                //     text: "",
                //     action: this.toPracticeExamHistory,
                //     pattern: 1,
                // },
                {
                    id: 1,
                    icon: "mdi-card-account-mail",
                    name: "メールアドレス設定",
                    action: this.toProfileSetting,
                    pattern: 1,
                },
                {
                    id: 99,
                    icon: "mdi-note-text-outline",
                    name: "利用規約",
                    action: this.toTermsOfService,
                    pattern: 1,
                },
            ],
        }
    },
    methods: {
        openDrawer() {
            this.primaryMenu = true;
        },
        // プロフィール設定画面
        toProfileSetting() {
            this.$emit('changeActiveFlg', undefined);
            this.$router.push({
                name: "ProfileSetting",
            });
        },
        // 模試のきろく
        toPracticeExamHistory() {
            this.$emit('changeActiveFlg', undefined);
            this.$router.push({
                name: "PracticeExamHistory",
            });
        },
        // 利用規約確認画面
        toTermsOfService() {
            this.$emit('changeActiveFlg', undefined);
            this.$router.push({
                name: "TermsOfService",
            });
        },
    }
}
</script>