<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="3" sm="3" md="3" lg="3" xl="3">
        <div align="left">
          <v-btn color="grey" small class="black--text" @click="toBack" :disabled="lockBack">前の課題へ</v-btn>
        </div>
      </v-col>
      <v-col cols="1" sm="1" md="1" lg="1" xl="1">

      </v-col>
      <v-col cols="4" sm="4" md="4" lg="4" xl="4" justify="end">
        <v-btn color="green" small class="white--text" @click="changeFavoriteHomeworks" v-if="this.favoriteFlg">お気に入り解除<v-icon color="orange">mdi-star</v-icon></v-btn>
        <v-btn color="green" small class="white--text" @click="changeFavoriteHomeworks" v-if="!this.favoriteFlg">お気に入り追加<v-icon>mdi-star-outline</v-icon></v-btn>
      </v-col>
      <v-col cols="1" sm="1" md="1" lg="1" xl="1">

      </v-col>
      <v-col cols="3" sm="3" md="3" lg="3" xl="3">
        <div align="right">
          <v-btn color="grey" small class="black--text" @click="toForward" :disabled="lockForward">次の課題へ</v-btn>
        </div>
      </v-col>
    </v-row>
    <!-- 課題ブロック -->
    <v-card
        class="d-flex justify-center flex-column mx-auto my-6 flat"
        width="1200"
        color="#fff"
      >
        <v-card-title class="d-flex justify-left">課題：{{ titleHW }}</v-card-title>
        <v-card-text class="d-flex justify-left pa-0 pl-4 mt-0 red--text" v-if="limitOverFlg">提出期日：{{ limitDate }}</v-card-text>
        <v-card-text class="d-flex justify-left pa-0 pl-4 mt-0" v-if="!limitOverFlg">提出期日：{{ limitDate }}</v-card-text>
        <v-card-text class="d-flex justify-left">課題作成者：{{ createUser }}</v-card-text>
        <v-card-text class="d-flex justify-center flex-column">
          <p style="white-space:pre-wrap;" v-html="autoLink(bodyHW)"></p>
        </v-card-text>   
        <v-card
          class="d-flex justify-center flex-column mx-auto my-0 flat"
          width="1200"
          color="#fff"
        >
        <v-list>
          <v-subheader>添付ファイル</v-subheader>
          <v-list-item-group  v-if="fileListHW.length" color="primary">
            <v-list-item v-for="item in fileListHW" @click="onClickDwonload(item)" :key="item.attachmentId"  >
              <a class="text-decoration-none">{{ item.fileName }}</a>
            </v-list-item>
          </v-list-item-group>
          <v-list-item-group  v-if="!fileListHW.length" color="primary">
            <v-list-item>ファイルはありません</v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-card>
    <!-- 活動記録ブロック -->
    <v-card
      class="d-flex justify-center flex-column mx-auto my-6 flat"
      width="1200"
      color="#fff"
    >
      <v-card-title class="d-flex justify-left">活動記録</v-card-title>
      <ValidationObserver ref="obs" v-slot="{ invalid }">
        <ValidationProvider v-if="editNameFlg" name="活動記録名" rules="required|max:50" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-text-field
            v-model="title"
            prepend-icon="$cet_required"
            label="活動記録名"
            placeholder=""
            :error-messages="errors[0]"
            outlined
            dense
            :counter="50"
            hide-details="auto"
            ></v-text-field>
          </div>
        </ValidationProvider>
        <ValidationProvider name="本文・設問" rules="required|max:9999" v-slot="{ errors }">
          <div class="mx-10 mt-0">
            <v-textarea
              v-model="body"
              prepend-icon="$cet_required"
              label="本文・設問"
              placeholder=""
              :error-messages="errors[0]"
              auto-grow
              outlined
              dense
              :counter="9999"
              hide-details="auto"
            ></v-textarea>
          </div> 
        </ValidationProvider>
        <v-card-text justify="center">
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    ファイル名
                  </th>
                  <th class="text-right">
                    削除
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in viewfileList"
                  :key="item.attachmentId"
                >
                  <td class="text-left"><a @click="onClickDwonload(item)" class="text-decoration-none">{{ item.fileName }}</a></td>
                  <td class="text-right">
                    <v-row class="justify-end">
                      <v-checkbox
                        v-model="selectData"
                        :value="item.attachmentId"
                        :id="item.attachmentId"
                        color="primary"
                        hide-details="auto"
                      />
                  </v-row>
                  </td>     
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <dashboard :uppy="uppy" :props="{
              theme: 'light',
              inline: true,
              width: 3000,
              height: 350,
              disableThumbnailGenerator:true,
              showRemoveButtonAfterComplete:true,
              proudlyDisplayPoweredByUppy:false,
            }" />
            ※添付ファイルは(容量：50MB、個数：10)が上限となっております
        </v-card-text>
        <v-card-actions>
          <v-row class="justify-center">
            <v-col cols="6" justify="center">
              <v-btn color="accent" class="ml-auto mt-0 black--text" @click="poSavePoHomeworkDetails(false)" :disabled="invalid" width="90%">保存(未提出)</v-btn>
            </v-col>
            <v-col cols="6" justify="center">
              <v-btn color="accent" class="ml-auto mt-0 black--text" @click="poSavePoHomeworkDetails(true)" :disabled="invalid" width="90%">提出</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </ValidationObserver>
    </v-card>
    <!-- コメントブロック -->
    <v-card
      class="d-flex justify-center flex-column mx-auto my-6 flat"
      width="1200"
      color="#fff"
    >
      <v-card v-if="!commentList.length">
        <v-card-text class="d-flex justify-center flex-column">
          <label color="primary">コメントはありません</label>
        </v-card-text>
      </v-card>
      <v-card v-if="commentList.length">
        <div v-for="item in commentList" :key="item.commentId">
          <v-divider></v-divider>
          <v-row dense class="lime lighten-4">
            <v-col cols="11">
              <div class="text-caption">
                <div>{{ item.userNm }}が</div>
                <span>{{ item.postDate }}に更新</span>
              </div>
            </v-col>
            <v-col cols="1">
              <v-card-actions class="justify-right pl-0 pr-0">
                <v-tooltip bottom v-if="item.editFlg">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="red" icon outlined x-small v-bind="attrs" v-on="on" @click="onClickDeleteItem(item)"><v-icon>mdi-delete</v-icon></v-btn>
                  </template>
                  <span>コメントを削除する</span>
                </v-tooltip>
              </v-card-actions>
            </v-col>
          </v-row>
          <v-row no-gutters><v-divider></v-divider></v-row>
          <v-row dense>
            <v-card-text class="d-flex justify-center flex-column pt-0">
              <v-row dense>
                <v-col cols="12">
                  <p style="white-space:pre-wrap;" v-html="autoLink(item.comment)"></p>
                </v-col>
              </v-row>
            </v-card-text>
          </v-row>
        </div>
      </v-card>
      <v-divider></v-divider>
      <v-card>
        <ValidationObserver ref="obs2" v-slot="{ invalid2 }">
          <ValidationProvider name="コメント本文" rules="max:3000" v-slot="{ errors }">
            <v-row dense justify="end">
              <v-col cols="12" class="d-flex text-right">
                <v-btn color="accent" class="ml-auto mt-0 black--text" @click="poSaveHomeworkComment" :disabled="invalid2">送信</v-btn>
              </v-col>
            </v-row>
            <v-row dense class="pt-0 mt-0">
              <v-col cols="12" class="pb-0">
                <v-textarea
                  v-model="commentBody"
                  label="コメント本文"
                  placeholder=""
                  outlined
                  dense
                  :error-messages="errors[0]"
                  :counter="3000"
                  class="text-body-2 pt-0 mb-0"
                  hide-details="auto"
                >
                </v-textarea>
              </v-col>
            </v-row>
          </ValidationProvider>
        </ValidationObserver>
      </v-card>
    </v-card>
    <div id="return-to-bottom"></div>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fixed fab bottom left color="secondary" @click="loadCommentArea" style="bottom: 50px">
          <v-icon color="white">mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>再読込</span>
    </v-tooltip>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fixed fab bottom right color="grey" style="bottom: 50px" v-scroll-to="{ element: '#return-to-bottom', duration: 1000 }">
          <v-icon color="white">mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <span>ページの最後を表示する</span>
    </v-tooltip>

  </v-container>
</template>

<script>
import { Dashboard } from '@uppy/vue'
import Uppy from '@uppy/core'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import ja_JP from '../../../plugins/puppy_lang'
import FirebaseCloudStorage from "../../../plugins/FirebaseCloudStorage";
import { getStorage } from "firebase/storage";
import Autolinker from 'autolinker';

import { saveAsCommon } from '../../../common';
import { httpsCallable, getFunctions } from "firebase/functions";
export default {
  props: ['reportId', 'homeworkListBuff', 'homeworkListIndex'],
  // ファイルアップロード実装 START-------------------------
  components: {
    Dashboard
  },
  computed: {
    uppy() {
      const storage = getStorage(this.$firebase);
      const storagePath = this.$root.uploadPath;
      var uppy = new Uppy()
      .use(FirebaseCloudStorage, { storage, storagePath });
      return uppy;
    },
  },
  mounted() {
    this.uppy.on('upload', () => {
      this.$root.loading = true;
    });
    this.uppy.on('upload-success', (file) => {
      // 配列にファイルを追加
      this.fileList.add(file.meta.refId);
    });
    this.uppy.on('complete', () => {
      this.$root.loading = false;
    });
    this.uppy.on('file-removed', (file) => {
      // 配列からファイルを削除
      this.fileList.delete(file.meta.refId);
    });
    // 50MB制限+10ファイル制限
    this.uppy.setOptions({
      debug: true,
      locale: ja_JP,
      autoProceed: true,
      restrictions: {
        maxFileSize: (50 * 1024 * 1024),
        maxNumberOfFiles: 10,
      },
    });
  },
  beforeDestroy () {
    this.uppy.close({ reason: 'unmount' })
  },
  // ファイルアップロード実装 END-------------------------
  data() {
    return {
      homeworkId: "",
      groupId: "",
      groupName: "",
      createUser: "",
      limitDate : "",
      limitDateTime : null,
      limitOverFlg : false,
      titleHW: "",
      bodyHW: "",
      editNameFlg: false,
      useTemplateFlg: false,
      useCategoryFlg: false,

      fileListHW: [],
      title : "",
      body : "",
      
      viewfileList: [],
      fileList: new Set(),
      selectData: [],

      commentList: [],
      commentBody: "",
      uploadFileList: new Set(),
      lockBack: false,
      lockForward: false,
      favoriteFlg: false,
    };
  },
  watch: {
    reportId: async function () {
      await this.load();
    }
  },
  // 初期表示時データ読み込み
  async created() {
    await this.load();
  },
  methods: {
    async load() {
      this.lockBack = (this.homeworkListIndex == 0)?true:false;
      this.lockForward = (this.homeworkListBuff.length == (this.homeworkListIndex+1))?true:false;
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var data = {
        funcName: "poLoadPoHomeworkDetails",
        reportId : this.reportId
      }
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(data).then((doc) => {
        this.homeworkId = doc.data.homeworkId;
        this.groupId = doc.data.groupId;
        this.groupName = doc.data.groupName;
        this.createUser = doc.data.createUser;
        this.limitDate = doc.data.limitDate;
        this.limitDateTime = doc.data.limitDateTime;
        this.titleHW = doc.data.titleHW;
        this.bodyHW = doc.data.bodyHW;
        this.editNameFlg = doc.data.editNameFlg;
        this.useTemplateFlg = doc.data.useTemplateFlg;
        this.useCategoryFlg = doc.data.useCategoryFlg;
        this.fileListHW = doc.data.fileListHW;
        this.title = doc.data.title;
        this.body = doc.data.body;
        this.viewfileList = doc.data.fileList;
        this.favoriteFlg = doc.data.favoriteFlg;
        var now = new Date();
        if(this.limitDateTime < now.getTime()) {
          this.limitOverFlg = true;
        }
      });

      // コメント情報取得
      await this.loadCommentArea();
      this.commentBody = "";

      // ローディング非表示
      this.$root.loading = false;
    },
    // 活動記録保存
    async poSavePoHomeworkDetails(submissionFlg) {
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "poSavePoHomeworkDetails",
        reportId: this.reportId,
        title: this.title,
        body: this.body,
        submissionFlg: submissionFlg,
        delFileList : this.selectData,
        fileList : Array.from(this.fileList),
      };
      await schoolAidHomeLogined(data).then((res) => {
        // ローディング非表示
        this.$root.loading = false;
        console.log(res.data);
        if (res.data == "success"){
          alert("更新しました。");
        }  
        else {
          alert("データ更新に失敗しました。");
        }
      });
      this.$router.push({
        name: "PoStudyHistory",
      });
    },
    // 前の課題へボタン
    toBack() {
      this.$router.push({
        name: "PoHomeworkDetails",
        params: {
          reportId: this.homeworkListBuff[this.homeworkListIndex - 1],
          homeworkListBuff: this.homeworkListBuff,
          homeworkListIndex: this.homeworkListIndex - 1,
        } 
      });
    },
    // 次の課題へボタン
    toForward() {
      this.$router.push({
        name: "PoHomeworkDetails",
        params: {
          reportId: this.homeworkListBuff[this.homeworkListIndex + 1],
          homeworkListBuff: this.homeworkListBuff,
          homeworkListIndex: this.homeworkListIndex + 1,
        } 
      });
    },
    async changeFavoriteHomeworks()  {      
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "changeFavoriteHomeworks",
        homeworkId: this.homeworkId,
        favoriteFlg: this.favoriteFlg
      };
      await schoolAidHomeLogined(data);
      this.favoriteFlg = !this.favoriteFlg;
    },
    // ファイルダウンロード
    async onClickDwonload(item) {
      saveAsCommon(item.url, item.fileName);
    },
    // コメントの取得
    async loadCommentArea() {
      this.commentList = [];
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var data = {
        funcName: "poLoadPoHomeworkDetailsReviews",
        reportId : this.reportId
      }
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(data).then((doc) => {
        this.commentList = doc.data ? doc.data : [];
      });
    },
    // コメントの保管
    async poSaveHomeworkComment() {
      if (!this.commentBody) {
        alert("コメント本文が入力されていません。");
        return;
      }

      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";

      var param = {
        funcName: "poSavePoHomeworkDetailsReviews",
        reportId: this.reportId,
        comment: this.commentBody,
      };

      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param);
      // ローディング終了
      this.$root.loading = false;
      await this.loadCommentArea();
      this.commentBody = "";
      this.$refs.obs2.reset();
    },
    // 
    async onClickDeleteItem(item) {
      var result = confirm("削除してよろしいですか？");
      if (!result) {
        return;
      }

      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      var param = {
        funcName: "poDeletePoHomeworkDetailsReviews",
        reportId: this.reportId,
        commentId: item.commentId,
      };
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param);
      // ローディング終了
      this.$root.loading = false;
      await this.loadCommentArea();
    },
    autoLink(text) {
      const autolinker = new Autolinker();
      return autolinker.link(text);
    },
  },  
};
</script>