<template>
  <ValidationObserver ref="obs" v-slot="{ invalid }">
    <v-card-text class="d-flex justify-center flex-column">
      <v-row dense>
        <v-col dense cols="12" justify="center">
          <ValidationProvider
            name="確定出欠"
            rules="required"
            v-slot="{ errors }"
            :skipIfEmpty="false"
          >
            <v-select
                label="確定出欠"
                item-text="name"
                item-value="code"
                v-model="selectedMain"
                prepend-icon="$cet_required"
                :items="selectMain"
                :error-messages="errors[0]"
                outlined
                @change="changeMain"
                hide-details="auto"
              ></v-select>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col dense cols="12" justify="center">
          <ValidationProvider
            name="確定理由"
            rules="required"
            v-slot="{ errors }"
            :skipIfEmpty="false"
          >
            <v-select
                label="確定理由"
                item-text="name"
                item-value="code"
                prepend-icon="$cet_required"
                v-model="approvalKbn"
                :items="selectSubFilter"
                :error-messages="errors[0]"
                outlined
                @change="changeSub"
                hide-details="auto"
              ></v-select>
          </ValidationProvider>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col dense cols="12" justify="center">
          <ValidationProvider v-if="approvalKbn.endsWith('99')" name="その他理由" rules="required|max:500" v-slot="{ errors }">
            <v-text-field
              v-model="approvalText"
              label="その他理由"
              placeholder="その他理由"
              prepend-icon="$cet_required"
              :error-messages="errors[0]"
              outlined
              dense
              :counter="500"
              v-if="approvalKbn.endsWith('99')"
              hide-details="auto"
            ></v-text-field>
          </ValidationProvider>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-btn color="accent" class="black--text" @click="save()" :disabled="invalid" width="100%">
        更新
      </v-btn>
    </v-card-actions>
  </ValidationObserver>
</template>
  
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import { format, parse } from 'date-fns';
import { ja } from 'date-fns/locale';
import Enumerable from "linq";

export default {
  props: ['studentId', 'param_yyyymmdd'],
  data() {
    return {
      yyyymmdd: "",
      selectMain: [],
      selectedMain: "", //初期値
      selectSub: [],
      selectSubFilter: [],
      approvalKbn: "", //初期値
      approvalText: "", //初期値
    };
  },

  // 初期表示時データ読み込み
  async created() {
    console.log("CalendarLeavingApproval created");
    var date = parse(this.param_yyyymmdd, 'yyyy-MM-dd', new Date());
    this.yyyymmdd = format(date, 'yyyy-MM-dd', { locale: ja });
    await this.reload();
  },
  methods: {
    // ページ再読込
    async reload() {
      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      const data = {
        funcName: "loadCalendarLeavingApproval",
        studentId: this.studentId,
        yyyymmdd: this.yyyymmdd,
      };
      await schoolAidHomeLogined(data).then((res) => {
        this.selectMain = [{code: "", name: "", orderNo: "", yobi1: ""}].concat(res.data.selectMain);
        this.selectSub = res.data.selectSub;
        this.selectedMain = res.data.approvalKbn.substring(0, 2);
        if(res.data.approvalKbn != ""){
          this.selectSubFilter = Enumerable.from(this.selectSub).where(i => i.code.substring(0, 2) == this.selectedMain).toArray();
        }

        this.approvalKbn = res.data.approvalKbn;
        this.approvalText = res.data.approvalText;
      }).catch((e) =>{
        console.log(e.message);
        this.error = e.message;
        this.$root.loading = false;
      });

      // ローディング終了
      this.$root.loading = false;
    },
    // 早退確定保存
    async save() {
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "saveCalendarLeavingApproval",
        yyyymmdd: this.yyyymmdd,
        studentId: this.studentId,
        approvalKbn: this.approvalKbn,
        approvalText: this.approvalText,
      };

      await schoolAidHomeLogined(data).then((res) => {
        // ローディング非表示
        this.$root.loading = false;
        console.log(res.data);
        var filter = Enumerable.from(this.selectMain).where(i => i.code == this.approvalKbn.substring(0, 2)).toArray();
        this.$emit('changeSotaiApproval', this.yyyymmdd, true, filter[0].name);
      }).catch((e) =>{
        console.log(e.message);
        this.error = e.message;
        this.$root.loading = false;
      });
      
      await this.reload();
    },
    async changeMain() {
      console.log('changeMain');
      this.approvalKbn = "";
      this.approvalText = "";
      this.selectSubFilter = Enumerable.from(this.selectSub).where(i => i.code.substring(0, 2) == this.selectedMain).toArray();
    },
    async changeSub() {
      console.log('changeSub');
      if(!this.approvalKbn.endsWith('99')){
        this.approvalText = "";
      }
    },
  },
};
</script>

<style scoped>
.wrap-text{
  white-space: normal;
}
</style>