<template>
  <v-container fluid>
    <v-card
        class="d-flex justify-center flex-column mx-auto my-2 flat"
        width="1200"
        color="#fff"
    >
      <v-card-title class="d-flex justify-center pa-0 my-2">
        年度更新(HOME)
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="d-flex flex-column">
        <v-row>
          <v-col class="d-flex mb-2" cols="12" sm="12" md="12" lg="12" xl="12">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="ml-2" v-bind="attrs" v-on="on" color="orange">mdi-help-circle-outline</v-icon>
              </template>
              <div>過去の出欠に対して先生側からの変更を制限する日付を設定できます</div>
              <div>※指定日以前の出欠の変更が出来なくなります</div>
            </v-tooltip>
            <v-text-field
              v-model="nendo"
              label="システム年度"
              outlined
              dense
              disabled="disabled"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex mb-2" cols="12" sm="12" md="12" lg="12" xl="12">
            <v-text-field
              v-model="annualUpdateStatus"
              label="年度更新状況"
              outlined
              dense
              disabled="disabled"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="red" class="mt-0 black--text" @click="execAnnualUpdate" :disabled="annualUpdateFlg" width="100%">年度更新</v-btn>
      </v-card-actions>  
    </v-card>
  </v-container>
</template>

<script>
import { httpsCallable, getFunctions } from "firebase/functions";

export default {
  data() {
    return {
      // HOMEのシステム年度
      nendo: "",
      // 年度更新状態(済⇒学籍システムのデータ連携待ち)
      annualUpdateFlg: false,
      annualUpdateStatus: "",
    };
  },
  async created() {
    await this.reload()
  },
  methods: {
    async reload(){
      // ローディング開始
      this.$root.loading = true;

      const data = {
        funcName: "loadAnnualUpdate",
      };

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      await schoolAidHomeLogined(data).then((res) => {
        this.nendo = res.data.nendo;
        this.annualUpdateFlg = res.data.annualUpdateFlg;
        this.annualUpdateStatus = res.data.annualUpdateStatus;
        
        // ローディング終了
        this.$root.loading = false;
      }).catch((e) => {
        console.log(e.message);
        this.$root.loading = false;
      });
    },
    // 年度更新
    async execAnnualUpdate() {
      var result = confirm("HOMEの年度更新してよろしいですか？");
      if (!result) {
        return;
      }
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "execAnnualUpdate",
      };
      await schoolAidHomeLogined(data).then((res) => {
        // ローディング非表示
        this.$root.loading = false;
        if (res.data == "success"){
          alert("更新しました。");
        }  
        else {
          alert("データ更新に失敗しました。");
        }
      });
      await this.reload();
    },
  },
};
</script>