<template>
  <v-container fluid>
    <v-form>
      <ValidationObserver ref="obs" v-slot="{ invalid }">
        <v-card
          class="d-flex justify-center flex-column mx-auto my-6 flat"
          width="374"
          color="#fff"
        >
          <img :src="require('@/assets/logo.svg')" alt="Logo" class="logo" />
          <v-card-text class="d-flex justify-center flex-column">
            <div class="mb-2">
              <ValidationProvider
                name="ログインＩＤ"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="uid"
                  label="ログインＩＤ"
                  name="uid"
                  :error-messages="errors[0]"
                  autocomplete="uid"
                  outlined
                  single-line
                  dense
                  hide-details="auto"
                ></v-text-field>
              </ValidationProvider>
            </div>
            <div class="mb-2">
              <ValidationProvider
                name="パスワード"
                rules="required"
                v-slot="{ errors }"
              >
                <v-text-field
                  v-model="password"
                  type="password"
                  name="password"
                  label="パスワード"
                  :error-messages="errors[0]"
                  autocomplete="current-password"
                  outlined
                  single-line
                  dense
                  hide-details="auto"
                ></v-text-field>
              </ValidationProvider>
            </div>
            <div class="text-center">
              <v-btn color="accent" class="black--text" @click="signIn" :disabled="invalid" width="100%">
                ログイン
              </v-btn>
            </div>
            <div class="text-danger">{{ error }}</div>
          </v-card-text>
          <v-card-text class="d-flex justify-center flex-column">
            <a style="color:#000;" href="#" @click.prevent.stop="toForgetPassword()">パスワード変更・再設定</a>
          </v-card-text>
        </v-card>
        <v-card-text class="d-flex justify-center flex-column">
          <div class="text-center"><a style="color:#000;" href="#" @click.prevent.stop="privacyPolicyClick()">プライバシーポリシー</a></div>
          <p class="text-center" style="margin:0%;">{{ version }}</p>
          <p class="text-center" style="margin:0%;">{{ copyright }}</p>
        </v-card-text>
      </ValidationObserver>
    </v-form>
  </v-container>
</template>
  
<script>
import { signInWithCustomToken , getAuth, browserSessionPersistence , setPersistence } from "firebase/auth";
import { httpsCallable, getFunctions } from "firebase/functions";
export default {
  data() {
    return {
      uid: "",
      password: "",
      error: "",
      version: this.$root.version,
      copyright: this.$root.copyright,
    };
  },
  async created() {
    // メニューを非表示
    this.$root.isLogin = false;
  },
  methods: {
    // ログイン
    async signIn() {
      var data = {
        funcName: "login",
        uid: this.uid,
        password: this.password
      };
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomePublic = httpsCallable(functions, "schoolAidHomePublic");
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      // カスタムログイン認証処理
      var token = null;
      await schoolAidHomePublic(data).then((ret) => {
        token = ret.data;
      }).catch((e) => {
        this.error = e.message;
        this.$root.loading = false;
      });
      if(token == null){
        return false;
      }

      const auth = getAuth();
      await setPersistence(auth, browserSessionPersistence);

      // firebase認証処理
      var uid = null;
      await signInWithCustomToken(auth, token).then((data) => {
        uid = data.user.uid;
      }).catch((e) => {
          this.error = e.message;
          this.$root.loading = false;
      });

      console.log(uid);
      
      // ユーザー情報取得
      await schoolAidHomeLogined({funcName:"getLoginInfo"}).then((res) => {
        this.$root.loading = false;
        var userKbn = res.data.userKbn;
        var userNm = res.data.userNm;
        var consentFlg = res.data.consentFlg;
        var mailAddressAuthFlg = res.data.mailAddressAuthFlg;
        var adminFlg = res.data.adminFlg;
        var schoolName = res.data.schoolName;
        this.$root.needCourseSelect = res.data.needCourseSelect;
        this.$root.adminFlg = res.data.adminFlg;
        this.$root.students = res.data.students;
        this.$root.uploadPath = "temp/user/{uid}/{uuid}".replace("{uid}", uid);

        if (userKbn !== undefined && consentFlg !== undefined && mailAddressAuthFlg !== undefined) {

          // 学校名をヘッダにセット
          this.$root.siteName = schoolName;
          // ユーザー区分に応じて背景色を変更
          this.$root.userKbn = userKbn;
          this.$root.userNm = userNm;

          if(consentFlg === true){
            // ログインフラグを立てる
            this.$root.isLogin = true;
            var nextPage = "";
            
            // ユーザー区分に応じた制御
            switch (userKbn) {
              case "0":
                nextPage = "StudentTopPage";
                this.$root.isStudentMenu = true;
                break;
              case "1":
                nextPage = "GuardianTopPage";
                this.$root.isParentMenu = true;
                break;
              case "8":
                nextPage = "TeacherTopPage";  
                this.$root.isKyoinMenu = true;
                if(adminFlg == true){
                  this.$root.menuPattern = 2;
                } else {
                  this.$root.menuPattern = 1;
                }
                break;
              case "9":
                nextPage = "TeacherTopPage";
                this.$root.isKyoinMenu = true;
                this.$root.menuPattern = 2;
                break;
              default:
                break;
            }
            // 利用規約同意済
            this.$router.push({
              name: nextPage
            });
          } else {
            // 利用規約未同意
            this.$router.push({
              name: "TermsOfService"
            }); 
          }
        } else {
          alert("システムエラー");
        }
      }).catch((e) => {
        this.error = e.message;
        this.$root.loading = false;
      });
    },
    // パスワード変更要求へ
    toForgetPassword() {
      this.$router.push("/forget-password");
    },

    /**
     * プライバシーポリシー情報ページを開く
     * @param なし
     * @returns プライバシーポリシーURL
     */
    async privacyPolicyClick() {
      // ローディング表示
      var data = {
        funcName: "getPrivacyPolicyUrl",
      };
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomePublic = httpsCallable(functions, "schoolAidHomePublic");
      this.windowReference = window.open();
      await schoolAidHomePublic(data).then((res) => {
        this.$root.loading = false;
        this.windowReference.location = res.data.privacyUrl;
      });
    },
  },
};
</script>

<style>
.logo {
  display: block;
  position: relative;
  overflow: hidden;
}
.pointer {
  cursor: pointer;
}
</style>
