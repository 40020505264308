<template>
  <v-app :style="settingBackGroundColor()">
    <v-app-bar v-if="$root.isLogin" app dense >
      <v-app-bar-nav-icon @click.stop="openDrawer">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="accent">mdi-menu</v-icon>
          </template>
          <span>メニューを表示</span>
        </v-tooltip>
      </v-app-bar-nav-icon>
      <v-toolbar-title style="font-size:1.012rem;">{{ this.$root.siteName }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <div style="font-size:1.012rem;">{{ this.$root.userNm }}さん</div>
    </v-app-bar>

    <!---------------------------------------------------- ドロワーメニュー start ---------------------------------------------------->
      <TeacherMenu
        ref="teacherMenu"
        v-if="$root.isKyoinMenu"
        @changeActiveFlg="changeActiveFlg"
      />
      <StudentMenu
        ref="studentMenu"
        v-if="$root.isStudentMenu"
        @changeActiveFlg="changeActiveFlg"
      />
      <ParentMenu
        ref="parentMenu"
        v-if="$root.isParentMenu"
        @changeActiveFlg="changeActiveFlg"
      />
    <!---------------------------------------------------- ドロワーメニュー end ---------------------------------------------------->

    <v-main>
      <router-view />
    </v-main>

    <!-- 生徒メニュー -->
    <v-bottom-navigation
      v-if="$root.isStudentMenu"
      color="accent"
      v-model="isActive"
      app
      grow
    >
      <v-btn value="menu01" @click="this.toStudentTopPage">
        <span class="text-button">学校連絡</span>
        <v-icon>mdi-bulletin-board</v-icon>
      </v-btn>
      <v-btn value="menu02" v-if="this.$root.needCourseSelect" @click="this.toCourseSelect">
        <span class="text-button">コース選択</span>
        <v-icon>mdi-routes</v-icon>
      </v-btn>
      <v-btn value="menu03" @click="this.toPoStudyHistory">
        <span class="text-button">学習のきろく</span>
        <v-icon>mdi-treasure-chest</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <!-- 保護者メニュー -->
    <v-bottom-navigation
      v-if="$root.isParentMenu"
      color="accent"
      v-model="isActive"
      app
      grow
    >
      <v-btn value="menu01" @click="this.toGuardianTopPage">
        <span class="text-button">学校連絡</span>
        <v-icon>mdi-bulletin-board</v-icon>
      </v-btn>
      <v-btn value="menu02" @click="this.toAttendanceRegist">
        <span class="text-button">出欠連絡</span>
        <v-icon>mdi-chat</v-icon>
      </v-btn>
      <v-btn value="menu03" v-if="this.$root.needCourseSelect" @click="this.toCourseSelectParent">
        <span class="text-button">コース選択確認</span>
        <v-icon>mdi-routes</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <!-- 教員管理者メニュー -->
    <v-bottom-navigation
      v-if="$root.isKyoinMenu"
      color="accent"
      v-model="isActive"
      app
      grow
    >
      <v-btn value="menu01" @click="this.toTeacherTopPage">
        <span class="text-button">校内連絡</span>
        <v-icon>mdi-account-settings-outline</v-icon>
      </v-btn>
      <v-btn value="menu02" @click="this.toAttendanceConfirm">
        <span class="text-button">出欠確認</span>
        <v-icon>mdi-comment-check</v-icon>
      </v-btn>
      <v-btn value="menu03" @click="this.toLeavingConfirm">
        <span class="text-button">早退確認</span>
        <v-icon>mdi-comment-alert</v-icon>
      </v-btn>
      <v-btn value="menu04" @click="this.toAttendanceDateSummary">
        <span class="text-button">出欠状況のまとめ</span>
        <v-icon>mdi-notebook-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <Loading
      :active.sync="$root.loading"
      :can-cancel="false"
      :is-full-page="true"
    ></Loading>
  </v-app>
</template>

<script>
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';

import TeacherMenu from './components/menu/TeacherMenu.vue'
import StudentMenu from './components/menu/StudentMenu.vue'
import ParentMenu from './components/menu/ParentMenu.vue'

export default {
  components: {
    TeacherMenu,
    StudentMenu,
    ParentMenu,
  },
  data() {
    return {
      isActive: 'menu01',
    };
  },
  // 初期表示時データ読み込み
  async created() {
    // ログイン済フラグ
    this.$root.isLogin = false;
    // ローディングフラグ
    this.$root.loading = false;
    // 生徒メニュー表示フラグ
    this.$root.isStudentMenu = false;
    // 保護者メニュー表示フラグ
    this.$root.isParentMenu = false;
    // 教員/管理者メニュー表示フラグ
    this.$root.isKyoinMenu = false;
    this.$root.menuPattern = 1;
    // タイトルバーの学校名
    this.$root.siteName = "";
    // タイトルバーのユーザー名
    this.$root.userNm = "";
    // コース選択対象者
    this.$root.needCourseSelect = false;
    // 管理者フラグ
    this.$root.adminFlg = false;
    // 被保護者リスト
    this.$root.students = [];
    // アップロード先バケットパス
    this.$root.uploadPath = "";

    // ログイン画面初期表示
    console.log("Vue初期化処理(画面ソース読込)");
    const conds = ['EmailAuthentication', 'PasswordReset', 'AutoLogin'];
    if (!conds.includes(this.$router.currentRoute.name)) {
      this.$router.push({
        name: "Login",
      });
    }
  },

  methods: {
    // ドロワーを開く
    openDrawer() {
      if(this.$root.isKyoinMenu == true){
        this.$refs.teacherMenu.openDrawer();
      } else if(this.$root.isStudentMenu == true) {
        this.$refs.studentMenu.openDrawer();
      } else if(this.$root.isParentMenu == true) {
        this.$refs.parentMenu.openDrawer();
      }
    },
    // 下メニューの表示制御
    changeActiveFlg(activeFlg) {
      this.isActive = activeFlg;
    },
    settingBackGroundColor() {
      switch (this.$root.userKbn) {
        case "0":
          return "{background-repeat: no-repeat;background-image: linear-gradient(rgb(204, 235, 235),rgb(127, 201, 126));}"
        case "1":
          return "{background-repeat: no-repeat;background-image: linear-gradient(rgb(255, 248, 194),rgb(255, 147, 75));}"
        case "8":
          return "{background-repeat: no-repeat;background-image: linear-gradient(rgb(179, 205, 227),rgb(103, 103, 202));}"
        default:
          return "{background-repeat: no-repeat;background-image: linear-gradient(rgb(255, 248, 194),rgb(255, 147, 75));}"
      }
    },
    // フッターメニュー(教員・管理者用) ---------------------------------------------------------------------------- //
    // 通知連絡画面(教員・管理者用)
    toTeacherTopPage() {
      this.$router.push({
        name: "TeacherTopPage",
      });
    },
    // 出欠確認画面へ
    toAttendanceConfirm() {
      var yyyymmdd = format(new Date(), 'yyyy-MM-dd', { locale: ja });
      this.$router.push({
        name: "AttendanceConfirm",
        params: {
            param_yyyymmdd: yyyymmdd,
        }
      });
    },
    // 早退確認画面へ
    toLeavingConfirm() {
      var yyyymmdd = format(new Date(), 'yyyy-MM-dd', { locale: ja });
      this.$router.push({
        name: "LeavingConfirm",
        params: {
            param_yyyymmdd: yyyymmdd,
        }
      });
    },
    // 出欠集計一覧
    toAttendanceDateSummary() {
      this.$router.push({
        name: "AttendanceDateSummary",
      });
    },
    // フッターメニュー(生徒用) ---------------------------------------------------------------------------- //
    // 通知連絡画面(生徒用)
    toStudentTopPage() {
      this.$router.push({
        name: "StudentTopPage",
      });
    },
    // コース選択
    toCourseSelect() {
      this.$router.push({
        name: "CourseSelect",
      });
    },
    // 学習のきろく
    toPoStudyHistory() {
      this.$router.push({
        name: "PoStudyHistory",
      });
    },
    // フッターメニュー(保護者) ---------------------------------------------------------------------------- //
    // 通知連絡画面(保護者用用)
    toGuardianTopPage() {
      this.$router.push({
        name: "GuardianTopPage",
      });
    },
    // 出欠登録/健康報告
    toAttendanceRegist() {
      if(this.$root.students && this.$root.students.length > 1){
        // 被保護者が複数名なら被保護者選択へ
        this.$router.push({
          name: "StudentSelect",
        });
      } else {
        // 被保護者が１名なら出欠登録/健康報告画面へ
        this.$router.push({
          name: "AttendanceRegist",
          params: {
            id: this.$root.students[0],
          }
        });
      }
    },
    // コース選択確認
    toCourseSelectParent() {
      this.$router.push({
        name: "CourseSelectParent",
      });
    },
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+New:wght@500&display=swap');
#app {
  font-family: "Zen Kaku Gothic New", sans-serif;
}
</style>