<template>
    <ValidationObserver ref="obs" v-slot="{ invalid }">
        <v-card-text class="d-flex justify-center flex-column">
            <v-row dense>
                <v-col dense cols="12" justify="center">
                    <ValidationProvider
                        name="検索：グループ"
                        rules="required"
                        v-slot="{ errors }"
                        :skipIfEmpty="false"
                    >
                        <v-autocomplete
                            prepend-icon="$cet_required"
                            v-model="selectedGroup"
                            label="検索：グループ"
                            placeholder="検索：グループ"
                            item-text="groupName"
                            item-value="groupId"
                            :items="groups"
                            :error-messages="errors[0]"
                            :loading="isGroupLoding"
                            outlined
                            dense
                            @change="changeGroup"
                            hide-details="auto"
                            background-color="white"
                        >
                            <template v-slot:item="{ item }">
                                <div class="d-flex col-11">{{ item.groupName }}</div>
                                <div class="d-flex col-1">
                                    <v-tooltip top v-if="item.favoriteFlg">
                                        <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" color="orange">mdi-star</v-icon>
                                        </template>
                                        <span>お気に入り</span>
                                    </v-tooltip>
                                </div>
                            </template>
                        </v-autocomplete>
                    </ValidationProvider>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col dense cols="12" justify="center">
                    <ValidationProvider name="検索：氏名カナ" rules="max:500" v-slot="{ errors }">
                        <v-text-field
                            v-model="studentName"
                            label="検索：氏名カナ"
                            placeholder="検索：氏名カナ"
                            :error-messages="errors[0]"
                            outlined
                            dense
                            :counter="500"
                            hide-details="auto"
                        />
                    </ValidationProvider>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col dense cols="12" justify="center">
                    <v-data-table
                        :headers="headers"
                        :items="members"
                        :items-per-page="-1"
                        :search="studentName"
                        :custom-filter="userNmFilter"
                        dense
                        disable-sort
                        hide-default-footer
                        mobile-breakpoint="0"
                        :loading="isListLoding" loading-text="読込中"
                    >

                        <template #[`header.recode`]="{}">
                            <v-row no-gutters><v-col cols="12">課程/部/科</v-col></v-row>
                            <v-row no-gutters><v-col cols="12">学年/学級/出席番号</v-col></v-row>
                        </template>

                        <!-- 明細定義 -->
                        <template #[`item.recode`]="{ item }">
                            <v-row no-gutters class="hidden-xs-only"><v-col cols="12">
                                {{ item.kateiNm }}/{{ item.buNm }}/{{ item.kaNm }}
                            </v-col></v-row>
                            <v-row no-gutters class="hidden-xs-only"><v-col cols="12">
                                {{ item.gakunen }}/{{ item.gakkyu }}/{{ item.syussekiNo }}
                            </v-col></v-row>

                            <!-- モバイル用まとめ -->
                            <v-row no-gutters class="d-sm-none">
                                <v-col cols="12">
                                    {{ item.kateiNm }}/{{ item.buNm }}/{{ item.kaNm }}/{{ item.gakunen }}/{{ item.gakkyu }}/{{ item.syussekiNo }}
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="d-sm-none">
                                <v-col cols="12">
                                    {{ item.userNm }}({{ item.userNmKana }})
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="d-sm-none">
                                <v-col cols="6" class="d-flex justify-center">
                                    保護者
                                    <v-tooltip bottom v-if="!item.existsParentRoomFlg">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="accent" icon outlined small :disabled="invalid" v-bind="attrs" v-on="on" @click="openTalkRoomToParent(item)">
                                                <v-icon small>mdi-chat-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>保護者へ個別連絡</span>
                                    </v-tooltip>
                                    <v-tooltip bottom v-if="item.existsParentRoomFlg">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="green" icon outlined small :disabled="invalid" v-bind="attrs" v-on="on" @click="moveTalkRoomToParent(item)">
                                                <v-icon small>mdi-forum-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>作成済の保護者との個別連絡へ移動します。</span>
                                    </v-tooltip>
                                </v-col>
                                <v-col cols="6" class="d-flex justify-center">
                                    生徒
                                    <v-tooltip bottom v-if="!item.existsStudentRoomFlg">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="accent" icon outlined small :disabled="invalid" v-bind="attrs" v-on="on" @click="openTalkRoomToStudent(item)">
                                            <v-icon small>mdi-chat-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>生徒へ個別連絡</span>
                                    </v-tooltip>
                                    <v-tooltip bottom v-if="item.existsStudentRoomFlg">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="green" icon outlined small :disabled="invalid" v-bind="attrs" v-on="on" @click="moveTalkRoomToStudent(item)">
                                            <v-icon small>mdi-forum-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>作成済の生徒との個別連絡へ移動します。</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </template>

                        <!-- 氏名(ｶﾅ) -->
                        <template #[`item.userNm`]="{ item }">
                            <v-row no-gutters>
                                <v-col cols="12">
                                    {{ item.userNm }}({{ item.userNmKana }})
                                </v-col>
                            </v-row>
                        </template>

                        <!-- 操作 -->
                        <template #[`item.action`]="{ item }">
                            <v-row no-gutters>
                                <v-col cols="6" class="d-flex justify-center">
                                    <v-tooltip bottom v-if="!item.existsParentRoomFlg">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="accent" icon outlined small :disabled="invalid" v-bind="attrs" v-on="on" @click="openTalkRoomToParent(item)">
                                                <v-icon small>mdi-chat-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>保護者へ個別連絡</span>
                                    </v-tooltip>
                                    <v-tooltip bottom v-if="item.existsParentRoomFlg">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="green" icon outlined small :disabled="invalid" v-bind="attrs" v-on="on" @click="moveTalkRoomToParent(item)">
                                                <v-icon small>mdi-forum-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>作成済の保護者との個別連絡へ移動します。</span>
                                    </v-tooltip>
                                </v-col>
                                <v-col cols="6" class="d-flex justify-center">
                                    <v-tooltip bottom v-if="!item.existsStudentRoomFlg">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="accent" icon outlined small :disabled="invalid" v-bind="attrs" v-on="on" @click="openTalkRoomToStudent(item)">
                                            <v-icon small>mdi-chat-plus</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>生徒へ個別連絡</span>
                                    </v-tooltip>
                                    <v-tooltip bottom v-if="item.existsStudentRoomFlg">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="green" icon outlined small :disabled="invalid" v-bind="attrs" v-on="on" @click="moveTalkRoomToStudent(item)">
                                            <v-icon small>mdi-forum-outline</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>作成済の生徒との個別連絡へ移動します。</span>
                                    </v-tooltip>
                                </v-col>
                            </v-row>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
    </ValidationObserver>
</template>

<script>
import { httpsCallable, getFunctions } from "firebase/functions";
export default {
    data() {
        return {
            groups: [],
            selectedGroup: "",
            isGroupLoding: false,
            studentName: "",
            headers: [
                { text: "課程/部/科/学年/学級/出席番号", value: "recode", class: "px-0 hidden-xs-only", width: "200"},
                { text: "氏名(ｶﾅ)", value: "userNm", class: "px-0 hidden-xs-only", cellClass: "hidden-xs-only"},
                { text: "操作(保護者/生徒)", value: "action", class: "px-0 hidden-xs-only", cellClass: "hidden-xs-only"},
            ],
            members: [],
            isListLoding: false,
        }
    },
    // 初期表示時データ読み込み
    async created() {
        const data = {
            funcName: "loadTalkRoomRegist",
        };
        
        const functions = getFunctions(this.$firebase);
        functions.region = "asia-northeast1";
        const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
        this.isGroupLoding = true;
        await schoolAidHomeLogined(data).then((groups) => {
            var ret = [];
            groups.data.forEach((group) => {
                var row = {
                    groupId : group.groupId,
                    groupName: group.groupName,
                    groupKbn: group.groupKbn,
                    groupPattern: group.groupPattern,
                    favoriteFlg: group.favoriteFlg,
                };
                ret.push(row);
            });
            this.groups = ret;
        });
        this.isGroupLoding = false;
    },
    methods: {
        async changeGroup() {
            console.log(this.selectedGroup);
            const data = {
                funcName: "loadTalkRoomRegistDetail",
                groupId: this.selectedGroup,
            };
            const functions = getFunctions(this.$firebase);
            functions.region = "asia-northeast1";
            const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
            this.isListLoding = true;
            await schoolAidHomeLogined(data).then((users) => {
                var buff = [];
                users.data.forEach((user) => {
                    var row = {
                        studentId : user.loginId,
                        parentId : user.parentId,
                        userNm: user.userNm,
                        userNmKana: user.userNmKana,
                        kateiNm: user.kateiNm,
                        buNm: user.buNm,
                        kaNm: user.kaNm,
                        gakunen: user.gakunen,
                        gakkyu: user.gakkyu,
                        syussekiNo: user.syussekiNo,
                        existsParentRoomFlg: user.existsParentRoomFlg,
                        existsParentRoomId: user.existsParentRoomId,
                        existsStudentRoomFlg: user.existsStudentRoomFlg,
                        existsStudentRoomId: user.existsStudentRoomId,
                    };
                    buff.push(row);
                });
                this.members = buff;
            });
            this.isListLoding = false;
        },
        // 検索=================================================================================
        // 生徒氏名フィルタ
        userNmFilter(value, search, item) {
            console.log(search);
            if(!search)return true;
            return (item.userNm.indexOf(search) >= 0 || item.userNmKana.indexOf(search) >= 0);
        },
        // 検索=================================================================================
        // 個別連絡作成処理(保護者)
        async openTalkRoomToParent(item){
            var result = confirm("保護者への個別連絡を作成します、よろしいですか？");
            if (!result) {
                return;
            }
            var param = {
                funcName: "openTalkRoomToParent",
                studentId: item.studentId,
                parentId: item.parentId,
                assignUserIds: [],
            }
            const functions = getFunctions(this.$firebase);
            functions.region = "asia-northeast1";
            const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
            await schoolAidHomeLogined(param).then((ret) => {
                if(ret.data.message) {
                alert("作成に失敗しました");
                } else {
                this.$router.push({
                    name: "TalkRoom",
                    params: {
                        roomId: ret.data.roomId,
                    }
                });
                }
            });
        },
        // 個別連絡作成処理(生徒)
        async openTalkRoomToStudent(item){
            var result = confirm("生徒への個別連絡を作成します、よろしいですか？");
            if (!result) {
                return;
            }
            var param = {
                funcName: "openTalkRoomToStudent",
                studentId: item.studentId,
                assignUserIds: [],
            }
            const functions = getFunctions(this.$firebase);
            functions.region = "asia-northeast1";
            const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
            await schoolAidHomeLogined(param).then((ret) => {
                if(ret.data.message) {
                    alert("作成に失敗しました");
                } else {
                this.$router.push({
                    name: "TalkRoom",
                    params: {
                        roomId: ret.data.roomId,
                    }
                });
                }
            });
        },
        async moveTalkRoomToStudent(item){
            var result = confirm("生徒との個別連絡へ移動します、よろしいですか？");
            if (!result) {
                return;
            }
            this.$router.push({
                name: "TalkRoom",
                params: {
                    roomId: item.existsStudentRoomId,
                }
            });
        },
        async moveTalkRoomToParent(item){
            var result = confirm("保護者との個別連絡へ移動します、よろしいですか？");
            if (!result) {
                return;
            }
            this.$router.push({
                name: "TalkRoom",
                params: {
                    roomId: item.existsParentRoomId,
                }
            });
        },
    }
}
</script>