<template>
  <ValidationObserver ref="obs" v-slot="{ invalid }">
    <!-- タブ -->
    <v-tabs fixed-tabs>
      <v-tab href="#input">登録</v-tab>
      <v-tab href="#history">履歴</v-tab>
      
      <!-- 登録タブ -->
      <v-tab-item value="input">
        <v-card-text class="d-flex justify-center flex-column">
          <v-row dense>
            <v-col dense cols="12" justify="center">
              <ValidationProvider
                name="出欠登録"
                rules="required"
                v-slot="{ errors }"
                :skipIfEmpty="false"
              >
                <v-select
                    label="出欠登録"
                    item-text="label"
                    item-value="syukketuKbn"
                    v-model="selectedSyukketu"
                    prepend-icon="$cet_required"
                    :items="syukketu"
                    :error-messages="errors[0]"
                    outlined
                    hide-details="auto"
                  ></v-select>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              <ValidationProvider name="備考" rules="max:150" v-slot="{ errors }">
                <v-text-field
                  v-model="biko"
                  label="備考"
                  placeholder="備考"
                  :error-messages="errors[0]"
                  outlined
                  dense
                  :counter="150"
                  hide-details="auto"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              <ValidationProvider name="コメント" rules="max:500" v-slot="{ errors }">
                <v-text-field
                  v-model="comment"
                  label="コメント"
                  placeholder="コメント"
                  :error-messages="errors[0]"
                  outlined
                  dense
                  :counter="500"
                  hide-details="auto"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              <v-checkbox class="mt-0" :disabled="!parentMailAuthFlg" v-model="updateMailFlg" label="保護者へ通知する" hide-details="auto" />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col dense cols="12" justify="center">
              <ValidationProvider name="通知タイトル" rules="max:30" v-slot="{ errors }">
                <v-text-field class="mb-3"
                  v-model="mailTitle"
                  label="通知タイトル"
                  :disabled="!updateMailFlg"
                  :error-messages="errors[0]"
                  outlined
                  dense
                  :counter="30"
                  hide-details="auto"
                ></v-text-field>
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn color="accent" class="black--text" @click="syukketuSave()"
            :disabled="pastLockFlg ? pastLockFlg : invalid" width="100%" 
          >
            更新
          </v-btn>
        </v-card-actions>
      </v-tab-item>

      <!-- 履歴タブ -->
      <v-tab-item value="history">
        <v-list>
          <v-list-item-group  v-if="history.length">
            <v-list-item v-for="row in history" :key="row.postDateTime" :style="changeListColor(row)">
              <v-list-item-content>
                <v-list-item-subtitle>
                  更新日時：{{ row.postDate }}
                  <v-icon x-large v-if="row.userKbn == '1'">$parent</v-icon>
                  <v-icon x-large v-if="row.userKbn == '8'">$teacher</v-icon>
                </v-list-item-subtitle>
                <v-list-item-subtitle>更新者：{{ row.userNm }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="row.syukketuNm">出欠区分：{{ row.syukketuNm }}</v-list-item-subtitle>
                <v-list-item-subtitle class="wrap-text" v-if="row.biko">備考：{{ row.biko }}</v-list-item-subtitle>
                <v-list-item-subtitle class="wrap-text" v-if="row.comment">コメント：{{ row.comment }}</v-list-item-subtitle>
                <v-list-item-subtitle class="wrap-text" v-if="row.userKbn=='8' && row.updateMailFlg">通知タイトル：{{ row.mailTitle }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
          <v-list-item-group  v-if="!history.length" color="primary">
            <v-list-item>履歴はありません</v-list-item>
          </v-list-item-group>
        </v-list>
      </v-tab-item>
    </v-tabs>
  </ValidationObserver>
</template>
  
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import { format, parse } from 'date-fns';
import { ja } from 'date-fns/locale';
import Enumerable from "linq";
export default {
  props: ['studentId', 'param_yyyymmdd'],
  data() {
    return {
      yyyymmdd: "",
      selectedSyukketu: { label: "", syukketuKbn: 0 }, //初期値
      syukketu: [
        { label: "", syukketuKbn: 0 },
        { label: "欠席", syukketuKbn: 1 },
        { label: "遅刻", syukketuKbn: 5 },
      ],
      biko: "",
      comment: "",
      parentMailAuthFlg: false,
      updateMailFlg: false,
      mailTitle: "",
      history: [],
      pastLockFlg: false,
    };
  },
  // 初期表示時データ読み込み
  async created() {
    console.log("AttendanceEdit created");
    var date = parse(this.param_yyyymmdd, 'yyyy-MM-dd', new Date());
    this.yyyymmdd = format(date, 'yyyy-MM-dd', { locale: ja });
    await this.reload();
  },
  methods: {
    // ページ再読込
    async reload() {
      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      const data = {
        funcName: "loadAttendanceEdit",
        yyyymmdd: this.yyyymmdd,
        studentId: this.studentId
      };
      await schoolAidHomeLogined(data).then((res) => {
        this.selectedSyukketu = res.data.syukketuKbn;
        this.biko = res.data.biko;
        this.history = res.data.history;
        this.pastLockFlg = res.data.pastLockFlg;
        this.parentMailAuthFlg = res.data.parentMailAuthFlg;
      }).catch((e) =>{
        console.log(e.message);
        this.error = e.message;
        this.$root.loading = false;
      });

      // ローディング終了
      this.$root.loading = false;
    },
    // 出欠情報設定保存
    async syukketuSave() {
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "saveAttendanceEdit",
        yyyymmdd: this.yyyymmdd,
        studentId: this.studentId,
        syukketuKbn: this.selectedSyukketu,
        biko: this.biko,
        comment: this.comment,
        updateMailFlg: this.updateMailFlg,
        mailTitle : this.mailTitle
      };

      await schoolAidHomeLogined(data).then((res) => {
        // ローディング非表示
        this.$root.loading = false;
        console.log(res.data);
        var filter = Enumerable.from(this.syukketu).where(i => i.syukketuKbn == this.selectedSyukketu).toArray();
        this.$emit('changeSyukketu', this.studentId, filter[0].syukketuKbn, filter[0].label);
      }).catch((e) =>{
        console.log(e.message);
        this.error = e.message;
        this.$root.loading = false;
      });
      
      await this.reload();
    },
    // 履歴の明細色変更
    changeListColor(row){
      if(row.userKbn == '1') {
        return 'background-color: #FFF8E1';
      } else if(row.userKbn == '8') {
        return 'background-color: #E8EAF6';
      }
    }
  },
};
</script>

<style scoped>
.wrap-text{
  white-space: normal;
}
</style>