<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="2" justify="center">
        <v-btn color="accent" class="black--text" @click="toReportPublishSettings" width="90%">開示設定(通知表)</v-btn>
      </v-col>
      <v-col cols="8" justify="center">
        <v-card class="mx-auto text-center black--text" outlined>
          成績開示設定(試験結果)
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="ml-2" v-bind="attrs" v-on="on" color="orange">mdi-help-circle-outline</v-icon>
            </template>
            <div>教務システムのHOME成績連携機能にて連携処理を実行すると、</div>
            <div>開示設定として下表に追加されます。</div>
          </v-tooltip>
        </v-card>
      </v-col>
      <v-col cols="2" justify="center"></v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
        <!-- 検索用の年度入力 -->
        <v-autocomplete
          v-model="selectNendo"
          label="年度指定"
          placeholder="年度指定"
          :items="nendoList"
          @change="reload"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
        <v-autocomplete
          v-model="publisherValue"
          label="発行者指定"
          placeholder="発行者指定"
          :items="publisherList"
          outlined
          dense
          clearable
          hide-details="auto"
          background-color="white"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-data-table
          :headers="headers"
          :items="reportList"
          :items-per-page.sync="perPage"
          :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
          dense
          disable-sort
          :loading="isLoding" loading-text="読込中"
        >
          <!-- ラベル：連携日付/学級名 -->
          <template #[`header.insDate`]="{}"> 
            <v-row no-gutters><v-col cols="12" justify="center">連携日時</v-col></v-row>
            <v-row no-gutters><v-col cols="12" justify="center">対象学級</v-col></v-row>
            <v-row no-gutters><v-col cols="12" justify="center">学期</v-col></v-row>
          </template>
          <!-- ラベル：試験名/発行教員/確認教員 -->
          <template #[`header.publisherNm`]="{}"> 
            <v-row no-gutters><v-col cols="12" justify="center">試験名</v-col></v-row>
            <v-row no-gutters><v-col cols="12" justify="center">発行教員</v-col></v-row>
            <v-row no-gutters><v-col cols="12" justify="center">確認教員</v-col></v-row>
          </template>
          <!-- ラベル：公開状態/設定日時 -->
          <template #[`header.publishStatus`]="{}"> 
            <v-row no-gutters><v-col cols="12" justify="center">公開状態</v-col></v-row>
            <v-row no-gutters><v-col cols="12" justify="center">設定日時</v-col></v-row>
          </template>
          <!-- 連携日付/学級名 -->
          <template #[`item.insDate`]="{ item }">
            <v-row no-gutters><v-col cols="12" justify="center">{{ item.insDate }}</v-col></v-row>
            <v-row no-gutters><v-col cols="12" justify="center">{{ item.groupName }}</v-col></v-row>
            <v-row no-gutters><v-col cols="12" justify="center">{{ item.gakki }}</v-col></v-row>
          </template>
          <!-- 試験名/発行教員/確認教員 -->
          <template #[`item.publisherNm`]="{ item }">
            <v-row dense><v-col cols="12" justify="center">{{ item.sikenNm }}</v-col></v-row>
            <v-row dense><v-col cols="12" justify="center">{{ item.publisherNm }}</v-col></v-row>
            <v-row dense><v-col cols="12" justify="center">{{ item.confirmerNms }}</v-col></v-row>
          </template>
          <!-- 確認教員変更 -->
          <template #[`item.confirmerChange`]="{ item }">
            <v-tooltip top v-if="item.publisherFlg == true">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" @click="openConfirmerModal(item)">変更</v-btn>
              </template>
              <span>帳票確認出来る教員を変更する</span>
            </v-tooltip>
            <v-tooltip top v-if="item.publisherFlg == false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" :disabled="true">変更</v-btn>
              </template>
              <span>帳票確認出来る教員を変更する</span>
            </v-tooltip>
          </template>
          <!-- 公開状態/設定日時 -->
          <template #[`item.publishStatus`]="{ item }">
            <v-row no-gutters v-if="item.studentPublishFlg == true"><v-col cols="12" class="red--text" justify="center">(生徒)公開状況：{{ item.studentPublishStatus }}</v-col></v-row>
            <v-row no-gutters v-if="item.studentPublishFlg == true"><v-col cols="12" class="red--text" justify="center">(生徒)設定日時：{{ item.studentPublishDate }}</v-col></v-row>
            <v-row no-gutters v-if="item.parentPublishFlg == true"><v-col cols="12" class="red--text" justify="center">(保護者)公開状況：{{ item.parentPublishStatus }}</v-col></v-row>
            <v-row no-gutters v-if="item.parentPublishFlg == true"><v-col cols="12" class="red--text" justify="center">(保護者)設定日時：{{ item.parentPublishDate }}</v-col></v-row>
            <v-row no-gutters v-if="item.studentPublishFlg == false"><v-col cols="12" justify="center">(生徒)公開状況：{{ item.studentPublishStatus }}</v-col></v-row>
            <v-row no-gutters v-if="item.studentPublishFlg == false"><v-col cols="12" justify="center">(生徒)設定日時：{{ item.studentPublishDate }}</v-col></v-row>
            <v-row no-gutters v-if="item.parentPublishFlg == false"><v-col cols="12" justify="center">(保護者)公開状況：{{ item.parentPublishStatus }}</v-col></v-row>
            <v-row no-gutters v-if="item.parentPublishFlg == false"><v-col cols="12" justify="center">(保護者)設定日時：{{ item.parentPublishDate }}</v-col></v-row>
          </template>
          <!-- 生徒公開状態変更 -->
          <template #[`item.publishStatusChange`]="{ item }">
            <v-row dense><v-col cols="12" justify="center">
              <v-tooltip top v-if="item.confirmerFlg == true">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" @click="changeStudentStatus(item)">生徒変更</v-btn>
                </template>
                <span>生徒の公開状況を変更する</span>
              </v-tooltip>
              <v-tooltip top v-if="item.confirmerFlg == false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" :disabled="true">生徒変更</v-btn>
                </template>
                <span>生徒の公開状況を変更する</span>
              </v-tooltip>
            </v-col></v-row>
            <v-row dense><v-col cols="12" justify="center">
              <v-tooltip top v-if="item.confirmerFlg == true">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" @click="changeParentStatus(item)">保護者変更</v-btn>
                </template>
                <span>保護者の公開状況を変更する</span>
              </v-tooltip>
              <v-tooltip top v-if="item.confirmerFlg == false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" :disabled="true">保護者変更</v-btn>
                </template>
                <span>保護者の公開状況を変更する</span>
              </v-tooltip>
            </v-col></v-row>
          </template>

          <!-- 内容確認 -->
          <template #[`item.action`]="{ item }">
            <v-tooltip top v-if="item.confirmerFlg == true">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" @click="openTestGradeModal(item)">確認</v-btn>
              </template>
              <span>帳票の内容を確認する</span>
            </v-tooltip>
            <v-tooltip top v-if="item.confirmerFlg == false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" :disabled="true">確認</v-btn>
              </template>
              <span>帳票の内容を確認する</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fixed fab bottom color="secondary" @click="reload" style="bottom: 50px">
          <v-icon color="white">mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>再読込</span>
    </v-tooltip>

    <!-- 確認教員変更モーダル -->
    <v-dialog
      v-model="confirmerDialog"
      persistent
      max-width="800"
    >
      <ValidationObserver ref="obs" v-slot="{ invalid }">
        <v-card
          class="d-flex justify-center flex-column mx-auto flat"
          width="800"
          color="#fff"
        >
          <v-card-subtitle class="d-flex justify-center" white-space="pre">
            <v-row dense>
              <v-col dense cols="4" justify="center">
                <v-btn color="grey" class="black--text" @click="confirmerDialog = false" width="90%">
                  閉じる
                </v-btn>
              </v-col>
              <v-col dense cols="4" justify="center">
                <p>確認教員を更新する</p>
              </v-col>
              <v-col dense cols="4" justify="center">
                <v-btn color="accent" class="black--text" @click="changeConfirmer()" :disabled="invalid" width="90%">
                  更新
                </v-btn>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-card-text class="d-flex justify-center flex-column">
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <ValidationProvider name="帳票を確認する教員(自身以外)" rules="required" :skipIfEmpty="false" v-slot="{ errors }">
                  <v-autocomplete
                    v-model="confirmerIds"
                    prepend-icon="$cet_required"
                    label="帳票を確認する教員(自身以外)"
                    placeholder="帳票を確認する教員(自身以外)"
                    :items="teacherList"
                    outlined
                    dense
                    multiple
                    deletable-chips
                    small-chips
                    :error-messages="errors[0]"
                    hide-details="auto"
                    background-color="white"
                  ></v-autocomplete>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </ValidationObserver>
    </v-dialog>

    <!-- 試験成績確認モーダル -->
    <v-dialog
      v-model="testGradeDialog"
      max-width="900"
      persistent
    >
      <v-card
        class="d-flex justify-center flex-column mx-auto flat"
        width="900"
        color="#fff"
      >
        <v-row dense>
          <v-col cols="12" justify="center">
            <v-toolbar
              dark
              color="primary"
            >
              <v-btn
                icon
                dark
                @click="testGradeDialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>試験結果一覧</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-col>
        </v-row>
        <v-card-text>
          <v-data-table
            :headers="testGradeHeader"
            :items="testGradeList"
            :items-per-page="-1"
            dense
            disable-sort
            hide-default-footer
          >
            <!-- 氏名/アップロード日時 -->
            <template #[`item.userNm`]="{ item }">
              <v-row no-gutters><v-col cols="12" justify="center">{{ item.userNm }}</v-col></v-row>
              <v-row no-gutters v-if="item.delFlg == true"><v-col cols="12" justify="center">-</v-col></v-row>
              <v-row no-gutters v-if="item.delFlg == false"><v-col cols="12" justify="center">{{ item.uploadDate }}</v-col></v-row>
            </template>
            <!-- 既読状況ボタン -->
            <template #[`item.unreadStatus`]="{ item }">
              <v-row no-gutters><v-col cols="12" justify="center">生徒：{{ item.studentUnreadStatus }}</v-col></v-row>
              <v-row no-gutters><v-col cols="12" justify="center">保護者：{{ item.parentUnreadStatus }}</v-col></v-row>
            </template>
            <!-- 確認状況ボタン -->
            <template #[`item.studentConfirmStatus`]="{ item }">
              <v-row no-gutters><v-col cols="12" justify="center">状況：{{ item.studentConfirmStatus }}</v-col></v-row>
              <v-row no-gutters><v-col cols="12" justify="center">日時：{{ item.studentConfirmDate }}</v-col></v-row>
            </template>
            <!-- 削除ボタン -->
            <template #[`item.result`]="{ item }">
              <v-tooltip bottom v-if="item.delFlg == false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="onClickView(item)">
                    <v-icon color="black">mdi-file-document-outline</v-icon>
                  </v-btn>
                </template>
                <span>アップロードされた試験結果を確認する。</span>
              </v-tooltip>
            </template>
            <!-- 削除ボタン -->
            <template #[`item.delStatus`]="{ item }">
              <v-tooltip bottom v-if="item.delFlg == false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="onClickDelete(item)">
                    <v-icon color="black">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>アップロードされた試験結果を削除する。</span>
              </v-tooltip>
              <span class="red--text" v-if="item.delFlg == true">
                {{item.delStatus}}
              </span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- 試験結果表示モーダル -->
    <v-dialog
      v-model="viewGradeDialog"
      max-width="900"
      persistent
    >
      <v-card
        class="d-flex justify-center flex-column mx-auto flat"
        width="900"
        color="#fff"
      >
        <v-row dense>
          <v-col cols="12" justify="center">
            <v-toolbar
              dark
              color="primary"
            >
              <v-btn
                icon
                dark
                @click="viewGradeDialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>試験結果確認</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-col>
        </v-row>
        <v-card-text>
          <v-row v-if="kamokuNm1 != null" dense><v-col>科目名：{{ kamokuNm1 }}</v-col><v-col>点数：{{ seiseki1 }}</v-col><v-col v-if="kamokuAve1">科目平均：{{ kamokuAve1 }}</v-col><v-col v-if="deviation1">偏差値：{{ deviation1 }}</v-col><v-col v-if="rank1">順位：{{ rank1 }}</v-col></v-row>
          <v-row v-if="kamokuNm2 != null" dense><v-col>科目名：{{ kamokuNm2 }}</v-col><v-col>点数：{{ seiseki2 }}</v-col><v-col v-if="kamokuAve2">科目平均：{{ kamokuAve2 }}</v-col><v-col v-if="deviation2">偏差値：{{ deviation2 }}</v-col><v-col v-if="rank2">順位：{{ rank2 }}</v-col></v-row>
          <v-row v-if="kamokuNm3 != null" dense><v-col>科目名：{{ kamokuNm3 }}</v-col><v-col>点数：{{ seiseki3 }}</v-col><v-col v-if="kamokuAve3">科目平均：{{ kamokuAve3 }}</v-col><v-col v-if="deviation3">偏差値：{{ deviation3 }}</v-col><v-col v-if="rank3">順位：{{ rank3 }}</v-col></v-row>
          <v-row v-if="kamokuNm4 != null" dense><v-col>科目名：{{ kamokuNm4 }}</v-col><v-col>点数：{{ seiseki4 }}</v-col><v-col v-if="kamokuAve4">科目平均：{{ kamokuAve4 }}</v-col><v-col v-if="deviation4">偏差値：{{ deviation4 }}</v-col><v-col v-if="rank4">順位：{{ rank4 }}</v-col></v-row>
          <v-row v-if="kamokuNm5 != null" dense><v-col>科目名：{{ kamokuNm5 }}</v-col><v-col>点数：{{ seiseki5 }}</v-col><v-col v-if="kamokuAve5">科目平均：{{ kamokuAve5 }}</v-col><v-col v-if="deviation5">偏差値：{{ deviation5 }}</v-col><v-col v-if="rank5">順位：{{ rank5 }}</v-col></v-row>
          <v-row v-if="kamokuNm6 != null" dense><v-col>科目名：{{ kamokuNm6 }}</v-col><v-col>点数：{{ seiseki6 }}</v-col><v-col v-if="kamokuAve6">科目平均：{{ kamokuAve6 }}</v-col><v-col v-if="deviation6">偏差値：{{ deviation6 }}</v-col><v-col v-if="rank6">順位：{{ rank6 }}</v-col></v-row>
          <v-row v-if="kamokuNm7 != null" dense><v-col>科目名：{{ kamokuNm7 }}</v-col><v-col>点数：{{ seiseki7 }}</v-col><v-col v-if="kamokuAve7">科目平均：{{ kamokuAve7 }}</v-col><v-col v-if="deviation7">偏差値：{{ deviation7 }}</v-col><v-col v-if="rank7">順位：{{ rank7 }}</v-col></v-row>
          <v-row v-if="kamokuNm8 != null" dense><v-col>科目名：{{ kamokuNm8 }}</v-col><v-col>点数：{{ seiseki8 }}</v-col><v-col v-if="kamokuAve8">科目平均：{{ kamokuAve8 }}</v-col><v-col v-if="deviation8">偏差値：{{ deviation8 }}</v-col><v-col v-if="rank8">順位：{{ rank8 }}</v-col></v-row>
          <v-row v-if="kamokuNm9 != null" dense><v-col>科目名：{{ kamokuNm9 }}</v-col><v-col>点数：{{ seiseki9 }}</v-col><v-col v-if="kamokuAve9">科目平均：{{ kamokuAve9 }}</v-col><v-col v-if="deviation9">偏差値：{{ deviation9 }}</v-col><v-col v-if="rank9">順位：{{ rank9 }}</v-col></v-row>
          <v-row v-if="kamokuNm10 != null" dense><v-col>科目名：{{ kamokuNm10 }}</v-col><v-col>点数：{{ seiseki10 }}</v-col><v-col v-if="kamokuAve10">科目平均：{{ kamokuAve10 }}</v-col><v-col v-if="deviation10">偏差値：{{ deviation10 }}</v-col><v-col v-if="rank10">順位：{{ rank10 }}</v-col></v-row>
          <v-row v-if="kamokuNm11 != null" dense><v-col>科目名：{{ kamokuNm11 }}</v-col><v-col>点数：{{ seiseki11 }}</v-col><v-col v-if="kamokuAve11">科目平均：{{ kamokuAve11 }}</v-col><v-col v-if="deviation11">偏差値：{{ deviation11 }}</v-col><v-col v-if="rank11">順位：{{ rank11 }}</v-col></v-row>
          <v-row v-if="kamokuNm12 != null" dense><v-col>科目名：{{ kamokuNm12 }}</v-col><v-col>点数：{{ seiseki12 }}</v-col><v-col v-if="kamokuAve12">科目平均：{{ kamokuAve12 }}</v-col><v-col v-if="deviation12">偏差値：{{ deviation12 }}</v-col><v-col v-if="rank12">順位：{{ rank12 }}</v-col></v-row>
          <v-row v-if="kamokuNm13 != null" dense><v-col>科目名：{{ kamokuNm13 }}</v-col><v-col>点数：{{ seiseki13 }}</v-col><v-col v-if="kamokuAve13">科目平均：{{ kamokuAve13 }}</v-col><v-col v-if="deviation13">偏差値：{{ deviation13 }}</v-col><v-col v-if="rank13">順位：{{ rank13 }}</v-col></v-row>
          <v-row v-if="kamokuNm14 != null" dense><v-col>科目名：{{ kamokuNm14 }}</v-col><v-col>点数：{{ seiseki14 }}</v-col><v-col v-if="kamokuAve14">科目平均：{{ kamokuAve14 }}</v-col><v-col v-if="deviation14">偏差値：{{ deviation14 }}</v-col><v-col v-if="rank14">順位：{{ rank14 }}</v-col></v-row>
          <v-row v-if="kamokuNm15 != null" dense><v-col>科目名：{{ kamokuNm15 }}</v-col><v-col>点数：{{ seiseki15 }}</v-col><v-col v-if="kamokuAve15">科目平均：{{ kamokuAve15 }}</v-col><v-col v-if="deviation15">偏差値：{{ deviation15 }}</v-col><v-col v-if="rank15">順位：{{ rank15 }}</v-col></v-row>
          <v-row v-if="kamokuNm16 != null" dense><v-col>科目名：{{ kamokuNm16 }}</v-col><v-col>点数：{{ seiseki16 }}</v-col><v-col v-if="kamokuAve16">科目平均：{{ kamokuAve16 }}</v-col><v-col v-if="deviation16">偏差値：{{ deviation16 }}</v-col><v-col v-if="rank16">順位：{{ rank16 }}</v-col></v-row>
          <v-row v-if="kamokuNm17 != null" dense><v-col>科目名：{{ kamokuNm17 }}</v-col><v-col>点数：{{ seiseki17 }}</v-col><v-col v-if="kamokuAve17">科目平均：{{ kamokuAve17 }}</v-col><v-col v-if="deviation17">偏差値：{{ deviation17 }}</v-col><v-col v-if="rank17">順位：{{ rank17 }}</v-col></v-row>
          <v-row v-if="kamokuNm18 != null" dense><v-col>科目名：{{ kamokuNm18 }}</v-col><v-col>点数：{{ seiseki18 }}</v-col><v-col v-if="kamokuAve18">科目平均：{{ kamokuAve18 }}</v-col><v-col v-if="deviation18">偏差値：{{ deviation18 }}</v-col><v-col v-if="rank18">順位：{{ rank18 }}</v-col></v-row>
          <v-row v-if="kamokuNm19 != null" dense><v-col>科目名：{{ kamokuNm19 }}</v-col><v-col>点数：{{ seiseki19 }}</v-col><v-col v-if="kamokuAve19">科目平均：{{ kamokuAve19 }}</v-col><v-col v-if="deviation19">偏差値：{{ deviation19 }}</v-col><v-col v-if="rank19">順位：{{ rank19 }}</v-col></v-row>
          <v-row v-if="kamokuNm20 != null" dense><v-col>科目名：{{ kamokuNm20 }}</v-col><v-col>点数：{{ seiseki20 }}</v-col><v-col v-if="kamokuAve20">科目平均：{{ kamokuAve20 }}</v-col><v-col v-if="deviation20">偏差値：{{ deviation20 }}</v-col><v-col v-if="rank20">順位：{{ rank20 }}</v-col></v-row>
          <v-row v-if="kamokuNm21 != null" dense><v-col>科目名：{{ kamokuNm21 }}</v-col><v-col>点数：{{ seiseki21 }}</v-col><v-col v-if="kamokuAve21">科目平均：{{ kamokuAve21 }}</v-col><v-col v-if="deviation21">偏差値：{{ deviation21 }}</v-col><v-col v-if="rank21">順位：{{ rank21 }}</v-col></v-row>
          <v-row v-if="kamokuNm22 != null" dense><v-col>科目名：{{ kamokuNm22 }}</v-col><v-col>点数：{{ seiseki22 }}</v-col><v-col v-if="kamokuAve22">科目平均：{{ kamokuAve22 }}</v-col><v-col v-if="deviation22">偏差値：{{ deviation22 }}</v-col><v-col v-if="rank22">順位：{{ rank22 }}</v-col></v-row>
          <v-row v-if="kamokuNm23 != null" dense><v-col>科目名：{{ kamokuNm23 }}</v-col><v-col>点数：{{ seiseki23 }}</v-col><v-col v-if="kamokuAve23">科目平均：{{ kamokuAve23 }}</v-col><v-col v-if="deviation23">偏差値：{{ deviation23 }}</v-col><v-col v-if="rank23">順位：{{ rank23 }}</v-col></v-row>
          <v-row v-if="kamokuNm24 != null" dense><v-col>科目名：{{ kamokuNm24 }}</v-col><v-col>点数：{{ seiseki24 }}</v-col><v-col v-if="kamokuAve24">科目平均：{{ kamokuAve24 }}</v-col><v-col v-if="deviation24">偏差値：{{ deviation24 }}</v-col><v-col v-if="rank24">順位：{{ rank24 }}</v-col></v-row>
          <v-row v-if="kamokuNm25 != null" dense><v-col>科目名：{{ kamokuNm25 }}</v-col><v-col>点数：{{ seiseki25 }}</v-col><v-col v-if="kamokuAve25">科目平均：{{ kamokuAve25 }}</v-col><v-col v-if="deviation25">偏差値：{{ deviation25 }}</v-col><v-col v-if="rank25">順位：{{ rank25 }}</v-col></v-row>
          <v-row v-if="kamokuNm26 != null" dense><v-col>科目名：{{ kamokuNm26 }}</v-col><v-col>点数：{{ seiseki26 }}</v-col><v-col v-if="kamokuAve26">科目平均：{{ kamokuAve26 }}</v-col><v-col v-if="deviation26">偏差値：{{ deviation26 }}</v-col><v-col v-if="rank26">順位：{{ rank26 }}</v-col></v-row>
          <v-row v-if="kamokuNm27 != null" dense><v-col>科目名：{{ kamokuNm27 }}</v-col><v-col>点数：{{ seiseki27 }}</v-col><v-col v-if="kamokuAve27">科目平均：{{ kamokuAve27 }}</v-col><v-col v-if="deviation27">偏差値：{{ deviation27 }}</v-col><v-col v-if="rank27">順位：{{ rank27 }}</v-col></v-row>
          <v-row v-if="kamokuNm28 != null" dense><v-col>科目名：{{ kamokuNm28 }}</v-col><v-col>点数：{{ seiseki28 }}</v-col><v-col v-if="kamokuAve28">科目平均：{{ kamokuAve28 }}</v-col><v-col v-if="deviation28">偏差値：{{ deviation28 }}</v-col><v-col v-if="rank28">順位：{{ rank28 }}</v-col></v-row>
          <v-row v-if="kamokuNm29 != null" dense><v-col>科目名：{{ kamokuNm29 }}</v-col><v-col>点数：{{ seiseki29 }}</v-col><v-col v-if="kamokuAve29">科目平均：{{ kamokuAve29 }}</v-col><v-col v-if="deviation29">偏差値：{{ deviation29 }}</v-col><v-col v-if="rank29">順位：{{ rank29 }}</v-col></v-row>
          <v-row v-if="kamokuNm30 != null" dense><v-col>科目名：{{ kamokuNm30 }}</v-col><v-col>点数：{{ seiseki30 }}</v-col><v-col v-if="kamokuAve30">科目平均：{{ kamokuAve30 }}</v-col><v-col v-if="deviation30">偏差値：{{ deviation30 }}</v-col><v-col v-if="rank30">順位：{{ rank30 }}</v-col></v-row>
          <v-row v-if="kamokuNm31 != null" dense><v-col>科目名：{{ kamokuNm31 }}</v-col><v-col>点数：{{ seiseki31 }}</v-col><v-col v-if="kamokuAve31">科目平均：{{ kamokuAve31 }}</v-col><v-col v-if="deviation31">偏差値：{{ deviation31 }}</v-col><v-col v-if="rank31">順位：{{ rank31 }}</v-col></v-row>
          <v-row v-if="kamokuNm32 != null" dense><v-col>科目名：{{ kamokuNm32 }}</v-col><v-col>点数：{{ seiseki32 }}</v-col><v-col v-if="kamokuAve32">科目平均：{{ kamokuAve32 }}</v-col><v-col v-if="deviation32">偏差値：{{ deviation32 }}</v-col><v-col v-if="rank32">順位：{{ rank32 }}</v-col></v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { httpsCallable, getFunctions } from "firebase/functions";
export default {
  data() {
    return {
      tounendo: "",
      selectNendo: "",
      nendoList : [],
      publisherValue: "",
      publisherList : [],
      headers : [
        { text: "連携日時/対象学級", value: "insDate" , width: "250" },
        { text: "発行教員/確認教員", value: "publisherNm" , width: "250", filter: this.teacherFilter }, 
        { text: "確認教員変更", value: "confirmerChange" , width: "100" },
        { text: "公開状況", value: "publishStatus" , width: "250" }, 
        { text: "公開状況設定", value: "publishStatusChange" , width: "80" },
        { text: "内容確認", value: "action", width: "80" },
      ],
      reportList: [],
      isLoding: false,
      // 確認教員更新用
      confirmerDialog: false,
      nendo: "",
      kbkgc: "",
      sikenCd: "",
      confirmerIds: [],
      teacherList:[],
      testGradeDialog: false,
      testGradeHeader: [
        { text: "出席番号", value: "syussekiNo" , width: "60" },
        { text: "氏名/アップロード日時", value: "userNm" , width: "280" },
        { text: "既読状況", value: "unreadStatus" , width: "120" },
        { text: "生徒確認", value: "studentConfirmStatus" , width: "120" },
        { text: "内容確認", value: "result" , width: "80" },
        { text: "削除", value: "delStatus" , width: "80" },
      ],
      testGradeList: [],
      perPage: 30,

      // 成績表示モーダル用
      viewGradeDialog: false,
      kamokuNm1:null,
      kamokuNm2:null,
      kamokuNm3:null,
      kamokuNm4:null,
      kamokuNm5:null,
      kamokuNm6:null,
      kamokuNm7:null,
      kamokuNm8:null,
      kamokuNm9:null,
      kamokuNm10:null,
      kamokuNm11:null,
      kamokuNm12:null,
      kamokuNm13:null,
      kamokuNm14:null,
      kamokuNm15:null,
      kamokuNm16:null,
      kamokuNm17:null,
      kamokuNm18:null,
      kamokuNm19:null,
      kamokuNm20:null,
      kamokuNm21:null,
      kamokuNm22:null,
      kamokuNm23:null,
      kamokuNm24:null,
      kamokuNm25:null,
      kamokuNm26:null,
      kamokuNm27:null,
      kamokuNm28:null,
      kamokuNm29:null,
      kamokuNm30:null,
      kamokuNm31:null,
      kamokuNm32:null,
      seiseki1:null,
      seiseki2:null,
      seiseki3:null,
      seiseki4:null,
      seiseki5:null,
      seiseki6:null,
      seiseki7:null,
      seiseki8:null,
      seiseki9:null,
      seiseki10:null,
      seiseki11:null,
      seiseki12:null,
      seiseki13:null,
      seiseki14:null,
      seiseki15:null,
      seiseki16:null,
      seiseki17:null,
      seiseki18:null,
      seiseki19:null,
      seiseki20:null,
      seiseki21:null,
      seiseki22:null,
      seiseki23:null,
      seiseki24:null,
      seiseki25:null,
      seiseki26:null,
      seiseki27:null,
      seiseki28:null,
      seiseki29:null,
      seiseki30:null,
      seiseki31:null,
      seiseki32:null,
      kamokuAve1:null,
      kamokuAve2:null,
      kamokuAve3:null,
      kamokuAve4:null,
      kamokuAve5:null,
      kamokuAve6:null,
      kamokuAve7:null,
      kamokuAve8:null,
      kamokuAve9:null,
      kamokuAve10:null,
      kamokuAve11:null,
      kamokuAve12:null,
      kamokuAve13:null,
      kamokuAve14:null,
      kamokuAve15:null,
      kamokuAve16:null,
      kamokuAve17:null,
      kamokuAve18:null,
      kamokuAve19:null,
      kamokuAve20:null,
      kamokuAve21:null,
      kamokuAve22:null,
      kamokuAve23:null,
      kamokuAve24:null,
      kamokuAve25:null,
      kamokuAve26:null,
      kamokuAve27:null,
      kamokuAve28:null,
      kamokuAve29:null,
      kamokuAve30:null,
      kamokuAve31:null,
      kamokuAve32:null,
      deviation1:null,
      deviation2:null,
      deviation3:null,
      deviation4:null,
      deviation5:null,
      deviation6:null,
      deviation7:null,
      deviation8:null,
      deviation9:null,
      deviation10:null,
      deviation11:null,
      deviation12:null,
      deviation13:null,
      deviation14:null,
      deviation15:null,
      deviation16:null,
      deviation17:null,
      deviation18:null,
      deviation19:null,
      deviation20:null,
      deviation21:null,
      deviation22:null,
      deviation23:null,
      deviation24:null,
      deviation25:null,
      deviation26:null,
      deviation27:null,
      deviation28:null,
      deviation29:null,
      deviation30:null,
      deviation31:null,
      deviation32:null,
      rank1:null,
      rank2:null,
      rank3:null,
      rank4:null,
      rank5:null,
      rank6:null,
      rank7:null,
      rank8:null,
      rank9:null,
      rank10:null,
      rank11:null,
      rank12:null,
      rank13:null,
      rank14:null,
      rank15:null,
      rank16:null,
      rank17:null,
      rank18:null,
      rank19:null,
      rank20:null,
      rank21:null,
      rank22:null,
      rank23:null,
      rank24:null,
      rank25:null,
      rank26:null,
      rank27:null,
      rank28:null,
      rank29:null,
      rank30:null,
      rank31:null,
      rank32:null,
      totalSeiseki:null,
      seisekiAve:null,
      deviationAve:null,
      classRank:null,
      classDenominator:null,
      gakunenRank:null,
      gakunenDenominator:null,
      courseRank:null,
      courseDenominator:null,
    };
  },
  // 初期表示時データ読み込み
  async created() {
    // 年度リストの生成
    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";
    const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
    await schoolAidHomeLogined({funcName: "commonGetNendo"}).then((ret) => {
      this.tounendo = ret.data.tounendo;
      this.selectNendo = ret.data.tounendo;
      this.nendoList = ret.data.list;
    });

    schoolAidHomeLogined({funcName: "commonGetTeachers", includeFlg: false}).then((ret) => {
      this.teacherList = ret.data.teacherList;
    })

    await this.reload();
  },
  methods: {
    // 発行者名フィルタ
    teacherFilter(value) {
      if (!this.publisherValue) {
        return true;
      }
      return value.includes(this.publisherValue);
    },
    // リスト読込
    async reload() {
      // 結果格納用配列
      this.reportList = [];
      // 条件格納用配列
      var temp_teachers = new Set();
      // 検索処理
      this.isLoding = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined({funcName:"loadGradePublishSettings", nendo:this.selectNendo }).then((ret) => {
        // 発行者の名前をリスト化
        ret.data.forEach((row) => {
          temp_teachers.add(row.publisherNm);
        });
        this.reportList = ret.data;
        this.isLoding = false;
      });
      this.publisherList = Array.from(temp_teachers);
    },
    // 確認教員変更モーダルを表示する
    openConfirmerModal(item) {
      this.confirmerIds = item.confirmerIds;
      this.nendo = item.nendo;
      this.kbkgc = item.kbkgc;
      this.sikenCd = item.sikenCd;
      this.confirmerDialog = true;
    },
    // 確認教員を変更する
    async changeConfirmer() {
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      var param = {
        funcName:"updateGradePublishSettingConfirmerId",
        nendo: this.nendo,
        kbkgc: this.kbkgc,
        sikenCd: this.sikenCd,
        confirmerIds: this.confirmerIds,
      }
      await schoolAidHomeLogined(param).then((ret) => {
        console.log(ret);
      });
      this.confirmerDialog = false;
      await this.reload();
    },
    // 生徒状態更新
    async changeStudentStatus(item){
      var result = confirm("生徒の公開状態を変更してよろしいですか？");
      if (!result) {
        return;
      }
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      var param = {
        funcName:"changeGradePublishSettingStudent",
        nendo: item.nendo,
        kbkgc: item.kbkgc,
        sikenCd: item.sikenCd,
        publishFlg: item.studentPublishFlg,
      }
      await schoolAidHomeLogined(param).then((ret) => {
        item.studentPublishFlg = ret.data.publishFlg;
        item.studentPublishStatus = ret.data.publishStatus;
        item.studentPublishDate = ret.data.publishDate;
      });
    },
    // 保護者状態更新
    async changeParentStatus(item){
      var result = confirm("保護者の公開状態を変更してよろしいですか？");
      if (!result) {
        return;
      }
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      var param = {
        funcName:"changeGradePublishSettingParent",
        nendo: item.nendo,
        kbkgc: item.kbkgc,
        sikenCd: item.sikenCd,
        publishFlg: item.parentPublishFlg,
      }
      await schoolAidHomeLogined(param).then((ret) => {
        item.parentPublishFlg = ret.data.publishFlg;
        item.parentPublishStatus = ret.data.publishStatus;
        item.parentPublishDate = ret.data.publishDate;
      });
    },
    // 試験成績一覧をモーダルで表示する
    async openTestGradeModal(item) {
      // 試験結果削除のために退避
      this.nendo = item.nendo;
      this.kbkgc = item.kbkgc;
      this.sikenCd = item.sikenCd;
      
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      var param = {
        funcName:"loadTestGradeList",
        nendo: item.nendo,
        kbkgc: item.kbkgc,
        sikenCd: item.sikenCd,
      }
      this.testGradeList = [];
      await schoolAidHomeLogined(param).then((ret) => {
        this.testGradeList = ret.data;
      });
      this.testGradeDialog = true;
    },
    // 試験結果確認
    async onClickView(item){
      this.resetGrade();
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      var param = {
        funcName:"viewTestGrade",
        nendo:this.nendo,
        kbkgc:this.kbkgc,
        sikenCd:this.sikenCd,
        userId:item.userId,
      }
      await schoolAidHomeLogined(param).then((ret) => {
        this.kamokuNm1=ret.data.kamokuNm1;
        this.kamokuNm2=ret.data.kamokuNm2;
        this.kamokuNm3=ret.data.kamokuNm3;
        this.kamokuNm4=ret.data.kamokuNm4;
        this.kamokuNm5=ret.data.kamokuNm5;
        this.kamokuNm6=ret.data.kamokuNm6;
        this.kamokuNm7=ret.data.kamokuNm7;
        this.kamokuNm8=ret.data.kamokuNm8;
        this.kamokuNm9=ret.data.kamokuNm9;
        this.kamokuNm10=ret.data.kamokuNm10;
        this.kamokuNm11=ret.data.kamokuNm11;
        this.kamokuNm12=ret.data.kamokuNm12;
        this.kamokuNm13=ret.data.kamokuNm13;
        this.kamokuNm14=ret.data.kamokuNm14;
        this.kamokuNm15=ret.data.kamokuNm15;
        this.kamokuNm16=ret.data.kamokuNm16;
        this.kamokuNm17=ret.data.kamokuNm17;
        this.kamokuNm18=ret.data.kamokuNm18;
        this.kamokuNm19=ret.data.kamokuNm19;
        this.kamokuNm20=ret.data.kamokuNm20;
        this.kamokuNm21=ret.data.kamokuNm21;
        this.kamokuNm22=ret.data.kamokuNm22;
        this.kamokuNm23=ret.data.kamokuNm23;
        this.kamokuNm24=ret.data.kamokuNm24;
        this.kamokuNm25=ret.data.kamokuNm25;
        this.kamokuNm26=ret.data.kamokuNm26;
        this.kamokuNm27=ret.data.kamokuNm27;
        this.kamokuNm28=ret.data.kamokuNm28;
        this.kamokuNm29=ret.data.kamokuNm29;
        this.kamokuNm30=ret.data.kamokuNm30;
        this.kamokuNm31=ret.data.kamokuNm31;
        this.kamokuNm32=ret.data.kamokuNm32;
        this.seiseki1=ret.data.seiseki1;
        this.seiseki2=ret.data.seiseki2;
        this.seiseki3=ret.data.seiseki3;
        this.seiseki4=ret.data.seiseki4;
        this.seiseki5=ret.data.seiseki5;
        this.seiseki6=ret.data.seiseki6;
        this.seiseki7=ret.data.seiseki7;
        this.seiseki8=ret.data.seiseki8;
        this.seiseki9=ret.data.seiseki9;
        this.seiseki10=ret.data.seiseki10;
        this.seiseki11=ret.data.seiseki11;
        this.seiseki12=ret.data.seiseki12;
        this.seiseki13=ret.data.seiseki13;
        this.seiseki14=ret.data.seiseki14;
        this.seiseki15=ret.data.seiseki15;
        this.seiseki16=ret.data.seiseki16;
        this.seiseki17=ret.data.seiseki17;
        this.seiseki18=ret.data.seiseki18;
        this.seiseki19=ret.data.seiseki19;
        this.seiseki20=ret.data.seiseki20;
        this.seiseki21=ret.data.seiseki21;
        this.seiseki22=ret.data.seiseki22;
        this.seiseki23=ret.data.seiseki23;
        this.seiseki24=ret.data.seiseki24;
        this.seiseki25=ret.data.seiseki25;
        this.seiseki26=ret.data.seiseki26;
        this.seiseki27=ret.data.seiseki27;
        this.seiseki28=ret.data.seiseki28;
        this.seiseki29=ret.data.seiseki29;
        this.seiseki30=ret.data.seiseki30;
        this.seiseki31=ret.data.seiseki31;
        this.seiseki32=ret.data.seiseki32;
        this.kamokuAve1=ret.data.kamokuAve1;
        this.kamokuAve2=ret.data.kamokuAve2;
        this.kamokuAve3=ret.data.kamokuAve3;
        this.kamokuAve4=ret.data.kamokuAve4;
        this.kamokuAve5=ret.data.kamokuAve5;
        this.kamokuAve6=ret.data.kamokuAve6;
        this.kamokuAve7=ret.data.kamokuAve7;
        this.kamokuAve8=ret.data.kamokuAve8;
        this.kamokuAve9=ret.data.kamokuAve9;
        this.kamokuAve10=ret.data.kamokuAve10;
        this.kamokuAve11=ret.data.kamokuAve11;
        this.kamokuAve12=ret.data.kamokuAve12;
        this.kamokuAve13=ret.data.kamokuAve13;
        this.kamokuAve14=ret.data.kamokuAve14;
        this.kamokuAve15=ret.data.kamokuAve15;
        this.kamokuAve16=ret.data.kamokuAve16;
        this.kamokuAve17=ret.data.kamokuAve17;
        this.kamokuAve18=ret.data.kamokuAve18;
        this.kamokuAve19=ret.data.kamokuAve19;
        this.kamokuAve20=ret.data.kamokuAve20;
        this.kamokuAve21=ret.data.kamokuAve21;
        this.kamokuAve22=ret.data.kamokuAve22;
        this.kamokuAve23=ret.data.kamokuAve23;
        this.kamokuAve24=ret.data.kamokuAve24;
        this.kamokuAve25=ret.data.kamokuAve25;
        this.kamokuAve26=ret.data.kamokuAve26;
        this.kamokuAve27=ret.data.kamokuAve27;
        this.kamokuAve28=ret.data.kamokuAve28;
        this.kamokuAve29=ret.data.kamokuAve29;
        this.kamokuAve30=ret.data.kamokuAve30;
        this.kamokuAve31=ret.data.kamokuAve31;
        this.kamokuAve32=ret.data.kamokuAve32;
        this.deviation1=ret.data.deviation1;
        this.deviation2=ret.data.deviation2;
        this.deviation3=ret.data.deviation3;
        this.deviation4=ret.data.deviation4;
        this.deviation5=ret.data.deviation5;
        this.deviation6=ret.data.deviation6;
        this.deviation7=ret.data.deviation7;
        this.deviation8=ret.data.deviation8;
        this.deviation9=ret.data.deviation9;
        this.deviation10=ret.data.deviation10;
        this.deviation11=ret.data.deviation11;
        this.deviation12=ret.data.deviation12;
        this.deviation13=ret.data.deviation13;
        this.deviation14=ret.data.deviation14;
        this.deviation15=ret.data.deviation15;
        this.deviation16=ret.data.deviation16;
        this.deviation17=ret.data.deviation17;
        this.deviation18=ret.data.deviation18;
        this.deviation19=ret.data.deviation19;
        this.deviation20=ret.data.deviation20;
        this.deviation21=ret.data.deviation21;
        this.deviation22=ret.data.deviation22;
        this.deviation23=ret.data.deviation23;
        this.deviation24=ret.data.deviation24;
        this.deviation25=ret.data.deviation25;
        this.deviation26=ret.data.deviation26;
        this.deviation27=ret.data.deviation27;
        this.deviation28=ret.data.deviation28;
        this.deviation29=ret.data.deviation29;
        this.deviation30=ret.data.deviation30;
        this.deviation31=ret.data.deviation31;
        this.deviation32=ret.data.deviation32;
        this.rank1=ret.data.rank1;
        this.rank2=ret.data.rank2;
        this.rank3=ret.data.rank3;
        this.rank4=ret.data.rank4;
        this.rank5=ret.data.rank5;
        this.rank6=ret.data.rank6;
        this.rank7=ret.data.rank7;
        this.rank8=ret.data.rank8;
        this.rank9=ret.data.rank9;
        this.rank10=ret.data.rank10;
        this.rank11=ret.data.rank11;
        this.rank12=ret.data.rank12;
        this.rank13=ret.data.rank13;
        this.rank14=ret.data.rank14;
        this.rank15=ret.data.rank15;
        this.rank16=ret.data.rank16;
        this.rank17=ret.data.rank17;
        this.rank18=ret.data.rank18;
        this.rank19=ret.data.rank19;
        this.rank20=ret.data.rank20;
        this.rank21=ret.data.rank21;
        this.rank22=ret.data.rank22;
        this.rank23=ret.data.rank23;
        this.rank24=ret.data.rank24;
        this.rank25=ret.data.rank25;
        this.rank26=ret.data.rank26;
        this.rank27=ret.data.rank27;
        this.rank28=ret.data.rank28;
        this.rank29=ret.data.rank29;
        this.rank30=ret.data.rank30;
        this.rank31=ret.data.rank31;
        this.rank32=ret.data.rank32;
        this.totalSeiseki=ret.data.totalSeiseki;
        this.seisekiAve=ret.data.seisekiAve;
        this.deviationAve=ret.data.deviationAve;
        this.classRank=ret.data.classRank;
        this.classDenominator=ret.data.classDenominator;
        this.gakunenRank=ret.data.gakunenRank;
        this.gakunenDenominator=ret.data.gakunenDenominator;
        this.courseRank=ret.data.courseRank;
        this.courseDenominator=ret.data.courseDenominator;
        this.viewGradeDialog = true;
      });
    },
    // 試験結果削除
    async onClickDelete(item){
      var result = confirm("アップロードされた試験結果を削除してよろしいですか？");
      if (!result) {
        return;
      }
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      var param = {
        funcName:"deleteTestGrade",
        nendo:this.nendo,
        kbkgc:this.kbkgc,
        sikenCd:this.sikenCd,
        userId:item.userId,
      }
      await schoolAidHomeLogined(param).then((ret) => {
        item.delFlg = ret.data.delFlg;
        item.delStatus = ret.data.delStatus;
      });
    },
    resetGrade(){
      this.kamokuNm1=null;
      this.kamokuNm2=null;
      this.kamokuNm3=null;
      this.kamokuNm4=null;
      this.kamokuNm5=null;
      this.kamokuNm6=null;
      this.kamokuNm7=null;
      this.kamokuNm8=null;
      this.kamokuNm9=null;
      this.kamokuNm10=null;
      this.kamokuNm11=null;
      this.kamokuNm12=null;
      this.kamokuNm13=null;
      this.kamokuNm14=null;
      this.kamokuNm15=null;
      this.kamokuNm16=null;
      this.kamokuNm17=null;
      this.kamokuNm18=null;
      this.kamokuNm19=null;
      this.kamokuNm20=null;
      this.kamokuNm21=null;
      this.kamokuNm22=null;
      this.kamokuNm23=null;
      this.kamokuNm24=null;
      this.kamokuNm25=null;
      this.kamokuNm26=null;
      this.kamokuNm27=null;
      this.kamokuNm28=null;
      this.kamokuNm29=null;
      this.kamokuNm30=null;
      this.kamokuNm31=null;
      this.kamokuNm32=null;
      this.seiseki1=null;
      this.seiseki2=null;
      this.seiseki3=null;
      this.seiseki4=null;
      this.seiseki5=null;
      this.seiseki6=null;
      this.seiseki7=null;
      this.seiseki8=null;
      this.seiseki9=null;
      this.seiseki10=null;
      this.seiseki11=null;
      this.seiseki12=null;
      this.seiseki13=null;
      this.seiseki14=null;
      this.seiseki15=null;
      this.seiseki16=null;
      this.seiseki17=null;
      this.seiseki18=null;
      this.seiseki19=null;
      this.seiseki20=null;
      this.seiseki21=null;
      this.seiseki22=null;
      this.seiseki23=null;
      this.seiseki24=null;
      this.seiseki25=null;
      this.seiseki26=null;
      this.seiseki27=null;
      this.seiseki28=null;
      this.seiseki29=null;
      this.seiseki30=null;
      this.seiseki31=null;
      this.seiseki32=null;
      this.kamokuAve1=null;
      this.kamokuAve2=null;
      this.kamokuAve3=null;
      this.kamokuAve4=null;
      this.kamokuAve5=null;
      this.kamokuAve6=null;
      this.kamokuAve7=null;
      this.kamokuAve8=null;
      this.kamokuAve9=null;
      this.kamokuAve10=null;
      this.kamokuAve11=null;
      this.kamokuAve12=null;
      this.kamokuAve13=null;
      this.kamokuAve14=null;
      this.kamokuAve15=null;
      this.kamokuAve16=null;
      this.kamokuAve17=null;
      this.kamokuAve18=null;
      this.kamokuAve19=null;
      this.kamokuAve20=null;
      this.kamokuAve21=null;
      this.kamokuAve22=null;
      this.kamokuAve23=null;
      this.kamokuAve24=null;
      this.kamokuAve25=null;
      this.kamokuAve26=null;
      this.kamokuAve27=null;
      this.kamokuAve28=null;
      this.kamokuAve29=null;
      this.kamokuAve30=null;
      this.kamokuAve31=null;
      this.kamokuAve32=null;
      this.deviation1=null;
      this.deviation2=null;
      this.deviation3=null;
      this.deviation4=null;
      this.deviation5=null;
      this.deviation6=null;
      this.deviation7=null;
      this.deviation8=null;
      this.deviation9=null;
      this.deviation10=null;
      this.deviation11=null;
      this.deviation12=null;
      this.deviation13=null;
      this.deviation14=null;
      this.deviation15=null;
      this.deviation16=null;
      this.deviation17=null;
      this.deviation18=null;
      this.deviation19=null;
      this.deviation20=null;
      this.deviation21=null;
      this.deviation22=null;
      this.deviation23=null;
      this.deviation24=null;
      this.deviation25=null;
      this.deviation26=null;
      this.deviation27=null;
      this.deviation28=null;
      this.deviation29=null;
      this.deviation30=null;
      this.deviation31=null;
      this.deviation32=null;
      this.rank1=null;
      this.rank2=null;
      this.rank3=null;
      this.rank4=null;
      this.rank5=null;
      this.rank6=null;
      this.rank7=null;
      this.rank8=null;
      this.rank9=null;
      this.rank10=null;
      this.rank11=null;
      this.rank12=null;
      this.rank13=null;
      this.rank14=null;
      this.rank15=null;
      this.rank16=null;
      this.rank17=null;
      this.rank18=null;
      this.rank19=null;
      this.rank20=null;
      this.rank21=null;
      this.rank22=null;
      this.rank23=null;
      this.rank24=null;
      this.rank25=null;
      this.rank26=null;
      this.rank27=null;
      this.rank28=null;
      this.rank29=null;
      this.rank30=null;
      this.rank31=null;
      this.rank32=null;
      this.totalSeiseki=null;
      this.seisekiAve=null;
      this.deviationAve=null;
      this.classRank=null;
      this.classDenominator=null;
      this.gakunenRank=null;
      this.gakunenDenominator=null;
      this.courseRank=null;
      this.courseDenominator=null;
    },
    // 成績開示設定(通知表)画面へ
    toReportPublishSettings() {
      this.$router.push({
        name: "ReportPublishSettings",
      });
    },
  },
};
</script>

<style scoped>
.modal-header, .modal-body {
  padding: 5px 25px;
}
.modal-header {
  border-bottom: 1px solid #ddd;
}

</style>