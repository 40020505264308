<template>
  <v-container fluid>
    <v-card
      class="d-flex justify-center flex-column mx-auto my-6 flat"
      color="#fff"
    >
      <v-card-title class="d-flex justify-center pa-0 mt-6"
        >利用規約の確認</v-card-title
      >
      <v-card-text class="d-flex justify-center flex-column">
        <v-textarea readonly label="利用規約本文" 
         style="white-space:pre; font-size: small;" 
         rows="11"
         v-model="textfield"
         hide-details="auto"
        >
        </v-textarea>
      </v-card-text>
      <v-card-text class="d-flex justify-center flex-column" v-if="consentFlg == false">
        <v-row>
          <v-col cols="1" class="pr-0">
            <v-checkbox v-model="mycheck" label="" hide-details="auto" />
          </v-col>
          <v-col cols="11" class="pt-6">
            <p>利用規約及び<a href="#" @click.prevent.stop="privacyPolicyClick()">プライバシーポリシー</a>に同意する。</p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="consentFlg == false">
        <v-btn color="accent" class="black--text" @click="consentSave" :disabled="!this.mycheck" width="100%"
          >同意</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { httpsCallable, getFunctions } from "firebase/functions";
export default {
  data() {
    return {
      consentFlg: true,
      mycheck: false,
      textfield: `「SCHOOLAID HOME」  利用規約
第１条（本規約の目的）
１．この「SCHOOLAID HOME利用規約」（以下、「本規約」といいます。）は、キヤノン電子テクノロジー株式会社（以下、当社という)が運営するアプリケーション「SCHOOLAID HOME」を通じて当社が提供するサービス（以下、「本サービス」といいます。）に関して、当社と本サービスを利用するユーザーの利用条件を定めたものです。
２．本サービスは、本規約に同意した者のみが利用でき、同意した時点で、本規約による契約（以下、「本契約」といいます。）が成立するものとします。
３．なお、未成年者であるユーザーが自ら本サービスを利用する場合は、必ず親権者の同意を得てください。
「同意」ボタンをクリックすることをもって、親権者の同意を得たうえで、本規約に同意したものとします。

第２条（用語の定義）
本規約において使用される用語の意味は以下のとおりとします。
（1）「本サービス」とは、当社が運営するオンライン連絡のプラットフォームアプリケーション「SCHOOLAID HOME」において 展開されるサービスをいいます。
（2）「本アプリ」とは、モバイル用端末(iOSスマートフォン、iOSタブレット、Androidスマートフォン)で本サービスを使用することをいいます。
（3）「ユーザー」とは、本サービスを利用して学校法人の許諾した関係者との連絡を行う者をいいます
（4）「知的財産」とは、著作権、特許権、実用新案権、商標権、意匠権その他の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含み、著作権については著作権法第 27 条および第 28 条に定める権利を含みます。）をいいます。

第３条（パスワードの発行・管理）
１．当社は、学校法人を通じて、ユーザーに対して、個別のユーザーを識別するために必要となる ID およびパスワード（以下、「ID 等」といいます。）を発行するものとし、当該 ID 等をユーザーに通知します。
２．ユーザーは、前項に基づき発行された ID 等について、第三者に開示、貸与、共有等しないものとし、第三者に漏えいすることのないよう自らの責任において厳重に管理し、定期的にパスワードの変更を行うなど、ID 等の盗用を防止する措置を講じるものとします。
３．当社は、本サービスの利用にあたり使用された ID 等がユーザーのものである場合には、ユーザーによる利用とみなします。
４．第三者がユーザーの ID 等を用いて本サービスを利用した場合、ユーザーはかかる利用に関する一切の責任を負うものとします。

第４条（知的財産権等） 
本サービスを構成する文章、画像、プログラムその他のデータ等についての一切の権利（所有権、知的財産権、肖像権等）は、当社または当該権利を有する第三者に帰属します。
ユーザーとしての利用以外の、 複製、転載、頒布、販売、改変、その他著作権侵害となるような行為は禁止されております。

第５条（禁止事項）
１．ユーザーは、本サービスの利用にあたり、以下各号の行為またはそのおそれがある行為を行ってはならないものとします。
（1）当社または第三者が保有する知的財産権、プライバシー権、肖像権その他の権利等を侵害する行為、または侵害するおそれのある行為。
（2）当社または第三者に不利益や損害を与える行為、またはそのおそれのある行為。
（3）本規約または法令等に違反する行為。
（4）当社の承諾なく営利を目的とした行為。
（5）他人になりすまして本サービスを利用する行為、他人の入力情報を閲覧する目的で本サービスを利用する行為。
（6）本サービスの運営を妨害する行為および本サービスのシステムに障害をきたすおそれのある行為。
（7）その他、当社が不適切と合理的に判断した行為。
２．ユーザーは、本サービスにおいて、以下の表現・内容を掲載しないものとします。
（1）殺害・虐待・自殺を肯定・勧誘あるいは助長するまたはそのおそれがあると当社が判断するもの。
（2）暴力的な表現、非合法活動への勧誘またはそのおそれのあるもの。
（3）本人の承諾のない個人情報（ただし、既に公開されているものは除きます。）。
（4）性的な画像、動画を含む（イラストや絵画等も含みます。）と当社が判断するもの。
（5）アダルトサイト、出会い系サイトに関連するもの、またはこれらのウェブサイト等へのリンク。
（6）無限連鎖講（ねずみ講）、マルチ商法、オンラインカジノ等の勧誘、およびこれらに類すると当社が判断するもの。
（7）個人・法人を問わず、他人を誹謗中傷もしくは侮辱するもの。
（8）脅迫行為、ストーカー行為を助長するまたはそのおそれがあるもの。
（9）民族的、人種、信条、性別、社会的身分または門地等による差別、その他差別につながるまたはそのおそれのあるもの。
（10）犯罪的行為もしくは犯罪的行為に結びつくおそれのあるもの。
（11）虚偽、または公の秩序もしくは善良の風俗に反するもの。
（12）法律に違反する表現・内容。
３．ユーザーが本条に違反した場合、当社は登録の解除、本サービス利用の一時停止、入力内容の全部または一部削除等の必要な措置を講じることができるものとします。

第６条（個人情報の取り扱いについて）
１．当社ではユーザーが登録する個人情報を第三者に提供することはございません。
２．本サービスに登録する個人情報は、本サービスを利用するために入力する情報であり、当社が利用することはございません。ただし、ユーザー本人及び学校法人より本サービスの調査などの依頼があった場合は、調査のために参照する可能性がございます。
３．入力した個人情報の開示は学校法人受付窓口にお問い合わせください。

第７条（本サービスの停止）
当社はユーザーに事前に通知することなく、以下の理由により本サービスの全部又は一部を停止又は中断することができるものとします。
・本サービスの提供に必要なコンピューター・システム等の点検又は保守作業を定期的又は緊急に行う場合
・コンピューター、通信回線等が事故（当社の責に帰すべき事由によるものであるか否かを問わない）によ
り停止した場合
・火災、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合
・その他、当社が停止又は中断を必要と判断した場合

第８条（本サービスの変更、終了）
当社はユーザーに事前の通知なく本サービスの運営の諸条件、 サービス内容の変更を行うことができるものとします。また営業上、技術上の理由等により本サービスを終了することができるものとします。 

第９条(連絡・通知)
本サービスに関する問い合わせ、連絡もしくは通知、その他当社からユーザーに対する連絡もしくは通知は、メール連絡や本サービスへの掲示など、当社の定める方法で行うものとします。

第１０条（免責）
１．ユーザーの誤入力や着信拒否等の設定に起因するメール不達などによりユーザーに損害・損失が発生した場合、 天災、紛争、暴動その他当社の責めに帰すことのできない不可抗力の事由により、 ユーザーに損害が発生した場合のいずれについても、当社は一切の損害賠償等の責任を負わないものとします。
２．本アプリのダウンロード及びインストールによりユーザーの利用端末に発生し得る損害等につき、一切の補償責任を負わないものとします。 
３．ユーザーが故意または過失の有無にかかわらず、本アプリをアンインストールまたは削除した場合において、後に本アプリを再インストールしても当該アンインストールまたは削除前に本アプリに記録・保存されていた情報を復元することができない場合があることにつき、ユーザーは予め同意するものとします。
４．当社は、ユーザーが本規約等に違反したことによって生じた損害や不利益については、一切の責任を負わず、一切の損害賠償の義務を負わないものとします。
５．当社は、ユーザーの利用環境等によって生じた損害等について、一切責任を負いません。
６．前各項の他、本サービスの利用に起因するユーザーと学校法人等または第三者間の一切の紛争について、当該紛争が当社の責に帰すべき事由による場合を除き、当社は何ら責任を負いません。

第１１条（本規約の変更）
当社は運営上の都合等により、本規約を随時変更できるものとします。 その場合は変更後の規約を速やかに本サービス内に掲示するものとします。 
本規約変更後、ユーザーが本サービスを継続して利用した場合、または当社の定める期間（定めがない場合は開示の日から 1 週間）内に本サービス登録解除の手続をとらなかった場合には、ユーザーは、変更後の本規約の内容の変更に同意したものとします。

第１２条（本アプリのバージョンアップ）
当社は、ユーザーの事前の承諾なく、いつでも本アプリのバージョンアップを行うことができるものとします。
前項の場合において、ユーザーがバージョンアップ後のアプリを自らの端末にインストールするまでの間、本サービスの全部または一部が利用できない場合があり、
ユーザーは自己の責任において、本アプリのバージョンアップを行うものとします。当社は、本アプリのバージョンアップを含む変更によりユーザーに生じた損害等につき、一切の責任を負わないものとします。

第１３条（権利の譲渡等の禁止）
ユーザーは、本サービスの利用にかかる権利義務を譲渡、転貸、担保差入れその他形態を問わず処分することはできないものとします。

第１４条（合意管轄裁判所）
当社とユーザーの間で、訴訟の必要が生じた場合には東京地方裁判所を第一審の専属的合意管轄裁判所とします。

第１５条（準拠法）
本規約および本サービス利用に関する一切の事項については、日本国法に準拠し、解釈されるものとします。

２０２５年３月１３日 改定
２０２３年３月１３日 施行
`,
    };
  },
  // 初期表示時データ読み込み
  async created() {
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined({funcName:"loadConsent"}).then((res) => {
        this.$root.loading = false;
        this.consentFlg = res.data.consentFlg;
      });
  },
  methods: {
    // 利用規約同意保存
    async consentSave() {
      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");

      await schoolAidHomeLogined({funcName:"saveConsent"}).then((res) => {
        this.$root.loading = false;
        // ログインフラグを立てる
        this.$root.isLogin = true;
        
        var userKbn = res.data.userKbn;
        var adminFlg = res.data.adminFlg;
        var nextPage = "";

        // ユーザー区分に応じた制御
        switch (userKbn) {
          case "0":
            nextPage = "StudentTopPage";
            this.$root.isStudentMenu = true;
            break;
          case "1":
            nextPage = "GuardianTopPage";
            this.$root.isParentMenu = true;  
            break;
          case "8":
            nextPage = "TeacherTopPage";
            this.$root.isKyoinMenu = true;
            if(adminFlg == true){
              this.$root.menuPattern = 2;
            } else {
              this.$root.menuPattern = 1;
            }
            break;
          case "9":
            nextPage = "TeacherTopPage";
            this.$root.isKyoinMenu = true;
            this.$root.menuPattern = 2;
            break;
          default:
            break;
        }
        // 利用規約同意済
        this.$router.push({
          name: nextPage
        });
      });
    },
    // プライバシーポリシー情報ページを開く
    async privacyPolicyClick() {
      var data = {
        funcName: "getPrivacyPolicyUrl",
      };
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomePublic = httpsCallable(functions, "schoolAidHomePublic");
      this.windowReference = window.open();
      await schoolAidHomePublic(data).then((res) => {
        this.$root.loading = false;
        this.windowReference.location = res.data.privacyUrl;
      });
    }
  },
};
</script>