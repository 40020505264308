<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="6" sm="2" md="1" lg="1" xl="1">
        <div align="left">
          <v-btn color="grey" small class="black--text" @click="toBack">前の日へ</v-btn>
        </div>
      </v-col>
      <v-col cols="6" sm="2" md="1" lg="1" xl="1">
        <div align="right">
          <v-btn color="grey" small class="black--text" @click="toForward">次の日へ</v-btn>
        </div>
      </v-col>
      <v-col cols="12" sm="8" md="10" lg="10" xl="10">
        <v-card class="mx-auto text-center black--text" outlined>
          <v-row dense>
            <v-col cols="8" sm="8" md="10" lg="10" xl="10">
              <div align="center">
                {{ yyyymmddDisp }}の出欠状況のまとめ
              </div>
            </v-col>
            <v-col cols="4" sm="4" md="2" lg="2" xl="2">
              <div align="right">
                <v-btn color="grey" small class="black--text" @click="calendar">日付選択</v-btn>

              </div>
            </v-col>
          </v-row>
          <Datepicker
            ref="picker"
            :monday-first="true"
            wrapper-class="calender-text-box-wrapper"
            input-class="calender-text-box"
            placeholder=""
            :language="language"
            @selected=dateSelected />
        </v-card>
      </v-col>
    </v-row>

    <!-- ラベル：最終更新日時 -->
    <v-row dense>
      <v-col cols="12" class="d-flex text-caption justify-end">
        <div>最終更新時間：{{ lastReloadTime }}(15分毎自動更新)</div>
      </v-col>
    </v-row>

    <v-row v-if="isListLoding">
      <v-col>
        <v-data-table
          :items="summary"
          :items-per-page="-1"
          hide-default-footer
          dense
          disable-sort
          :loading="isListLoding" loading-text="読込中"
        ></v-data-table>
      </v-col>
    </v-row>

    <v-row v-if="buMst.includes('00')">
      <v-col>
        <v-expansion-panels v-model="panel00" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header class="bu-header" @click="changeOpenSyukketu('00')">
              <div style="font-size:x-large">小学校
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="ml-2" v-bind="attrs" v-on="on" color="grey">mdi-help-circle-outline</v-icon>
                  </template>
                  <div>欠席・遅刻・早退には未確定の値を含みます</div>
                </v-tooltip>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="hidden-xs-only">
                <v-col cols="4">
                  <v-card class="mx-auto">
                    <v-card-title class="gakunen-header">
                      <span>{{ getGakunenName('00','1') }}年</span>
                    </v-card-title>
                    <v-card-text>
                      <v-data-table
                        :headers="headers"
                        :items="getFilteredSummary('00','1')"
                        :items-per-page="-1"
                        hide-default-footer
                        disable-sort
                        dense
                      >
                        <template #[`item`]="{ item }">
                          <tr :class="rowStyleYellow(item)" @click="onClickDetails(item)">
                            <td class="text-start body-1">
                              <span>{{ item.gakkyu }}組</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedKesseki > 0" class="font-weight-bold">{{ item.mergedKesseki }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedChikoku > 0" class="font-weight-bold">{{ item.mergedChikoku }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedSotai > 0" class="font-weight-bold">{{ item.mergedSotai }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.unconfirmed > 0" class="font-weight-bold" style="color: red;">{{ item.unconfirmed }}</span>
                              <span v-else>0</span>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card class="mx-auto">
                    <v-card-title class="gakunen-header">
                      <span>{{ getGakunenName('00','2') }}年</span>
                    </v-card-title>
                    <v-card-text>
                      <v-data-table
                        :headers="headers"
                        :items="getFilteredSummary('00','2')"
                        :items-per-page="-1"
                        hide-default-footer
                        disable-sort
                        dense
                      >
                        <template #[`item`]="{ item }">
                          <tr :class="rowStyleYellow(item)" @click="onClickDetails(item)">
                            <td class="text-start body-1">
                              <span>{{ item.gakkyu }}組</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedKesseki > 0" class="font-weight-bold">{{ item.mergedKesseki }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedChikoku > 0" class="font-weight-bold">{{ item.mergedChikoku }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedSotai > 0" class="font-weight-bold">{{ item.mergedSotai }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.unconfirmed > 0" class="font-weight-bold" style="color: red;">{{ item.unconfirmed }}</span>
                              <span v-else>0</span>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card class="mx-auto">
                    <v-card-title class="gakunen-header">
                      <span>{{ getGakunenName('00','3') }}年</span>
                    </v-card-title>
                    <v-card-text>
                      <v-data-table
                        :headers="headers"
                        :items="getFilteredSummary('00','3')"
                        :items-per-page="-1"
                        hide-default-footer
                        disable-sort
                        dense
                      >
                        <template #[`item`]="{ item }">
                          <tr :class="rowStyleYellow(item)" @click="onClickDetails(item)">
                            <td class="text-start body-1">
                              <span>{{ item.gakkyu }}組</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedKesseki > 0" class="font-weight-bold">{{ item.mergedKesseki }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedChikoku > 0" class="font-weight-bold">{{ item.mergedChikoku }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedSotai > 0" class="font-weight-bold">{{ item.mergedSotai }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.unconfirmed > 0" class="font-weight-bold" style="color: red;">{{ item.unconfirmed }}</span>
                              <span v-else>0</span>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="hidden-xs-only">
                <v-col cols="4">
                  <v-card class="mx-auto">
                    <v-card-title class="gakunen-header">
                      <span>{{ getGakunenName('00','4') }}年</span>
                    </v-card-title>
                    <v-card-text>
                      <v-data-table
                        :headers="headers"
                        :items="getFilteredSummary('00','4')"
                        :items-per-page="-1"
                        hide-default-footer
                        disable-sort
                        dense
                      >
                        <template #[`item`]="{ item }">
                          <tr :class="rowStyleYellow(item)" @click="onClickDetails(item)">
                            <td class="text-start body-1">
                              <span>{{ item.gakkyu }}組</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedKesseki > 0" class="font-weight-bold">{{ item.mergedKesseki }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedChikoku > 0" class="font-weight-bold">{{ item.mergedChikoku }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedSotai > 0" class="font-weight-bold">{{ item.mergedSotai }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.unconfirmed > 0" class="font-weight-bold" style="color: red;">{{ item.unconfirmed }}</span>
                              <span v-else>0</span>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card class="mx-auto">
                    <v-card-title class="gakunen-header">
                      <span>{{ getGakunenName('00','5') }}年</span>
                    </v-card-title>
                    <v-card-text>
                      <v-data-table
                        :headers="headers"
                        :items="getFilteredSummary('00','5')"
                        :items-per-page="-1"
                        hide-default-footer
                        disable-sort
                        dense
                      >
                        <template #[`item`]="{ item }">
                          <tr :class="rowStyleYellow(item)" @click="onClickDetails(item)">
                            <td class="text-start body-1">
                              <span>{{ item.gakkyu }}組</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedKesseki > 0" class="font-weight-bold">{{ item.mergedKesseki }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedChikoku > 0" class="font-weight-bold">{{ item.mergedChikoku }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedSotai > 0" class="font-weight-bold">{{ item.mergedSotai }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.unconfirmed > 0" class="font-weight-bold" style="color: red;">{{ item.unconfirmed }}</span>
                              <span v-else>0</span>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card class="mx-auto">
                    <v-card-title class="gakunen-header">
                      <span>{{ getGakunenName('00','6') }}年</span>
                    </v-card-title>
                    <v-card-text>
                      <v-data-table
                        :headers="headers"
                        :items="getFilteredSummary('00','6')"
                        :items-per-page="-1"
                        hide-default-footer
                        disable-sort
                        dense
                      >
                        <template #[`item`]="{ item }">
                          <tr :class="rowStyleYellow(item)" @click="onClickDetails(item)">
                            <td class="text-start body-1">
                              <span>{{ item.gakkyu }}組</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedKesseki > 0" class="font-weight-bold">{{ item.mergedKesseki }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedChikoku > 0" class="font-weight-bold">{{ item.mergedChikoku }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedSotai > 0" class="font-weight-bold">{{ item.mergedSotai }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.unconfirmed > 0" class="font-weight-bold" style="color: red;">{{ item.unconfirmed }}</span>
                              <span v-else>0</span>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <!--モバイル用（学年ごとに段を構成）-->
              <!--小学１年-->
              <v-row class="d-sm-none">
                <v-col cols="12">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="gakunen-header body-1">
                        <span>{{ getGakunenName('00','1') }}年</span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-data-table
                          :headers="headers"
                          :items="getFilteredSummary('00','1')"
                          :items-per-page="-1"
                          :item-class="rowStyle"
                          @click:row="onClickDetails"
                          hide-default-footer
                          disable-sort
                          dense
                        >
                          <template #[`item`]="{ item }">
                            <tr>
                              <td class="text-start body-1">
                                <span>{{ getGakunenName('00','1') }}年 {{ item.gakkyu }}組</span>
                              </td>
                              <td class="body-1">
                                <span v-if="item.mergedKesseki > 0" class="font-weight-bold">{{ item.mergedKesseki }}</span>
                                <span v-else>0</span>
                              </td>
                              <td class="body-1">
                                <span v-if="item.mergedChikoku > 0" class="font-weight-bold">{{ item.mergedChikoku }}</span>
                                <span v-else>0</span>
                              </td>
                              <td class="body-1">
                                <span v-if="item.mergedSotai > 0" class="font-weight-bold">{{ item.mergedSotai }}</span>
                                <span v-else>0</span>
                              </td>
                              <td class="body-1">
                                <span v-if="item.unconfirmed > 0" class="font-weight-bold" style="color: red;">{{ item.unconfirmed }}</span>
                                <span v-else>0</span>
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
              <!--小学２年-->
              <v-row class="d-sm-none">
                <v-col cols="12">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="gakunen-header body-1">
                        <span>{{ getGakunenName('00','2') }}年</span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-data-table
                          :headers="headers"
                          :items="getFilteredSummary('00','2')"
                          :items-per-page="-1"
                          :item-class="rowStyle"
                          @click:row="onClickDetails"
                          hide-default-footer
                          disable-sort
                          dense
                        >
                          <template #[`item`]="{ item }">
                            <tr>
                              <td class="text-start body-1">
                                <span>{{ getGakunenName('00','2') }}年 {{ item.gakkyu }}組</span>
                              </td>
                              <td class="body-1">
                                <span v-if="item.mergedKesseki > 0" class="font-weight-bold">{{ item.mergedKesseki }}</span>
                                <span v-else>0</span>
                              </td>
                              <td class="body-1">
                                <span v-if="item.mergedChikoku > 0" class="font-weight-bold">{{ item.mergedChikoku }}</span>
                                <span v-else>0</span>
                              </td>
                              <td class="body-1">
                                <span v-if="item.mergedSotai > 0" class="font-weight-bold">{{ item.mergedSotai }}</span>
                                <span v-else>0</span>
                              </td>
                              <td class="body-1">
                                <span v-if="item.unconfirmed > 0" class="font-weight-bold" style="color: red;">{{ item.unconfirmed }}</span>
                                <span v-else>0</span>
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
              <!--小学３年-->
              <v-row class="d-sm-none">
                <v-col cols="12">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="gakunen-header body-1">
                        <span>{{ getGakunenName('00','3') }}年</span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-data-table
                          :headers="headers"
                          :items="getFilteredSummary('00','3')"
                          :items-per-page="-1"
                          :item-class="rowStyle"
                          @click:row="onClickDetails"
                          hide-default-footer
                          disable-sort
                          dense
                        >
                          <template #[`item`]="{ item }">
                            <tr>
                              <td class="text-start body-1">
                                <span>{{ getGakunenName('00','3') }}年 {{ item.gakkyu }}組</span>
                              </td>
                              <td class="body-1">
                                <span v-if="item.mergedKesseki > 0" class="font-weight-bold">{{ item.mergedKesseki }}</span>
                                <span v-else>0</span>
                              </td>
                              <td class="body-1">
                                <span v-if="item.mergedChikoku > 0" class="font-weight-bold">{{ item.mergedChikoku }}</span>
                                <span v-else>0</span>
                              </td>
                              <td class="body-1">
                                <span v-if="item.mergedSotai > 0" class="font-weight-bold">{{ item.mergedSotai }}</span>
                                <span v-else>0</span>
                              </td>
                              <td class="body-1">
                                <span v-if="item.unconfirmed > 0" class="font-weight-bold" style="color: red;">{{ item.unconfirmed }}</span>
                                <span v-else>0</span>
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
              <!--小学４年-->
              <v-row class="d-sm-none">
                <v-col cols="12">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="gakunen-header body-1">
                        <span>{{ getGakunenName('00','4') }}年</span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-data-table
                          :headers="headers"
                          :items="getFilteredSummary('00','4')"
                          :items-per-page="-1"
                          :item-class="rowStyle"
                          @click:row="onClickDetails"
                          hide-default-footer
                          disable-sort
                          dense
                        >
                          <template #[`item`]="{ item }">
                            <tr>
                              <td class="text-start body-1">
                                <span>{{ getGakunenName('00','4') }}年 {{ item.gakkyu }}組</span>
                              </td>
                              <td class="body-1">
                                <span v-if="item.mergedKesseki > 0" class="font-weight-bold">{{ item.mergedKesseki }}</span>
                                <span v-else>0</span>
                              </td>
                              <td class="body-1">
                                <span v-if="item.mergedChikoku > 0" class="font-weight-bold">{{ item.mergedChikoku }}</span>
                                <span v-else>0</span>
                              </td>
                              <td class="body-1">
                                <span v-if="item.mergedSotai > 0" class="font-weight-bold">{{ item.mergedSotai }}</span>
                                <span v-else>0</span>
                              </td>
                              <td class="body-1">
                                <span v-if="item.unconfirmed > 0" class="font-weight-bold" style="color: red;">{{ item.unconfirmed }}</span>
                                <span v-else>0</span>
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
              <!--小学５年-->
              <v-row class="d-sm-none">
                <v-col cols="12">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="gakunen-header body-1">
                        <span>{{ getGakunenName('00','5') }}年</span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-data-table
                          :headers="headers"
                          :items="getFilteredSummary('00','5')"
                          :items-per-page="-1"
                          :item-class="rowStyle"
                          @click:row="onClickDetails"
                          hide-default-footer
                          disable-sort
                          dense
                        >
                          <template #[`item`]="{ item }">
                            <tr>
                              <td class="text-start body-1">
                                <span>{{ getGakunenName('00','5') }}年 {{ item.gakkyu }}組</span>
                              </td>
                              <td class="body-1">
                                <span v-if="item.mergedKesseki > 0" class="font-weight-bold">{{ item.mergedKesseki }}</span>
                                <span v-else>0</span>
                              </td>
                              <td class="body-1">
                                <span v-if="item.mergedChikoku > 0" class="font-weight-bold">{{ item.mergedChikoku }}</span>
                                <span v-else>0</span>
                              </td>
                              <td class="body-1">
                                <span v-if="item.mergedSotai > 0" class="font-weight-bold">{{ item.mergedSotai }}</span>
                                <span v-else>0</span>
                              </td>
                              <td class="body-1">
                                <span v-if="item.unconfirmed > 0" class="font-weight-bold" style="color: red;">{{ item.unconfirmed }}</span>
                                <span v-else>0</span>
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
              <!--小学６年-->
              <v-row class="d-sm-none">
                <v-col cols="12">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="gakunen-header body-1">
                        <span>{{ getGakunenName('00','6') }}年</span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-data-table
                          :headers="headers"
                          :items="getFilteredSummary('00','6')"
                          :items-per-page="-1"
                          :item-class="rowStyle"
                          @click:row="onClickDetails"
                          hide-default-footer
                          disable-sort
                          dense
                        >
                          <template #[`item`]="{ item }">
                            <tr>
                              <td class="text-start body-1">
                                <span>{{ getGakunenName('00','6') }}年 {{ item.gakkyu }}組</span>
                              </td>
                              <td class="body-1">
                                <span v-if="item.mergedKesseki > 0" class="font-weight-bold">{{ item.mergedKesseki }}</span>
                                <span v-else>0</span>
                              </td>
                              <td class="body-1">
                                <span v-if="item.mergedChikoku > 0" class="font-weight-bold">{{ item.mergedChikoku }}</span>
                                <span v-else>0</span>
                              </td>
                              <td class="body-1">
                                <span v-if="item.mergedSotai > 0" class="font-weight-bold">{{ item.mergedSotai }}</span>
                                <span v-else>0</span>
                              </td>
                              <td class="body-1">
                                <span v-if="item.unconfirmed > 0" class="font-weight-bold" style="color: red;">{{ item.unconfirmed }}</span>
                                <span v-else>0</span>
                              </td>
                            </tr>
                          </template>
                        </v-data-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>

            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row v-if="buMst.includes('01')">
      <v-col>
        <v-expansion-panels v-model="panel01" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header class="bu-header" @click="changeOpenSyukketu('01')">
              <div style="font-size:x-large">中学
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="ml-2" v-bind="attrs" v-on="on" color="grey">mdi-help-circle-outline</v-icon>
                  </template>
                  <div>欠席・遅刻・早退には未確定の値を含みます</div>
                </v-tooltip>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="hidden-xs-only">
                <v-col cols="4">
                  <v-card class="mx-auto">
                    <v-card-title class="gakunen-header">
                      <span>{{ getGakunenName('01','1') }}年</span>
                    </v-card-title>
                    <v-card-text>
                      <v-data-table
                        :headers="headers"
                        :items="getFilteredSummary('01','1')"
                        :items-per-page="-1"
                        hide-default-footer
                        disable-sort
                        dense
                      >
                        <template #[`item`]="{ item }">
                          <tr :class="rowStyleYellow(item)" @click="onClickDetails(item)">
                            <td class="text-start body-1">
                              <span>{{ item.gakkyu }}組</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedKesseki > 0" class="font-weight-bold">{{ item.mergedKesseki }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedChikoku > 0" class="font-weight-bold">{{ item.mergedChikoku }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedSotai > 0" class="font-weight-bold">{{ item.mergedSotai }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.unconfirmed > 0" class="font-weight-bold" style="color: red;">{{ item.unconfirmed }}</span>
                              <span v-else>0</span>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card class="mx-auto">
                    <v-card-title class="gakunen-header">
                      <span>{{ getGakunenName('01','2') }}年</span>
                    </v-card-title>
                    <v-card-text>
                      <v-data-table
                        :headers="headers"
                        :items="getFilteredSummary('01','2')"
                        :items-per-page="-1"
                        hide-default-footer
                        disable-sort
                        dense
                      >
                        <template #[`item`]="{ item }">
                          <tr :class="rowStyleYellow(item)" @click="onClickDetails(item)">
                            <td class="text-start body-1">
                              <span>{{ item.gakkyu }}組</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedKesseki > 0" class="font-weight-bold">{{ item.mergedKesseki }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedChikoku > 0" class="font-weight-bold">{{ item.mergedChikoku }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedSotai > 0" class="font-weight-bold">{{ item.mergedSotai }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.unconfirmed > 0" class="font-weight-bold" style="color: red;">{{ item.unconfirmed }}</span>
                              <span v-else>0</span>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card class="mx-auto">
                    <v-card-title class="gakunen-header">
                      <span>{{ getGakunenName('01','3') }}年</span>
                    </v-card-title>
                    <v-card-text>
                      <v-data-table
                        :headers="headers"
                        :items="getFilteredSummary('01','3')"
                        :items-per-page="-1"
                        hide-default-footer
                        disable-sort
                        dense
                      >
                        <template #[`item`]="{ item }">
                          <tr :class="rowStyleYellow(item)" @click="onClickDetails(item)">
                            <td class="text-start body-1">
                              <span>{{ item.gakkyu }}組</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedKesseki > 0" class="font-weight-bold">{{ item.mergedKesseki }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedChikoku > 0" class="font-weight-bold">{{ item.mergedChikoku }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedSotai > 0" class="font-weight-bold">{{ item.mergedSotai }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.unconfirmed > 0" class="font-weight-bold" style="color: red;">{{ item.unconfirmed }}</span>
                              <span v-else>0</span>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <!--モバイル用（学年ごとに段を構成）-->
              <!--中学１年-->
              <v-row class="d-sm-none">
                <v-col cols="12">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="gakunen-header body-1">
                        <span>{{ getGakunenName('01','1') }}年</span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-data-table
                          :headers="headers"
                          :items="getFilteredSummary('01','1')"
                          :items-per-page="-1"
                          :item-class="rowStyle"
                          @click:row="onClickDetails"
                          hide-default-footer
                          disable-sort
                          dense
                        >
                          <template #[`item.gakkyu`]="{ item }">
                            <div class="text-start body-1">
                              <span>{{ getGakunenName('01','1') }}年 {{ item.gakkyu }}組</span>
                            </div>
                          </template>
                          <template #[`item.mergedKesseki`]="{ item }">
                            <div class="body-1">
                              <span v-if="item.mergedKesseki > 0" class="font-weight-bold">{{ item.mergedKesseki }}</span>
                              <span v-else>0</span>
                            </div>
                          </template>
                          <template #[`item.mergedChikoku`]="{ item }">
                            <div class="body-1">
                              <span v-if="item.mergedChikoku > 0" class="font-weight-bold">{{ item.mergedChikoku }}</span>
                              <span v-else>0</span>
                            </div>
                          </template>
                          <template #[`item.mergedSotai`]="{ item }">
                            <div class="body-1">
                              <span v-if="item.mergedSotai > 0" class="font-weight-bold">{{ item.mergedSotai }}</span>
                              <span v-else>0</span>
                            </div>
                          </template>
                          <template #[`item.unconfirmed`]="{ item }">
                            <div class="body-1">
                              <span v-if="item.unconfirmed > 0" class="font-weight-bold" style="color: red;">{{ item.unconfirmed }}</span>
                              <span v-else>0</span>
                            </div>
                          </template>
                        </v-data-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
              <!--中学２年-->
              <v-row class="d-sm-none">
                <v-col cols="12">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="gakunen-header body-1">
                        <span>{{ getGakunenName('01','2') }}年</span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-data-table
                          :headers="headers"
                          :items="getFilteredSummary('01','2')"
                          :items-per-page="-1"
                          :item-class="rowStyle"
                          @click:row="onClickDetails"
                          hide-default-footer
                          disable-sort
                          dense
                        >
                          <template #[`item.gakkyu`]="{ item }">
                            <div class="text-start body-1">
                              <span>{{ getGakunenName('01','2') }}年 {{ item.gakkyu }}組</span>
                            </div>
                          </template>
                          <template #[`item.mergedKesseki`]="{ item }">
                            <div class="body-1">
                              <span v-if="item.mergedKesseki > 0" class="font-weight-bold">{{ item.mergedKesseki }}</span>
                              <span v-else>0</span>
                            </div>
                          </template>
                          <template #[`item.mergedChikoku`]="{ item }">
                            <div class="body-1">
                              <span v-if="item.mergedChikoku > 0" class="font-weight-bold">{{ item.mergedChikoku }}</span>
                              <span v-else>0</span>
                            </div>
                          </template>
                          <template #[`item.mergedSotai`]="{ item }">
                            <div class="body-1">
                              <span v-if="item.mergedSotai > 0" class="font-weight-bold">{{ item.mergedSotai }}</span>
                              <span v-else>0</span>
                            </div>
                          </template>
                          <template #[`item.unconfirmed`]="{ item }">
                            <div class="body-1">
                              <span v-if="item.unconfirmed > 0" class="font-weight-bold" style="color: red;">{{ item.unconfirmed }}</span>
                              <span v-else>0</span>
                            </div>
                          </template>
                        </v-data-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
              <!--中学３年-->
              <v-row class="d-sm-none">
                <v-col cols="12">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="gakunen-header body-1">
                        <span>{{ getGakunenName('01','3') }}年</span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-data-table
                          :headers="headers"
                          :items="getFilteredSummary('01','3')"
                          :items-per-page="-1"
                          :item-class="rowStyle"
                          @click:row="onClickDetails"
                          hide-default-footer
                          disable-sort
                          dense
                        >
                          <template #[`item.gakkyu`]="{ item }">
                            <div class="text-start body-1">
                              <span>{{ getGakunenName('01','3') }}年 {{ item.gakkyu }}組</span>
                            </div>
                          </template>
                          <template #[`item.mergedKesseki`]="{ item }">
                            <div class="body-1">
                              <span v-if="item.mergedKesseki > 0" class="font-weight-bold">{{ item.mergedKesseki }}</span>
                              <span v-else>0</span>
                            </div>
                          </template>
                          <template #[`item.mergedChikoku`]="{ item }">
                            <div class="body-1">
                              <span v-if="item.mergedChikoku > 0" class="font-weight-bold">{{ item.mergedChikoku }}</span>
                              <span v-else>0</span>
                            </div>
                          </template>
                          <template #[`item.mergedSotai`]="{ item }">
                            <div class="body-1">
                              <span v-if="item.mergedSotai > 0" class="font-weight-bold">{{ item.mergedSotai }}</span>
                              <span v-else>0</span>
                            </div>
                          </template>
                          <template #[`item.unconfirmed`]="{ item }">
                            <div class="body-1">
                              <span v-if="item.unconfirmed > 0" class="font-weight-bold" style="color: red;">{{ item.unconfirmed }}</span>
                              <span v-else>0</span>
                            </div>
                          </template>
                        </v-data-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
              
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-row v-if="buMst.includes('02')">
      <v-col>
        <v-expansion-panels v-model="panel02" multiple>
          <v-expansion-panel>
            <v-expansion-panel-header class="bu-header" @click="changeOpenSyukketu('02')">
              <div style="font-size:x-large">高校
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="ml-2" v-bind="attrs" v-on="on" color="grey">mdi-help-circle-outline</v-icon>
                  </template>
                  <div>欠席・遅刻・早退には未確定の値を含みます</div>
                </v-tooltip>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row class="hidden-xs-only">
                <v-col cols="4">
                  <v-card class="mx-auto">
                    <v-card-title class="gakunen-header">
                      <span>{{ getGakunenName('02','1') }}年</span>
                    </v-card-title>
                    <v-card-text>
                      <v-data-table
                        :headers="headers"
                        :items="getFilteredSummary('02','1')"
                        :items-per-page="-1"
                        hide-default-footer
                        disable-sort
                        dense
                      >
                        <template #[`item`]="{ item }">
                          <tr :class="rowStyleYellow(item)" @click="onClickDetails(item)">
                            <td class="text-start body-1">
                              <span>{{ item.gakkyu }}組</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedKesseki > 0" class="font-weight-bold">{{ item.mergedKesseki }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedChikoku > 0" class="font-weight-bold">{{ item.mergedChikoku }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedSotai > 0" class="font-weight-bold">{{ item.mergedSotai }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.unconfirmed > 0" class="font-weight-bold" style="color: red;">{{ item.unconfirmed }}</span>
                              <span v-else>0</span>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card class="mx-auto">
                    <v-card-title class="gakunen-header">
                      <span>{{ getGakunenName('02','2') }}年</span>
                    </v-card-title>
                    <v-card-text>
                      <v-data-table
                        :headers="headers"
                        :items="getFilteredSummary('02','2')"
                        :items-per-page="-1"
                        hide-default-footer
                        disable-sort
                        dense
                      >
                        <template #[`item`]="{ item }">
                          <tr :class="rowStyleYellow(item)" @click="onClickDetails(item)">
                            <td class="text-start body-1">
                              <span>{{ item.gakkyu }}組</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedKesseki > 0" class="font-weight-bold">{{ item.mergedKesseki }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedChikoku > 0" class="font-weight-bold">{{ item.mergedChikoku }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedSotai > 0" class="font-weight-bold">{{ item.mergedSotai }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.unconfirmed > 0" class="font-weight-bold" style="color: red;">{{ item.unconfirmed }}</span>
                              <span v-else>0</span>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="4">
                  <v-card class="mx-auto">
                    <v-card-title class="gakunen-header">
                      <span>{{ getGakunenName('02','3') }}年</span>
                    </v-card-title>
                    <v-card-text>
                      <v-data-table
                        :headers="headers"
                        :items="getFilteredSummary('02','3')"
                        :items-per-page="-1"
                        hide-default-footer
                        disable-sort
                        dense
                      >
                        <template #[`item`]="{ item }">
                          <tr :class="rowStyleYellow(item)" @click="onClickDetails(item)">
                            <td class="text-start body-1">
                              <span>{{ item.gakkyu }}組</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedKesseki > 0" class="font-weight-bold">{{ item.mergedKesseki }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedChikoku > 0" class="font-weight-bold">{{ item.mergedChikoku }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.mergedSotai > 0" class="font-weight-bold">{{ item.mergedSotai }}</span>
                              <span v-else>0</span>
                            </td>
                            <td class="body-1">
                              <span v-if="item.unconfirmed > 0" class="font-weight-bold" style="color: red;">{{ item.unconfirmed }}</span>
                              <span v-else>0</span>
                            </td>
                          </tr>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <!--モバイル用（学年ごとに段を構成）-->
              <!--高校１年-->
              <v-row class="d-sm-none">
                <v-col cols="12">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="gakunen-header body-1">
                        <span>{{ getGakunenName('02','1') }}年</span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-data-table
                          :headers="headers"
                          :items="getFilteredSummary('02','1')"
                          :items-per-page="-1"
                          :item-class="rowStyle"
                          @click:row="onClickDetails"
                          hide-default-footer
                          disable-sort
                          dense
                        >
                          <template #[`item.gakkyu`]="{ item }">
                            <div class="text-start body-1">
                              <span>{{ getGakunenName('02','1') }}年 {{ item.gakkyu }}組</span>
                            </div>
                          </template>
                          <template #[`item.mergedKesseki`]="{ item }">
                            <div class="body-1">
                              <span v-if="item.mergedKesseki > 0" class="font-weight-bold">{{ item.mergedKesseki }}</span>
                              <span v-else>0</span>
                            </div>
                          </template>
                          <template #[`item.mergedChikoku`]="{ item }">
                            <div class="body-1">
                              <span v-if="item.mergedChikoku > 0" class="font-weight-bold">{{ item.mergedChikoku }}</span>
                              <span v-else>0</span>
                            </div>
                          </template>
                          <template #[`item.mergedSotai`]="{ item }">
                            <div class="body-1">
                              <span v-if="item.mergedSotai > 0" class="font-weight-bold">{{ item.mergedSotai }}</span>
                              <span v-else>0</span>
                            </div>
                          </template>
                          <template #[`item.unconfirmed`]="{ item }">
                            <div class="body-1">
                              <span v-if="item.unconfirmed > 0" class="font-weight-bold" style="color: red;">{{ item.unconfirmed }}</span>
                              <span v-else>0</span>
                            </div>
                          </template>
                        </v-data-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
              <!--高校２年-->
              <v-row class="d-sm-none">
                <v-col cols="12">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="gakunen-header body-1">
                        <span>{{ getGakunenName('02','2') }}年</span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-data-table
                          :headers="headers"
                          :items="getFilteredSummary('02','2')"
                          :items-per-page="-1"
                          :item-class="rowStyle"
                          @click:row="onClickDetails"
                          hide-default-footer
                          disable-sort
                          dense
                        >
                          <template #[`item.gakkyu`]="{ item }">
                            <div class="text-start body-1">
                              <span>{{ getGakunenName('02','2') }}年 {{ item.gakkyu }}組</span>
                            </div>
                          </template>
                          <template #[`item.mergedKesseki`]="{ item }">
                            <div class="body-1">
                              <span v-if="item.mergedKesseki > 0" class="font-weight-bold">{{ item.mergedKesseki }}</span>
                              <span v-else>0</span>
                            </div>
                          </template>
                          <template #[`item.mergedChikoku`]="{ item }">
                            <div class="body-1">
                              <span v-if="item.mergedChikoku > 0" class="font-weight-bold">{{ item.mergedChikoku }}</span>
                              <span v-else>0</span>
                            </div>
                          </template>
                          <template #[`item.mergedSotai`]="{ item }">
                            <div class="body-1">
                              <span v-if="item.mergedSotai > 0" class="font-weight-bold">{{ item.mergedSotai }}</span>
                              <span v-else>0</span>
                            </div>
                          </template>
                          <template #[`item.unconfirmed`]="{ item }">
                            <div class="body-1">
                              <span v-if="item.unconfirmed > 0" class="font-weight-bold" style="color: red;">{{ item.unconfirmed }}</span>
                              <span v-else>0</span>
                            </div>
                          </template>
                        </v-data-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
              <!--高校３年-->
              <v-row class="d-sm-none">
                <v-col cols="12">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="gakunen-header body-1">
                        <span>{{ getGakunenName('02','3') }}年</span>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-data-table
                          :headers="headers"
                          :items="getFilteredSummary('02','3')"
                          :items-per-page="-1"
                          :item-class="rowStyle"
                          @click:row="onClickDetails"
                          hide-default-footer
                          disable-sort
                          dense
                        >
                          <template #[`item.gakkyu`]="{ item }">
                            <div class="text-start body-1">
                              <span>{{ getGakunenName('02','3') }}年 {{ item.gakkyu }}組</span>
                            </div>
                          </template>
                          <template #[`item.mergedKesseki`]="{ item }">
                            <div class="body-1">
                              <span v-if="item.mergedKesseki > 0" class="font-weight-bold">{{ item.mergedKesseki }}</span>
                              <span v-else>0</span>
                            </div>
                          </template>
                          <template #[`item.mergedChikoku`]="{ item }">
                            <div class="body-1">
                              <span v-if="item.mergedChikoku > 0" class="font-weight-bold">{{ item.mergedChikoku }}</span>
                              <span v-else>0</span>
                            </div>
                          </template>
                          <template #[`item.mergedSotai`]="{ item }">
                            <div class="body-1">
                              <span v-if="item.mergedSotai > 0" class="font-weight-bold">{{ item.mergedSotai }}</span>
                              <span v-else>0</span>
                            </div>
                          </template>
                          <template #[`item.unconfirmed`]="{ item }">
                            <div class="body-1">
                              <span v-if="item.unconfirmed > 0" class="font-weight-bold" style="color: red;">{{ item.unconfirmed }}</span>
                              <span v-else>0</span>
                            </div>
                          </template>
                        </v-data-table>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>

            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fixed fab bottom color="secondary" @click="load" :disabled="!isSearch" style="bottom: 50px">
          <v-icon color="white">mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>再読込</span>
    </v-tooltip>

    <!-- 詳細モーダル -->
    <v-dialog
      v-model="isOpenDialog"
      max-width="1000"
      persistent
    >
      <v-card
        class="d-flex justify-center flex-column mx-auto flat"
        width="1000"
        color="#fff"
      >
        <v-card-title class="d-flex justify-center" white-space="pre">
          <p>詳細[{{ dialogGroupName }}：{{ dialogDisplayDate }}]</p>
        </v-card-title>
        <v-card-actions>
          <v-btn color="grey" class="black--text" @click="isOpenDialog = false">
            閉じる
          </v-btn>
        </v-card-actions>
        <v-card-text class="d-flex justify-center flex-column">
          <v-data-table
            :headers="searchHeaders"
            :items="searchResult"
            :items-per-page="-1"
            dense
            disable-sort
            hide-default-footer
            class="elevation-1"
            :loading="isDialogLoding" loading-text="読込中"
          >
            <template #[`header.GCS_Name`]="{}"> 
              <v-row no-gutters>
                <v-col cols="12" class="justify-start">
                  学年/学級/出席番号
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" class="justify-start">
                  氏名
                </v-col>
              </v-row>
            </template>
            <template #[`header.data`]="{}"> 
              <v-row no-gutters>
                <v-col cols="12" class="justify-start">
                  出欠
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" class="justify-start">
                  確定
                </v-col>
              </v-row>
            </template>
            <template #[`item.GCS_Name`]="{ item }">
              <v-row no-gutters>
                <v-col cols="12" class="justify-start">
                  {{ item.gakunen }}/{{ item.gakkyu }}/{{ item.syussekiNo }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" class="justify-start">
                  {{ item.userNm }}
                </v-col>
              </v-row>
            </template>
            <template #[`item.data`]="{ item }">
              <v-row no-gutters style="background-color: #F5F5F5">
                <v-col cols="12" class="justify-start">
                  {{ item.unconfirmedSyukketuKbn }}
                  {{ item.unconfirmedSotaiKbn }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" class="justify-start">
                  <span class="font-weight-bold red--text" v-if="item.unconfirmedFlg == true">未確定</span>
                  {{ item.confirmedSyukketuKbn }}
                  {{ item.confirmedSotaiKbn }}
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn color="grey" class="black--text" @click="isOpenDialog = false">
            閉じる
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import { format, parse } from 'date-fns';
import { ja } from 'date-fns/locale';
export default {
  beforeRouteEnter(to, from, next){next(vm => {vm.setup()})},
  watch: {
    yyyymmdd: async function() {
      await this.load();
    },
    openSyukketuBu00: function(newVal) {this.$store.dispatch('setOpenSyukketuBu00', newVal)},
    openSyukketuBu01: function(newVal) {this.$store.dispatch('setOpenSyukketuBu01', newVal)},
    openSyukketuBu02: function(newVal) {this.$store.dispatch('setOpenSyukketuBu02', newVal)},
  },
  data() {
    return {
      buMst: [],
      isSearch: false,
      yyyymmdd: "",
      yyyymmddDisp: "",
      headers: [
        { text: "学級", value: "gakkyu", class:"font-weight-bold body-1", width: "25%" },
        { text: "欠席", value: "mergedKesseki", class:"font-weight-bold pa-0 body-1", width: "10%" },
        { text: "遅刻", value: "mergedChikoku", class:"font-weight-bold pa-0 body-1", width: "10%" },
        { text: "早退", value: "mergedSotai", class:"font-weight-bold pa-0 body-1", width: "10%" },
        { text: "未確定", value: "unconfirmed", class:"font-weight-bold pa-0 body-1", width: "10%" },
      ],
      summary: [],
      isListLoding: false,
      panel00: [],
      panel01: [],
      panel02: [],
      gakunenList: [],
      openSyukketuBu00: true,
      openSyukketuBu01: true,
      openSyukketuBu02: true,

      dialogGroupName: "",
      dialogDisplayDate: "",
      isOpenDialog: false,
      isDialogLoding: false,
      searchHeaders: [
        { text: "学年/学級/出席番号/氏名", value: "GCS_Name", width: "30%" },  
        { text: "出欠区分", value: "data", width: "70%" },
      ],
      searchResult:[],

      // 一覧の定期更新用
      intervalid1: "",
      lastReloadTime: "",
    };
  },
  // 初期表示時データ読み込み
  async created() {
    this.yyyymmdd = format(new Date(), 'yyyyMMdd', { locale: ja });
    this.yyyymmddDisp = format(new Date(), 'yyyy年M月d日(E)', { locale: ja });
    this.openSyukketuBu00 = this.$store.state.openSyukketuBu00;
    this.openSyukketuBu01 = this.$store.state.openSyukketuBu01;
    this.openSyukketuBu02 = this.$store.state.openSyukketuBu02;
    await this.load();

    // 定期更新の設定
    this.intervalid1 = setInterval(() => {
      this.load();
    }, 900000);
  },
  methods: {
    // 初期処理
    async setup() {
      // KBKGCの取得
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined({funcName:"commonGetKBKG"}).then((ret) => {
        this.buMst = ret.data.buMst.map(item => item.code);
      });
    },
    async load() {
      const data = {
        funcName: "loadAttendanceDateSummary",
        yyyymmdd: this.yyyymmdd,
        yyyymmddDisp: this.yyyymmddDisp,
      };
      this.summary = [];

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isListLoding = true;
      await schoolAidHomeLogined(data).then((ret) => {
        var buff = [];
        ret.data.syukketu.forEach((recode) => {
          buff.push(recode);
        });
        this.summary = buff;
        this.gakunenList = ret.data.gakunenList;
      });
      this.isSearch = true;
      this.lastReloadTime = format(new Date(), "HH時mm分ss秒", { locale: ja });
      this.isListLoding = false;

      // 部ごとの折り畳み制御
      this.panel00.length = 0;
      this.panel01.length = 0;
      this.panel02.length = 0;
      if (this.openSyukketuBu00) this.panel00.push(0);
      if (this.openSyukketuBu01) this.panel01.push(0);
      if (this.openSyukketuBu02) this.panel02.push(0);
    },
    beforeDestroy () {
    // 定期更新の設定破棄
      clearInterval(this.intervalid1)
    },
    changeOpenSyukketu (buCd) {
      if (buCd == '00') this.openSyukketuBu00 = !this.openSyukketuBu00;
      if (buCd == '01') this.openSyukketuBu01 = !this.openSyukketuBu01;
      if (buCd == '02') this.openSyukketuBu02 = !this.openSyukketuBu02;
    },
    // ダイアログ表示処理
    async onClickDetails(item){
      this.dialogGroupName = item.groupName;
      this.dialogDisplayDate = item.displayDate;
      this.searchResult = [];

      let kateiCd = item.groupKey.substr(0, 2);
      let buCd = item.groupKey.substr(3, 2);
      let kaCd = item.groupKey.substr(6, 2);
      let gakunen = item.groupKey.substr(9, 1);
      let gakkyu = item.gakkyuCd;
      let groupKey = [kateiCd, "-", buCd, "-", kaCd, "-", gakunen, "-", gakkyu].join('');
      var date = parse(this.yyyymmdd, 'yyyyMMdd', new Date());
      var yyyymmdd = format(date, 'yyyy-MM-dd', { locale: ja });
      const data = {
        funcName: "loadAttendanceSummaryDetail",
        katei: kateiCd,
        bu: buCd,
        ka: kaCd,
        groupKey: groupKey,
        yyyymmdd: yyyymmdd,
      };
      this.isOpenDialog = true;

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isDialogLoding = true;
      await schoolAidHomeLogined(data).then((ret) => {
        var buff = [];
        ret.data.forEach((recode) => {
          buff.push(recode);
        });
        this.searchResult = buff;
        this.isDialogLoding = false;
      });
    },
    // フィルターをかけるメソッド
    getFilteredSummary(buCd, gakunen) {
      return this.summary.filter(item => item.gakunenCd == gakunen && item.buCd == buCd);
    },
    // 学年名称の取得
    getGakunenName(buCd, gakunen) {
      var buGakunen = [buCd, "-", gakunen].join('');
      var gakunenNm = this.gakunenList.filter(item => item.key == buGakunen);
      return gakunenNm.length > 0 ? gakunenNm[0].gakunenName : "";
    },
    // データピッカー開閉
    calendar() {
        if(!this.$refs.picker.isOpen) {
            this.$refs.picker.$el.querySelector("input").focus();
            this.$refs.picker.showCalendar();
        } else {
            this.$refs.picker.close();
        }
    },
    // データピッカー日付選択
    dateSelected(selectedDate) {
        this.yyyymmdd = format(selectedDate, 'yyyyMMdd', { locale: ja });
        this.yyyymmddDisp = format(selectedDate, 'yyyy年M月d日(E)', { locale: ja });
    },
    // 前の日へボタン
    toBack() {
      var date = parse(this.yyyymmdd, 'yyyyMMdd', new Date());
      date.setDate(date.getDate() - 1);
      this.yyyymmdd = format(date, 'yyyyMMdd', { locale: ja });
      this.yyyymmddDisp = format(date, 'yyyy年M月d日(E)', { locale: ja });
    },
    // 次の日へボタン
    toForward() {
      var date = parse(this.yyyymmdd, 'yyyyMMdd', new Date());
      date.setDate(date.getDate() + 1);
      this.yyyymmdd = format(date, 'yyyyMMdd', { locale: ja });
      this.yyyymmddDisp = format(date, 'yyyy年M月d日(E)', { locale: ja });
    },
    rowStyle(item) {
      return item.syukketuData > 0 ? 'warning' : ''
    },
    rowStyleYellow(item) {
      return item.syukketuData > 0 ? 'row-yellow' : ''
    },
  },
};
</script>

<style scoped>
.calender-text-box {
    display: none;
}
.vdp-datepicker__calendar {
    width: 100% !important;
}
.bu-header{
  padding: 0px 18px;
}
.gakunen-header {
  background-color: #E0E0E0;
  padding: 6px 20px;
}
.row-yellow {
  background-color: yellow;
}
</style>