<template>
  <v-container fluid>
    <!-- 進行中の課題 -->
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center" color="blue-grey" dark>
          現在出ている課題
        </v-card>
      </v-col>
    </v-row>
    <v-row dense class="pt-2 pb-2 blue-grey lighten-5 d-sm-none">
      <v-col cols="6" class="d-flex justify-start px-2">
        <v-btn color="accent" class="black--text" small @click="toPoActivityRecordRegist()"><v-icon left dark>mdi-pen-plus</v-icon>活動記録作成</v-btn>
      </v-col>  
      <v-col cols="6" class="d-flex justify-end px-2">
        <v-btn color="green" @click="filterDialog3 = true" small>
          <v-icon right dark v-if="isFilterFlg3">mdi-filter</v-icon>
          <v-icon right dark v-if="!isFilterFlg3">mdi-filter-outline</v-icon>
          フィルタ
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense class="mt-1 pt-2 pb-2 blue-grey lighten-5 hidden-xs-only">
      <v-col cols="12" sm="6" md="3" lg="3" xl="3">
        <!-- 検索用のテキスト入力 -->
        <v-text-field
          v-model="titleFilterValue3"
          label="タイトル検索"
          placeholder="タイトル検索"
          outlined
          dense
          clearable
          hide-details="auto"
          background-color="white"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3" lg="3" xl="3">
        <!-- 検索用のテキスト入力 -->
        <v-autocomplete
          v-model="groupFilterValue3"
          label="グループ指定"
          placeholder="グループ指定"
          :items="groupNames3"
          outlined
          dense
          clearable
          hide-details="auto"
          background-color="white"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="4" md="2" lg="2" xl="2">
        <v-switch 
          v-model="viewUnreadOnly3"
          @click="changeFilter3(true)"
          dense
          class="mt-1"
          label="未読のみ"
          hide-details="auto"
        />
      </v-col>
      <v-col cols="12" sm="4" md="2" lg="2" xl="2">
        <v-switch 
          v-model="viewFavoriteOnly3"
          @click="changeFilter3(false)"
          dense
          class="mt-1"
          label="お気に入りのみ"
          hide-details="auto"
        />
      </v-col>
      <v-col cols="12" sm="4" md="2" lg="2" xl="2">
        <v-btn color="accent" class="mx-auto black--text" @click="toPoActivityRecordRegist()" width="90%"><v-icon left dark>mdi-pen-plus</v-icon>活動記録作成</v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-data-table
          :headers="homeworkHeaders"
          :items="homeworkList"
          :items-per-page.sync="parPageHomework"
          :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
          dense
          disable-sort
          @click:row="onClickPoHomeworkDetails"
          :item-class="rowStyleHomework"
          :loading="isListLoding" loading-text="読込中"
          mobile-breakpoint="0"
          ref="homework"
        >

          <!-- 課題名と期限とモバイル用まとめ -->
          <template #[`item.title`]="{ item }">
            <v-row no-gutters class="hidden-xs-only">
              <v-col cols="12" justify="center">
                <span>{{ item.title }}</span><span v-if="item.editNameFlg">：{{ item.titleAR }}</span>
              </v-col>
            </v-row>
            <v-row no-gutters class="hidden-xs-only">
              <v-col cols="12" justify="center" class="red--text" v-if="item.limitOverFlg">
                {{ item.limitDate }}
              </v-col>
              <v-col cols="12" justify="center" v-if="!item.limitOverFlg">
                {{ item.limitDate }}
              </v-col>
            </v-row>

            <!-- モバイル用まとめ -->
            <v-row dense class="d-sm-none">
              <v-col cols="12" justify="center">
                <!-- １行目(グループ名＋投稿者) -->
                <v-row no-gutters>
                  <v-col cols="8" class="text-caption">
                    {{ item.groupName }}
                  </v-col>
                  <v-col cols="4" class="d-flex justify-end text-caption">
                    {{ item.postUser }}
                  </v-col>
                </v-row>
                <!-- ２行目(アンケートタイトル) -->
                <v-row no-gutters>
                  <v-col cols="12" class="text-subtitle-1">
                    <span>{{ item.title }}</span><span v-if="item.editNameFlg">：{{ item.titleAR }}</span>
                  </v-col>
                </v-row>
                <!-- ３行目(公開開始日＋既読状況＋入力状況) -->
                <v-row no-gutters>
                  <v-col cols="8" class="text-caption">
                    公開:{{ item.openDate }}
                  </v-col>
                  <v-col cols="4" class="d-flex justify-end text-caption">
                    {{ item.submissionState }}
                  </v-col>
                </v-row>
                <!-- ４行目(回答期限＋お気に入り) -->
                <v-row no-gutters>
                  <v-col cols="8" class="text-caption">
                    期限:<span v-if="item.limitOverFlg" class="red--text">{{ item.limitDate }}</span>
                    <span v-if="!item.limitOverFlg">{{ item.limitDate }}</span>
                  </v-col>
                  <v-col cols="4" class="d-flex justify-end text-caption">
                    <v-icon v-if="item.favoriteFlg" color="orange">mdi-star</v-icon>
                    <v-icon v-if="!item.favoriteFlg">mdi-star-outline</v-icon>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </template>

          <!-- 登録者とグループ名 -->
          <template #[`item.groupName`]="{ item }">
            <v-row no-gutters><v-col cols="12" justify="center">{{ item.postUser }}</v-col></v-row>
            <v-row no-gutters><v-col cols="12" justify="center">{{ item.groupName }}</v-col></v-row>
          </template>
          <!-- お気に入り -->
          <template #[`item.favoriteFlg`]="{ item }">
            <v-btn icon v-if="item.favoriteFlg" :disable="true"><v-icon color="orange">mdi-star</v-icon></v-btn>
            <v-btn icon v-if="!item.favoriteFlg" :disable="true"><v-icon>mdi-star-outline</v-icon></v-btn>
          </template>
          <!-- 提出状況 -->
          <template #[`item.unreadFlg`]="{ item }">
            {{ item.submissionState }}
          </template>
          <template #[`item.comment`]="{ item }">
            <v-btn icon v-if="item.checkFlg">
              <v-icon color="red">mdi-exclamation-thick</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center" color="blue-grey" dark>
          学習のまとめ
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="0" sm="7" md="9" lg="9" xl="9" justify="center">
      </v-col>
      <v-col cols="12" sm="5" md="3" lg="3" xl="3">
        <v-btn color="green" class="mx-auto black--text" :disabled="isExportingFlg1" :loading="isExportingFlg1" @click="exportExcelStoryListForMyself()" width="90%">まとめ一覧出力<v-icon right dark>mdi-file-excel</v-icon></v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-data-table
          :headers="headersStory"
          :items="storyList"
          :loading="isListLoding" loading-text="読込中"
          dense
          @click:row="onClickPoStudyStoryRegist"
          disable-sort
          hide-default-footer
        >
          <template #[`item.status`]="{ item }">
            <span v-if="item.submissionFlg">{{ item.status }}</span>
            <span v-else class="red--text">{{ item.status }}</span>
          </template>
          <template #[`item.commentFlg`]="{ item }">
            <v-icon v-if="item.commentFlg" large color="red">mdi-new-box</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center" color="blue-grey" dark>
          活動記録(課題期間が終了し、提出済みのものを表示します)
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="0" sm="7" md="9" lg="9" xl="9" justify="center">
      </v-col>
      <v-col cols="12" sm="5" md="3" lg="3" xl="3">
        <v-btn color="green" class="mx-auto black--text" :disabled="isExportingFlg2" :loading="isExportingFlg2" @click="exportExcelActivityRecordForMyself()" width="90%">活動記録出力(提出済分)<v-icon right dark>mdi-file-excel</v-icon></v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-data-table
          :headers="headersAR"
          :items="activityRecordList"
          :items-per-page="-1"
          :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
          :loading="isListLoding" loading-text="読込中"
          dense
          disable-sort
        >
          <!-- 課題開始日時/課題グループ -->
          <template #[`item.openDate`]="{ item }">
            <v-row dense><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.openDate }}</v-col></v-row>
            <v-row dense><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.groupName }}</v-col></v-row>
            <v-row dense><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.createUserNm }}</v-col></v-row>
          </template>
          <!-- 課題タイトル/課題カテゴリ -->
          <template #[`item.disp1`]="{ item }">
            <v-row dense><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.titleHW }}</v-col></v-row>
            <v-row dense><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.categoryNmHW }}</v-col></v-row>
          </template>
          <!-- 課題詳細ボタン -->
          <template #[`item.action1`]="{ item }">
            <v-btn 
              color="accent"
              class="black--text"
              @click="openDetailHWDialog(item)"
              v-if="item.repFlg == false"
              >
              課題詳細
            </v-btn>
          </template>
          <!-- 活動記録名/活動記録カテゴリ -->
          <template #[`item.disp2`]="{ item }">
            <v-row dense v-if="item.editNameFlg"><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.title }}</v-col></v-row>
            <v-row dense v-if="item.useCategoryFlg"><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.categoryNm }}</v-col></v-row>
          </template>
          <!-- 提出日時 -->
          <template #[`item.submissionDateYYYYMMDD`]="{ item }">
            <v-row dense><v-col cols="12" justify="center" style="white-space : nowrap;">{{ item.submissionDate }}</v-col></v-row>
          </template>
          <!-- 活動記録詳細ボタン -->
          <template #[`item.action2`]="{ item }">
            <v-btn 
              color="accent"
              class="black--text"
              @click="openDetailDialog(item)"
              >
              活動記録詳細
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fixed fab bottom color="secondary" @click="reload" style="bottom: 50px">
          <v-icon color="white">mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>再読込</span>
    </v-tooltip>

    <!-- 課題フィルター -->
    <v-dialog
      v-model="filterDialog3"
      persistent
      max-width="600"
    >
      <v-card
        class="d-flex justify-center flex-column mx-auto flat"
        width="600"
        color="#fff"
      >
        <v-card-title class="d-flex pt-3 justify-center" white-space="pre">
          <v-row dense>
            <v-col dense cols="4" justify="center">
              <v-btn color="grey" class="black--text" @click="clearFilter3" width="90%">
                クリア
              </v-btn>
            </v-col>
            <v-col dense cols="4" justify="center">
              <p>フィルタ</p>
            </v-col>
            <v-col dense cols="4" justify="center">
              <v-btn color="accent" class="black--text" @click="doFilter3" width="90%">
                適用
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="d-flex justify-center flex-column">
          <v-row dense>
            <v-col cols="12">
              <!-- 検索用のテキスト入力 -->
              <v-text-field
                v-model="titleFilterValue3"
                label="タイトル検索"
                placeholder="タイトル検索"
                outlined
                dense
                clearable
                hide-details="auto"
                background-color="white"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <!-- 検索用のテキスト入力 -->
              <v-autocomplete
                v-model="groupFilterValue3"
                label="グループ指定"
                placeholder="グループ指定"
                :items="groupNames3"
                outlined
                dense
                clearable
                hide-details="auto"
                background-color="white"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-switch 
                v-model="viewUnreadOnly3"
                @click="changeFilter3(true)"
                dense
                class="mt-1"
                label="未読のみ"
                hide-details="auto"
              />
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-switch 
                v-model="viewFavoriteOnly3"
                @click="changeFilter3(false)"
                dense
                class="mt-1"
                label="お気に入りのみ"
                hide-details="auto"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- 課題詳細モーダル -->
    <v-dialog
      v-model="detailHWDialog"
      scrollable
      fullscreen
      persistent
    >
      <v-card>
        <v-card-text class="text-pre-wrap">
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-toolbar
                dark
                color="primary"
              >
                <v-toolbar-title>課題詳細</v-toolbar-title>
              </v-toolbar>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    タイトル
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  {{ dialogTitleHW }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    提出期限
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  {{ dialogLimitDateHW }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    本文・設問
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogBodyHW)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="!dialogUseCategoryFlg" dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    カテゴリ
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  {{ dialogCategoryNmHW }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" class="d-flex ml-2 pb-0 pt-0">
              <v-list dense>
                <v-subheader>添付ファイル</v-subheader>
                <v-list-item-group  v-if="dialogFileListHW.length" color="primary">
                  <v-list-item v-for="item in dialogFileListHW" :key="item.attachmentId">
                    <a @click="onClickDwonload(item)" class="text-decoration-none">{{ item.fileName }}</a>
                  </v-list-item>
                </v-list-item-group>
                <v-list-item-group  v-if="!dialogFileListHW.length" color="primary">
                  <v-list-item>ファイルはありません</v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-btn fixed fab bottom left color="grey" @click="detailHWDialog = false">
          <v-icon color="white">mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
    <!-- 活動記録詳細モーダル -->
    <v-dialog
      v-model="detailDialog"
      scrollable
      fullscreen
      persistent
    >
      <v-card>
        <v-card-text class="text-pre-wrap">
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-toolbar dark color="primary">
                <v-toolbar-title>活動記録</v-toolbar-title>
              </v-toolbar>
            </v-col>
          </v-row>
          <v-row v-if="editNameFlg" dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    タイトル
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  {{ dialogTitle }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    本文・設問
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  <p style="white-space:pre-wrap;" v-html="autoLink(dialogBody)"></p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="dialogUseCategoryFlg" dense>
            <v-col cols="12" justify="center">
              <v-card outlined>
                <v-card-text class="py-1">
                  <div class="my-0 text-caption-1">
                    カテゴリ
                  </div>
                </v-card-text>
                <v-divider class="mx-4"></v-divider>
                <v-card-text>
                  {{ dialogCategoryNm }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" class="d-flex ml-2 pb-0 pt-0">
              <v-list dense>
                <v-subheader>添付ファイル</v-subheader>
                <v-list-item-group  v-if="dialogFileList.length" color="primary">
                  <v-list-item v-for="item in dialogFileList" :key="item.attachmentId">
                    <a @click="onClickDwonload(item)" class="text-decoration-none">{{ item.fileName }}</a>
                  </v-list-item>
                </v-list-item-group>
                <v-list-item-group  v-if="!dialogFileList.length" color="primary">
                  <v-list-item>ファイルはありません</v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
          <!-- レビューコメント -->
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-toolbar dark color="primary">
                <v-toolbar-title>コメント</v-toolbar-title>
              </v-toolbar>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" justify="center">
              <v-card v-if="!dialogReviewList.length">
                <v-card-text class="d-flex justify-center flex-column">
                  <label color="primary">コメントはありません</label>
                </v-card-text>
              </v-card>
              <v-card v-if="dialogReviewList.length">
                <div v-for="item in dialogReviewList" :key="item.commentId">
                  <v-divider></v-divider>
                  <v-row dense class="lime lighten-4">
                    <v-col cols="12">
                      <div class="text-caption">
                        <div>{{ item.userNm }}が</div>
                        <span>{{ item.postDate }}に更新</span>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row no-gutters><v-divider></v-divider></v-row>
                  <v-row dense>
                    <v-card-text class="d-flex justify-center flex-column pt-0">
                      <v-row dense>
                        <v-col cols="12">
                          <p style="white-space:pre-wrap;" v-html="autoLink(item.comment)"></p>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-row>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <v-row style="height: 50px;">
            <v-col cols="12" justify="center">
            </v-col>
          </v-row>
        </v-card-text>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fixed fab left v-bind="attrs" v-on="on" color="grey" @click="closeDetailDialog" style="bottom: -10px">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </template>
          <span>閉じる</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn fixed fab right v-bind="attrs" v-on="on" color="grey" @click="scrollToBottomDialog" style="bottom: -10px">
              <v-icon color="white">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <span>ページの最後を表示する</span>
        </v-tooltip>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import Enumerable from "linq";
import { saveAsCommon } from '../../../common';
import Autolinker from 'autolinker';
export default {
  // 初期処理宣言
  beforeRouteEnter(to, from, next){next(vm => {vm.setup()})},
  data() {
    return {
      homeworkHeaders : [
        { text: "タイトル/期限", value: "title", class: "hidden-xs-only", filter: this.titleFilter3 },
        { text: "登録者/グループ名", value: "groupName", class: "hidden-xs-only", cellClass: "hidden-xs-only", filter: this.groupNameFilter3 },
        { text: "お気に入り", value: "favoriteFlg", class: "hidden-xs-only", cellClass: "hidden-xs-only", filter: this.favoriteFilter3 },
        { text: "提出状況" ,value:"unreadFlg", class: "hidden-xs-only", cellClass: "hidden-xs-only", filter: this.unreadFilter3},
        { text: "コメント", value: "comment", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
      ],
      homeworkList : [],
      parPageHomework: 30,
      unreadHomeworksCount: 0,
      // 課題フィルター
      filterDialog3: false,
      isFilterFlg3: false,
      titleFilterValue3: "",
      groupFilterValue3: "",
      viewUnreadOnly3: false,
      viewFavoriteOnly3: false,
      groupNames3 : [],

      headersStory: [
        { text: "年次", value: "gakunen"},
        { text: "登録状況", value: "status"},
        { text: "最終更新日", value: "lastUpdDate"},
        { text: "未読コメント", value: "comment"},
      ],
      storyList: [],
      headersAR: [
        { text: "課題公開日時/対象ｸﾞﾙｰﾌﾟ名/作成者", value: "openDate"},
        { text: "課題名/課題カテゴリ", value: "disp1"},
        { text: "", value: "action1", width: "80" },
        { text: "活動記録名/カテゴリ", value: "disp2"},
        { text: "提出日時", value: "submissionDateYYYYMMDD", width: "200" },
        { text: "", value: "action2", width: "80" },
      ],
      activityRecordList: [],
      isListLoding: false,
      isHomeworkLoding: false,

      isExportingFlg1: false,
      isExportingFlg2: false,
      // 課題詳細ダイアログ表示用
      detailHWDialog: false,
      dialogHomeworkId: "",
      dialogTitleHW: "",
      dialogLimitDateHW: "",
      dialogBodyHW: "",
      dialogCategoryNmHW: "",
      dialogUseCategoryFlg: false,
      dialogFileListHW: [],
      // 活動記録詳細ダイアログ表示用
      detailDialog: false,
      dialogReportId: "",
      dialogTitle: "",
      dialogBody: "",
      dialogCategoryNm: "",
      dialogFileList: [],
      dialogReviewList: [],
      dialogComment: "",
      isReviewListLoding: false,

    };
  },
  methods: {
    async setup() {
      await this.reload();
    },
    // 活動記録登録画面へ遷移
    async toPoActivityRecordRegist() {
      // 所属学級の代表課題のIDを取得
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      var homeworkId = "";
      var param = {
        funcName: "getClassRepHomeworkId",
      }
      await schoolAidHomeLogined(param).then((ret) => {        
        homeworkId = ret.data;
      });
      
      // 画面遷移
      this.$router.push({
        name: "PoActivityRecordRegist",
        params: {
          homeworkId: homeworkId,
        }
      });
    },
    // 課題詳細
    onClickPoHomeworkDetails(item) {
      // フィルタされた配列の取得
      let items = this.$refs.homework.$children[0].filteredItems;
      // 連絡IDのみ抽出
      var homeworkListBuff = Enumerable.from(items).select(x => { return x.reportId }).toArray();
      var homeworkListIndex = homeworkListBuff.indexOf(item.reportId);

      this.$router.push({
        name: "PoHomeworkDetails",
        params: {
          reportId: item.reportId,
          homeworkListBuff: homeworkListBuff,
          homeworkListIndex: homeworkListIndex,
        }
      });
    },
    rowStyleHomework: function (item) {
      return item.submissionFlg == true ? 'do-style' : 'not-yet-style'
    },
    // リロードボタン
    async reload() {
      this.homeworkList = [];
      this.activityRecordList = [];
      var unreadHomeworksCount = 0;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isListLoding = true;
      // 課題の取得
      await schoolAidHomeLogined({funcName:"loadTopPageHomework"}).then((snapshot) => {
        var ret = [];
        var temp_groupNames = new Set();
        snapshot.data.forEach((report) => {
          temp_groupNames.add(report.groupName);
          var row = {
            homeworkId: report.homeworkId,
            title: report.title,
            titleAR: report.titleAR,
            limitDate: report.limitDate,
            limitOverFlg: report.limitOverFlg,
            groupName: report.groupName,
            openDate: report.openDate,
            postUser: report.postUser,
            reportId: report.reportId,
            unreadFlg: report.unreadFlg,
            submissionFlg: report.submissionFlg,
            editNameFlg: report.editNameFlg,
            favoriteFlg: report.favoriteFlg,
            targetSelectFlg: report.targetSelectFlg,
            checkFlg: report.checkFlg,
          };
          // 提出状況の表示
          if(row.unreadFlg){
            row.submissionState = "未読";
            unreadHomeworksCount++;
          } else {
            if(row.submissionFlg){
              row.submissionState = "提出済";
            } else {
              row.submissionState = "既読：未提出";
            }
            // 新規コメントの確認表示
            if(row.checkFlg){
              unreadHomeworksCount++;
            }
          }

          ret.push(row);
        });
        this.unreadHomeworksCount = unreadHomeworksCount;
        this.homeworkList = ret;
        this.groupNames3 = Array.from(temp_groupNames);
      });
      // ストーリー情報の取得
      await schoolAidHomeLogined({funcName:"poLoadStudyStory"}).then((ret) => {
        var buff1 = [];
        ret.data.forEach((recode) => {
          var row = {
            storyCd: recode.storyCd,
            gakunen: recode.gakunen,
            status: recode.status,
            lastUpdDate: recode.lastUpdDate,
            submissionFlg: recode.submissionFlg,
            existsFlg: recode.existsFlg,
            commentFlg: recode.commentFlg,
          }
          buff1.push(row);
        });
        this.storyList = buff1;
      });
      
      // 活動記録情報の取得
      await schoolAidHomeLogined({funcName:"poLoadStudyHistory"}).then((ret) => {
        var buff2 = [];
        ret.data.forEach((recode) => {

          var row = {
            // 課題情報
            homeworkId: recode.homeworkId,
            openDate: recode.openDate,
            createUserNm: recode.createUserNm,
            groupName: recode.groupName,
            titleHW: recode.titleHW,
            limitDate: recode.limitDate,
            bodyHW: recode.bodyHW,
            categoryNmHW: recode.categoryNmHW,
            useCategoryFlg: recode.useCategoryFlg,
            repFlg: recode.repFlg,
            editNameFlg: recode.editNameFlg,

            // 活動記録
            reportId: recode.reportId,
            title: recode.title,
            body: recode.body,
            categoryNm: recode.categoryNm,
            submissionDate: recode.submissionDate,
            submissionDateYYYYMMDD: recode.submissionDateYYYYMMDD,
            checkFlg: recode.checkFlg,
          };

          buff2.push(row);
        });
        this.activityRecordList = buff2;
      });

      this.isListLoding = false;
    },
    // 学習のまとめ登録画面へ
    onClickPoStudyStoryRegist(item) {
      this.$router.push({
        name: "PoStudyStoryRegist",
        params: {
          storyCd: item.storyCd,
        }
      });
    },
    // 課題詳細モーダルを開く
    async openDetailHWDialog(item){
      this.dialogReportId = item.reportId;
      this.dialogTitleHW = item.titleHW;
      this.dialogLimitDateHW = item.limitDate;
      this.dialogBodyHW = item.bodyHW;
      this.dialogCategoryNmHW = item.categoryNmHW;
      this.dialogUseCategoryFlg = item.useCategoryFlg;
      await this.loadFilelistHW();
      this.detailHWDialog = true;
    },
    // 課題のファイルリストを取得
    async loadFilelistHW() {
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isReviewListLoding = true;

      var param = {
        funcName: "poLoadFilelistHW_ForMyself",
        reportId: this.dialogReportId,
      }
      await schoolAidHomeLogined(param).then((ret) => {        
        this.dialogFileListHW = ret.data.fileList;
      });
    },
    // 活動記録モーダルを開く
    async openDetailDialog(item) {
      this.dialogReportId = item.reportId;
      this.dialogTitle = item.title;
      this.dialogBody = item.body;
      this.dialogCategoryNm = item.categoryNm;
      this.dialogUseCategoryFlg = item.useCategoryFlg;
      await this.loadFilelistAndReviews();
      this.detailDialog = true;
    },

    // 活動記録のファイルリストとコメントを取得
    async loadFilelistAndReviews() {
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isReviewListLoding = true;

      var param = {
        funcName: "poLoadARFilelistAndReviewsForMyself",
        reportId: this.dialogReportId,
      }
      await schoolAidHomeLogined(param).then((ret) => {        
        this.dialogFileList = ret.data.fileList;
        this.dialogReviewList = ret.data.reviewList;
      });
    },
    // 活動記録モーダルを閉じる
    async closeDetailDialog() {
      this.detailDialog = false;
    },
    // 添付ファイルダウンロード
    async onClickDwonload(item) {
      saveAsCommon(item.url, item.fileName);
    },
    // エクセル出力処理
    async exportExcelStoryListForMyself() {
      var result = confirm("出力してよろしいですか？");
      if (!result) {
        return;
      }

      // ローディング開始
      this.isExportingFlg1 = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      
      var param = {
        funcName: "exportExcelStoryListForMyself",
      };
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param).then((ret) => {
        if(ret.data.message){
          alert(ret.data.message);
        } else {
          saveAsCommon(ret.data.downloadUrl, ret.data.fileName);
          alert("ダウンロードを開始します。\nダウンロードフォルダをご確認ください。\n※ファイルを確認出来ない場合はポップアップがブロックされていないか\nご確認ください。");
        }
      });
      // ローディング終了
      this.isExportingFlg1 = false;
    },
    // エクセル出力処理
    async exportExcelActivityRecordForMyself() {
      var result = confirm("出力してよろしいですか？");
      if (!result) {
        return;
      }

      // ローディング開始
      this.isExportingFlg2 = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      
      var param = {
        funcName: "exportExcelActivityRecordForMyself",
      };
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param).then((ret) => {
        if(ret.data.message){
          alert(ret.data.message);
        } else {
          saveAsCommon(ret.data.downloadUrl, ret.data.fileName);
          alert("ダウンロードを開始します。\nダウンロードフォルダをご確認ください。\n※ファイルを確認出来ない場合はポップアップがブロックされていないか\nご確認ください。");
        }
      });
      // ローディング終了
      this.isExportingFlg2 = false;
    },

    // 下部スクロール
    scrollToBottomDialog() {
      this.$refs.commentArea.focus();
    },
    autoLink(text) {
      const autolinker = new Autolinker();
      return autolinker.link(text);
    },

    // 検索(課題)=================================================================================
    // タイトルフィルタ
    titleFilter3(value) {
      if (!this.titleFilterValue3) {
        return true;
      }
      return value.toString().includes(this.titleFilterValue3);
    },
    // グループ名フィルタ
    groupNameFilter3(value) {
      if (!this.groupFilterValue3) {
        return true;
      }
      return value.toString() == this.groupFilterValue3;
    },
    // 未読フィルタ
    unreadFilter3(value) {
      if (!this.viewUnreadOnly3) {
        return true;
      }
      return value;
    },
    // お気に入りフィルタ
    favoriteFilter3(value) {
      if (!this.viewFavoriteOnly3) {
        return true;
      }
      return value;
    },
    changeFilter3(flg){
      if(this.viewUnreadOnly3 && this.viewFavoriteOnly3){
        if(flg){
          this.viewFavoriteOnly3 = false;
        } else {
          this.viewUnreadOnly3 = false;
        }
      }
    },
    doFilter3() {
      this.isFilterFlg3 = true;
      this.filterDialog3 = false;
    },
    clearFilter3(){
      this.titleFilterValue3 = "";
      this.groupFilterValue3 = "";
      this.viewUnreadOnly3 = false;
      this.viewFavoriteOnly3 = false;

      this.isFilterFlg3 = false;
      this.filterDialog3 = false;
    },
  },
};
</script>

<style scoped>
.do-style {
  background-color: rgb(182, 182, 182)
}
.not-yet-style {
  background-color: rgb(255, 255, 255)
}
.v-data-table >>> .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none;
}
</style>