<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center" color="blue-grey" dark>
          出欠事前連絡
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-card class="mx-auto text-center">
          日付を選択して、出欠を登録または解除をしてください。
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-card class="mx-auto text-center black--text" outlined>
          <v-sheet
              tile
              height="54"
              class="d-flex"
          >
              <v-row dense>
                  <v-col cols="4" sm="4" md="4" lg="4" xl="4">
                      <!-- 前月へボタン -->
                      <v-btn
                          :disabled="lockFlg"
                          color="orange"
                          @click="changeMonth(true)"
                      >
                          前月へ
                      </v-btn>
                  </v-col>
                  <v-col cols="4" sm="4" md="4" lg="4" xl="4">
                      {{ year }} 年 {{ month }} 月
                  </v-col>
                  <v-col cols="4" sm="4" md="4" lg="4" xl="4">
                      <!-- 次月へボタン -->
                      <v-btn
                          :disabled="!lockFlg"
                          color="orange"
                          @click="changeMonth(false)"
                      >
                          次月へ
                      </v-btn>
                  </v-col>
              </v-row>
          </v-sheet>
          <v-sheet>
            <div class="d-flex justify-space-around">
                <div v-for="w in dayOfWeek"
                    :key="w.value"
                    :class="w.color"
                    >
                    {{ w.label }}
                </div>
            </div>
            <v-calendar
                ref="calendar"
                v-model="focus"
                locale="ja-jp"
                hide-header
            >
                <template v-slot:day-label="{ date,past,present,weekday }">
                    <v-row dense>
                        <v-col v-bind:class="(past||present)?'lock':dayOfWeekStyle[weekday]">
                            <!-- 指定可能日 -->
                            <v-btn
                            v-if="!(past||present)"
                            icon
                            :color="cellColor(date)"
                            :outlined="isInput(date)"
                            @click="clickDate(date)"
                            >
                            {{dayFormat(date)}}
                            </v-btn>
                            <!-- 指定無効日 -->
                            <v-btn
                            v-if="(past||present)"
                            icon>
                            {{dayFormat(date)}}
                            </v-btn>
                        </v-col>
                    </v-row>
                </template>
            </v-calendar>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
        <v-col cols="12" sm="4" md="4" lg="4" xl="4">
            <v-btn color="blue" class="black--text" @click="viewerDialog = true" :disabled="this.viewList.length == 0" width="100%">
                登録内容確認
            </v-btn>
        </v-col>
        <v-col cols="0" sm="4" md="4" lg="4" xl="4">
        </v-col>
        <v-col cols="12" sm="4" md="4" lg="4" xl="4">
            <v-btn color="grey" class="black--text" @click="toAttendanceRegist" width="100%">
                戻る
            </v-btn>
        </v-col>
    </v-row>

    <!-- 登録モーダル -->
    <v-dialog
      v-model="registDialog"
      max-width="600"
      scrollable
      persistent
    >
        <ValidationObserver ref="obs" v-slot="{ invalid }">
        <v-card>
            
            <v-card-title class="pt-2 pb-0" style="font-size:1.23rem;">
                {{ dialogDateDisp }}の出欠連絡
            </v-card-title>
            <v-card-text>
                <v-row justify="center">
                    <div>
                        <v-col cols="12">
                        <v-radio-group
                            label="出欠区分"
                            v-model="dialogSyukketuKbn"
                            class="justify-center"
                            row
                        >
                            <v-radio color="red" value="1">
                                <template v-slot:label>
                                    <div class="text-no-wrap red lighten-3" style="width: 2rem;">
                                        欠席
                                    </div>
                                </template>
                            </v-radio>
                            <v-radio color="green" value="2">
                                <template v-slot:label>
                                    <div class="text-no-wrap green lighten-3" style="width: 2rem;">
                                        遅刻
                                    </div>
                                </template>
                            </v-radio>
                            <v-radio color="blue" value="3">
                                <template v-slot:label>
                                    <div class="text-no-wrap blue lighten-3" style="width: 4rem;">
                                        早退予定
                                    </div>
                                </template>
                            </v-radio>
                            <v-radio color="purple" value="4">
                                <template v-slot:label>
                                    <div class="text-no-wrap purple lighten-3" style="width: 7rem;">
                                        遅刻⇒早退予定
                                    </div>
                                </template>
                            </v-radio>
                        </v-radio-group>
                        </v-col>
                    </div>
                </v-row>
                <v-row dense>
                    <v-col dense cols="12" justify="center">
                        <ValidationProvider name="備考" rules="max:150" v-slot="{ errors }">
                            <v-text-field
                                v-model="dialogBiko"
                                label="備考"
                                placeholder="備考"
                                :error-messages="errors[0]"
                                outlined
                                dense
                                :counter="150"
                                hide-details="auto"
                            ></v-text-field>
                        </ValidationProvider>
                    </v-col>
                </v-row>
                <v-row dense v-if="dialogSyukketuKbn == '3' || dialogSyukketuKbn == '4'">
                    <v-col dense cols="12" justify="center">
                    <ValidationProvider name="早退事由" rules="required|max:50" v-slot="{ errors }">
                        <v-text-field
                        v-model="dialogLeavingReason"
                        prepend-icon="$cet_required"
                        label="早退事由"
                        placeholder="早退事由"
                        :error-messages="errors[0]"
                        outlined
                        dense
                        :counter="50"
                        hide-details="auto"
                        ></v-text-field>
                    </ValidationProvider>
                    </v-col>
                </v-row>
                <v-row dense v-if="dialogSyukketuKbn == '3' || dialogSyukketuKbn == '4'">
                    <v-col dense cols="12" justify="center">
                        <v-menu
                            ref="menu"
                            v-model="menu2"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            :return-value.sync="dialogLeavingTime"
                            transition="scale-transition"
                            offset-y
                            max-width="290px"
                            min-width="290px"
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <ValidationProvider name="下校開始予定時刻" rules="required" v-slot="{ errors }">
                                <v-text-field
                                    v-model="dialogLeavingTime"
                                    prepend-icon="$cet_required"
                                    label="下校開始予定時刻"
                                    placeholder="下校開始予定時刻"
                                    :error-messages="errors[0]"
                                    readonly
                                    outlined
                                    dense
                                    clearable
                                    hide-details="auto"
                                    v-bind="attrs"
                                    v-on="on"
                                    ></v-text-field>
                            </ValidationProvider>
                        </template>
                        <v-time-picker
                            v-if="menu2"
                            v-model="dialogLeavingTime"
                            :allowed-hours="allowedHours"
                            :allowed-minutes="allowedStep"
                            full-width
                            @click:minute="$refs.menu.save(dialogLeavingTime)"
                        ></v-time-picker>
                        </v-menu>
                    </v-col>
                    </v-row>
            </v-card-text>
            <v-card-actions>
                <v-row dense>
                    <v-col cols="6" justify="center">
                        <v-btn block color="grey" @click="registDialog = false">
                            閉じる
                        </v-btn>
                    </v-col>
                    <v-col cols="6" justify="center">
                        <v-btn block color="orange" @click="registDate" :disabled="invalid">
                            登録
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
            
        </v-card>
        </ValidationObserver>
    </v-dialog>

    <!-- 一覧表示モーダル -->
    <v-dialog
      v-model="viewerDialog"
      max-width="600"
      scrollable
      persistent
    >
        <v-card>
            <v-card-title class="pt-2 pb-0" style="font-size:1.23rem;">
                連絡内容
            </v-card-title>
            <v-card-text>
                <div v-for="item in viewList" :key="item.date">
                    <v-row dense>
                        <v-col cols="12" justify="center" class="d-flex">
                            <span :class="dayOfWeek[item.weekday].color">{{ item.dateDisp }}</span>
                            <span :class="item.style" style="width: 7rem;">{{ item.syukketuKbnName }}</span>
                            <span class="ml-2">{{ item.biko }}</span>
                            <span v-if="!item.biko">未設定</span>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="item.sotaiFlg">
                        <v-col cols="12" justify="center" class="d-flex">
                            <span class="ml-15">早退事由：{{ item.leavingReason }}</span>
                        </v-col>
                    </v-row>
                    <v-row dense v-if="item.sotaiFlg">
                        <v-col cols="12" justify="center" class="d-flex">
                            <span class="ml-15">早退予定時刻：{{ item.leavingTime }}</span>
                        </v-col>
                    </v-row>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-row dense>
                    <v-col cols="12" class="ml-1" justify="center">
                        <v-btn block color="grey" @click="viewerDialog = false">
                            閉じる
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fixed fab bottom color="secondary" @click="reload" style="bottom: 50px">
          <v-icon color="white">mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>再読込</span>
    </v-tooltip>

  </v-container>
</template>

<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import { format, parse } from 'date-fns';
import { ja } from 'date-fns/locale';
export default {
    props: ['id'],
    data: () => ({

        year: format(new Date().setDate(new Date().getDate() + 1), 'yyyy', { locale: ja }),
        month: format(new Date().setDate(new Date().getDate() + 1), 'M', { locale: ja }),
        focus: format(new Date().setDate(new Date().getDate() + 1), 'yyyy-MM-dd', { locale: ja }),
        lockFlg: true,
        dayOfWeekStyle: ['sun','','','','','','sat'],
        dayOfWeek: [
            { value: 0, label: "日", color: "red--text" },
            { value: 1, label: "月", color: "" },
            { value: 2, label: "火", color: "" },
            { value: 3, label: "水", color: "" },
            { value: 4, label: "木", color: "" },
            { value: 5, label: "金", color: "" },
            { value: 6, label: "土", color: "blue--text" },
        ],
        all: [],
        kesseki: [],
        chikoku: [],
        sotai: [],
        chiso: [],
        viewList: [],

        // 登録モーダル
        registDialog: false,
        // 登録処理日付
        dialogDateDisp: "",
        dialogDate: "",
        dialogSyukketuKbn: "1",
        dialogBiko: "",
        dialogLeavingReason: "",
        dialogLeavingTime: "",
        menu2: false,

        // 一覧表示モーダル
        viewerDialog: false,
    }),
    async created() {
        await this.reload();
    },
    methods: {
        // 日付ラベル設定
        async reload(){
            // ローディング開始
            this.$root.loading = true;
            const functions = getFunctions(this.$firebase);
            functions.region = "asia-northeast1";
            const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");

            const data = {
                funcName: "loadAttendanceFutureRegist",
                studentId: this.id
            };

            await schoolAidHomeLogined(data).then((res) => {
                this.all = res.data.all;
                this.kesseki = res.data.kesseki;
                this.chikoku = res.data.chikoku;
                this.sotai = res.data.sotai;
                this.chiso = res.data.chiso;
                this.viewList = res.data.viewList;
            }).catch((e) =>{
                console.log(e);
                this.$root.loading = false;
            });

            // ローディング終了
            this.$root.loading = false;
        },

        // 日付選択(登録ダイアログ or キャンセルダイアログの表示)
        async clickDate(date) {
            console.log("clickDate",date);
            // 既存データ確認
            const index0 = this.all.indexOf(date);
            if (index0 === -1) {
                // 登録ダイアログ表示
                this.viewRegistDialog(date);
            } else {
                // キャンセル処理
                await this.cancelDate(date);
            }
        },
        // 登録ダイアログ表示処理
        viewRegistDialog(date) {
            var dialogDateDisp = format(parse(date, 'yyyy-MM-dd', new Date()), 'yyyy年M月d日(E)', { locale: ja });
            this.dialogDateDisp = dialogDateDisp;
            this.dialogDate = date;
            this.dialogSyukketuKbn = "1";
            //this.dialogBiko = "";
            this.dialogLeavingReason = "";
            this.dialogLeavingTime = "";
            this.registDialog = true;
        },
        // 登録処理
        async registDate() {

            // ローディング表示
            this.$root.loading = true;
            const functions = getFunctions(this.$firebase);
            functions.region = "asia-northeast1";
            const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
            // 登録処理
            const data = {
                funcName: "saveAttendanceFutureRegist",
                targetDate: this.dialogDate,
                studentId: this.id,
                syukketuKbn: this.dialogSyukketuKbn,
                biko: this.dialogBiko,
                leavingReason: this.dialogLeavingReason,
                leavingTime: this.dialogLeavingTime,
            };

            await schoolAidHomeLogined(data).then((res) => {
                this.$root.loading = false;
                console.log(res.data);
                alert("連絡しました。");
            }).catch((e) =>{
                console.log(e);
            });
            
            await this.reload();
            this.registDialog = false;
        },
        // キャンセル処理
        async cancelDate(date) {
            var result = confirm("出欠予定を解除してよろしいですか？");
            if (!result) {
                return;
            }
            // ローディング表示
            this.$root.loading = true;
            const functions = getFunctions(this.$firebase);
            functions.region = "asia-northeast1";
            const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
            // 登録処理
            const data = {
                funcName: "cancelAttendanceFutureRegist",
                targetDate: date,
                studentId: this.id,
            };

            await schoolAidHomeLogined(data).then((res) => {
                this.$root.loading = false;
                console.log(res.data);
                alert("解除しました。");
            }).catch((e) =>{
                console.log(e);
            });
            
            await this.reload();
        },
        // カレンダー制御用 start *********************************************************************
        // カレンダーの月変更
        changeMonth: function(flg){
            if(flg){
                this.$refs.calendar.prev();
            } else {
                this.$refs.calendar.next();
            }
            var buff = parse(this.focus, 'yyyy-MM-dd', new Date());
            this.year = format(buff, 'yyyy', { locale: ja });
            this.month = format(buff, 'M', { locale: ja });
            this.lockFlg = !this.lockFlg;
        },
        // 日付ラベル設定
        dayFormat: function(date){
            var buff = parse(date, 'yyyy-MM-dd', new Date());
            // 月初判定
            if(buff.getDate() == 1){
                return format(buff, 'M/d', { locale: ja })
            } else {
                return format(buff, 'd', { locale: ja })
            }
        },
        // 選択日出欠区分判定(セル表示用)
        cellColor(date) {
            if(this.kesseki.some((x) => x === date)){
                return 'red';
            } else if(this.chikoku.some((x) => x === date)){
                return 'green';
            } else if(this.sotai.some((x) => x === date)){
                return 'blue';
            } else if(this.chiso.some((x) => x === date)){
                return 'purple';
            } else {
                return 'black'
            }
        },
        // 入力判定(セル表示用)
        isInput(date) {
            return this.all.some((x) => x === date);
        },
        // カレンダー制御用 end *********************************************************************
        toAttendanceRegist() {
            this.$router.push({
                name: "AttendanceRegist",
                params: {
                id: this.id,
                }
            });
        },
        // タイムピッカーの入力制限設定
        allowedHours: v => 5 < v && v < 24,
        allowedStep: m => m % 10 === 0,
    },
};
</script>

<style scoped>
.lock {
    background: rgba(97, 97, 97) !important;
}
.sun {
    background: rgba(255, 204, 188) !important;
}
.sat {
    background: rgba(207, 216, 220) !important;
}
</style>