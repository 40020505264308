<template>
  <v-container fluid>
    <v-row dense>
      <v-col cols="2" justify="center">
        <v-btn color="accent" class="black--text" @click="toGradePublishSettings" width="90%">開示設定(試験結果)</v-btn>
      </v-col>
      <v-col cols="8" justify="center">
        <v-card class="mx-auto text-center black--text" outlined>
          成績開示設定(通知表)
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="ml-2" v-bind="attrs" v-on="on" color="orange">mdi-help-circle-outline</v-icon>
            </template>
            <div>教務システムの通知表機能にて連携処理を実行すると、</div>
            <div>開示設定として下表に追加されます。</div>
          </v-tooltip>
        </v-card>
      </v-col>
      <v-col cols="2" justify="center"></v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
        <!-- 検索用の年度入力 -->
        <v-autocomplete
          v-model="selectNendo"
          label="年度指定"
          placeholder="年度指定"
          :items="nendoList"
          @change="reload"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6" xl="6">
        <v-autocomplete
          v-model="publisherValue"
          label="発行者指定"
          placeholder="発行者指定"
          :items="publisherList"
          outlined
          dense
          clearable
          hide-details="auto"
          background-color="white"
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12" justify="center">
        <v-data-table
          :headers="headers"
          :items="reportList"
          :items-per-page.sync="perPage"
          :footer-props="{'items-per-page-options': [10, 20, 30, 50, 100, -1]}"
          dense
          disable-sort
          :loading="isLoding" loading-text="読込中"
        >
          <!-- ラベル：連携日付/学級名 -->
          <template #[`header.insDate`]="{}"> 
            <v-row no-gutters><v-col cols="12" justify="center">連携日時</v-col></v-row>
            <v-row no-gutters><v-col cols="12" justify="center">対象学級</v-col></v-row>
            <v-row no-gutters><v-col cols="12" justify="center">学期</v-col></v-row>
          </template>
          <!-- ラベル：発行教員/確認教員 -->
          <template #[`header.publisherNm`]="{}"> 
            <v-row no-gutters><v-col cols="12" justify="center">帳票名</v-col></v-row>
            <v-row no-gutters><v-col cols="12" justify="center">発行教員</v-col></v-row>
            <v-row no-gutters><v-col cols="12" justify="center">確認教員</v-col></v-row>
          </template>
          <!-- ラベル：公開状態/設定日時 -->
          <template #[`header.publishStatus`]="{}"> 
            <v-row no-gutters><v-col cols="12" justify="center">公開状態</v-col></v-row>
            <v-row no-gutters><v-col cols="12" justify="center">設定日時</v-col></v-row>
          </template>
          <!-- 連携日付/学級名 -->
          <template #[`item.insDate`]="{ item }">
            <v-row no-gutters><v-col cols="12" justify="center">{{ item.insDate }}</v-col></v-row>
            <v-row no-gutters><v-col cols="12" justify="center">{{ item.groupName }}</v-col></v-row>
            <v-row no-gutters><v-col cols="12" justify="center">{{ item.gakki }}</v-col></v-row>
          </template>
          <!-- 発行教員/確認教員 -->
          <template #[`item.publisherNm`]="{ item }">
            <v-row dense><v-col cols="12" justify="center">{{ item.reportNm }}</v-col></v-row>
            <v-row dense><v-col cols="12" justify="center">{{ item.publisherNm }}</v-col></v-row>
            <v-row dense><v-col cols="12" justify="center">{{ item.confirmerNms }}</v-col></v-row>
          </template>
          <!-- 確認教員変更 -->
          <template #[`item.confirmerChange`]="{ item }">
            <v-tooltip top v-if="item.publisherFlg == true">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" @click="openConfirmerModal(item)">変更</v-btn>
              </template>
              <span>帳票確認出来る教員を変更する</span>
            </v-tooltip>
            <v-tooltip top v-if="item.publisherFlg == false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" :disabled="true">変更</v-btn>
              </template>
              <span>帳票確認出来る教員を変更する</span>
            </v-tooltip>
          </template>
          <!-- 公開状態/設定日時 -->
          <template #[`item.publishStatus`]="{ item }">
            <v-row no-gutters v-if="item.studentPublishFlg == true"><v-col cols="12" class="red--text" justify="center">(生徒)公開状況：{{ item.studentPublishStatus }}</v-col></v-row>
            <v-row no-gutters v-if="item.studentPublishFlg == true"><v-col cols="12" class="red--text" justify="center">(生徒)設定日時：{{ item.studentPublishDate }}</v-col></v-row>
            <v-row no-gutters v-if="item.parentPublishFlg == true"><v-col cols="12" class="red--text" justify="center">(保護者)公開状況：{{ item.parentPublishStatus }}</v-col></v-row>
            <v-row no-gutters v-if="item.parentPublishFlg == true"><v-col cols="12" class="red--text" justify="center">(保護者)設定日時：{{ item.parentPublishDate }}</v-col></v-row>
            <v-row no-gutters v-if="item.studentPublishFlg == false"><v-col cols="12" justify="center">(生徒)公開状況：{{ item.studentPublishStatus }}</v-col></v-row>
            <v-row no-gutters v-if="item.studentPublishFlg == false"><v-col cols="12" justify="center">(生徒)設定日時：{{ item.studentPublishDate }}</v-col></v-row>
            <v-row no-gutters v-if="item.parentPublishFlg == false"><v-col cols="12" justify="center">(保護者)公開状況：{{ item.parentPublishStatus }}</v-col></v-row>
            <v-row no-gutters v-if="item.parentPublishFlg == false"><v-col cols="12" justify="center">(保護者)設定日時：{{ item.parentPublishDate }}</v-col></v-row>
          </template>
          <!-- 生徒公開状態変更 -->
          <template #[`item.publishStatusChange`]="{ item }">
            <v-row dense><v-col cols="12" justify="center">
              <v-tooltip top v-if="item.confirmerFlg == true">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" @click="changeStudentStatus(item)">生徒変更</v-btn>
                </template>
                <span>生徒の公開状況を変更する</span>
              </v-tooltip>
              <v-tooltip top v-if="item.confirmerFlg == false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" :disabled="true">生徒変更</v-btn>
                </template>
                <span>生徒の公開状況を変更する</span>
              </v-tooltip>
            </v-col></v-row>
            <v-row dense><v-col cols="12" justify="center">
              <v-tooltip top v-if="item.confirmerFlg == true">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" @click="changeParentStatus(item)">保護者変更</v-btn>
                </template>
                <span>保護者の公開状況を変更する</span>
              </v-tooltip>
              <v-tooltip top v-if="item.confirmerFlg == false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" :disabled="true">保護者変更</v-btn>
                </template>
                <span>保護者の公開状況を変更する</span>
              </v-tooltip>
            </v-col></v-row>
          </template>

          <!-- 内容確認 -->
          <template #[`item.action`]="{ item }">
            <v-tooltip top v-if="item.confirmerFlg == true">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" @click="openDownLoadModal(item)">確認</v-btn>
              </template>
              <span>帳票の内容を確認する</span>
            </v-tooltip>
            <v-tooltip top v-if="item.confirmerFlg == false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" :disabled="true">確認</v-btn>
              </template>
              <span>帳票の内容を確認する</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fixed fab bottom color="secondary" @click="reload" style="bottom: 50px">
          <v-icon color="white">mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>再読込</span>
    </v-tooltip>

    <!-- 確認教員変更モーダル -->
    <v-dialog
      v-model="confirmerDialog"
      persistent
      max-width="800"
    >
      <ValidationObserver ref="obs" v-slot="{ invalid }">
        <v-card
          class="d-flex justify-center flex-column mx-auto flat"
          width="800"
          color="#fff"
        >
          <v-card-subtitle class="d-flex justify-center" white-space="pre">
            <v-row dense>
              <v-col dense cols="4" justify="center">
                <v-btn color="grey" class="black--text" @click="confirmerDialog = false" width="90%">
                  閉じる
                </v-btn>
              </v-col>
              <v-col dense cols="4" justify="center">
                <p>確認教員を更新する</p>
              </v-col>
              <v-col dense cols="4" justify="center">
                <v-btn color="accent" class="black--text" @click="changeConfirmer()" :disabled="invalid" width="90%">
                  更新
                </v-btn>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-card-text class="d-flex justify-center flex-column">
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <ValidationProvider name="帳票を確認する教員(自身以外)" rules="required" :skipIfEmpty="false" v-slot="{ errors }">
                  <v-autocomplete
                    v-model="confirmerIds"
                    prepend-icon="$cet_required"
                    label="帳票を確認する教員(自身以外)"
                    placeholder="帳票を確認する教員(自身以外)"
                    :items="teacherList"
                    outlined
                    dense
                    multiple
                    deletable-chips
                    small-chips
                    :error-messages="errors[0]"
                    hide-details="auto"
                    background-color="white"
                  ></v-autocomplete>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </ValidationObserver>
    </v-dialog>

    <!-- 帳票確認モーダル -->
    <v-dialog v-model="downloadDialog" max-width="800">
      <v-card
        class="d-flex justify-center flex-column mx-auto flat"
        width="800"
        color="#fff"
      >
        <v-card-title class="pl-5" style="font-size:1.012rem;">帳票確認</v-card-title>
        <v-card-text>
          <v-data-table
            :headers="downloadHeader"
            :items="downloadList"
            :items-per-page="-1"
            dense
            disable-sort
            hide-default-footer
          >
            <!-- 氏名/アップロード日時 -->
            <template #[`item.userNm`]="{ item }">
              <v-row no-gutters><v-col cols="12" justify="center">{{ item.userNm }}</v-col></v-row>
              <v-row no-gutters v-if="item.delFlg == true"><v-col cols="12" justify="center">-</v-col></v-row>
              <v-row no-gutters v-if="item.delFlg == false"><v-col cols="12" justify="center">{{ item.uploadDate }}</v-col></v-row>
            </template>
            <!-- ダウンロードボタン -->
            <template #[`item.url`]="{ item }">
              <v-tooltip bottom v-if="item.delFlg == false">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" @click="onClickDwonload(item)">
                      <v-icon color="black">mdi-download</v-icon>
                    </v-btn>
                  </template>
                  <span>帳票をダウンロードする。</span>
                </v-tooltip>
            </template>
            <!-- 確認状況ボタン -->
            <template #[`item.unreadStatus`]="{ item }">
              <v-row no-gutters><v-col cols="12" justify="center">生徒：{{ item.studentUnreadStatus }}</v-col></v-row>
              <v-row no-gutters><v-col cols="12" justify="center">保護者：{{ item.parentUnreadStatus }}</v-col></v-row>
            </template>
            <!-- 削除ボタン -->
            <template #[`item.delStatus`]="{ item }">
              <v-tooltip bottom v-if="item.delFlg == false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on" @click="onClickDelete(item)">
                    <v-icon color="black">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>帳票を削除する。</span>
              </v-tooltip>
              <span class="red--text" v-if="item.delFlg == true">
                {{item.delStatus}}
              </span>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
  
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import { saveAsCommon } from '../../common';
export default {
  data() {
    return {
      tounendo: "",
      selectNendo: "",
      nendoList : [],
      publisherValue: "",
      publisherList : [],
      headers : [
        { text: "連携日時/対象学級", value: "insDate" , width: "250" },
        { text: "発行教員/確認教員", value: "publisherNm" , width: "250", filter: this.teacherFilter }, 
        { text: "確認教員変更", value: "confirmerChange" , width: "100" },
        { text: "公開状況", value: "publishStatus" , width: "250" }, 
        { text: "公開状況設定", value: "publishStatusChange" , width: "80" },
        { text: "内容確認", value: "action", width: "80" },
      ],
      reportList: [],
      isLoding: false,
      // 確認教員更新用
      confirmerDialog: false,
      reportId: "",
      repoCd: "",
      confirmerIds: [],
      teacherList:[],
      downloadDialog: false,
      downloadHeader: [
        { text: "出席番号", value: "syussekiNo" , width: "60" },
        { text: "氏名/アップロード日時", value: "userNm" , width: "280" },
        { text: "帳票", value: "url" , width: "80" },
        { text: "確認状況", value: "unreadStatus" , width: "120" },
        { text: "削除", value: "delStatus" , width: "80" },
      ],
      downloadList: [],
      perPage: 30,
    };
  },
  // 初期表示時データ読み込み
  async created() {
    // 年度リストの生成
    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";
    const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
    await schoolAidHomeLogined({funcName: "commonGetNendo"}).then((ret) => {
      this.tounendo = ret.data.tounendo;
      this.selectNendo = ret.data.tounendo;
      this.nendoList = ret.data.list;
    });

    schoolAidHomeLogined({funcName: "commonGetTeachers", includeFlg: false}).then((ret) => {
      this.teacherList = ret.data.teacherList;
    })

    await this.reload();
  },
  methods: {
    // 発行者名フィルタ
    teacherFilter(value) {
      if (!this.publisherValue) {
        return true;
      }
      return value.includes(this.publisherValue);
    },
    // リスト読込
    async reload() {
      // 結果格納用配列
      this.reportList = [];
      // 条件格納用配列
      var temp_teachers = new Set();
      // 検索処理
      this.isLoding = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined({funcName:"loadReportPublishSettings", nendo:this.selectNendo }).then((ret) => {
        // 発行者の名前をリスト化
        ret.data.forEach((row) => {
          temp_teachers.add(row.publisherNm);
        });
        this.reportList = ret.data;
        this.isLoding = false;
      });
      this.publisherList = Array.from(temp_teachers);
    },
    // 確認教員変更モーダルを表示する
    openConfirmerModal(item) {
      this.confirmerIds = item.confirmerIds;
      this.reportId = item.reportId;
      this.repoCd = item.repoCd;
      this.confirmerDialog = true;
    },
    // 確認教員を変更する
    async changeConfirmer() {
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      var param = {
        funcName:"updateReportPublishSettingConfirmerId",
        reportId: this.reportId,
        repoCd: this.repoCd,
        confirmerIds: this.confirmerIds,
      }
      await schoolAidHomeLogined(param).then((ret) => {
        console.log(ret);
      });
      this.confirmerDialog = false;
      await this.reload();
    },
    // 生徒状態更新
    async changeStudentStatus(item){
      var result = confirm("生徒の公開状態を変更してよろしいですか？");
      if (!result) {
        return;
      }
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      var param = {
        funcName:"changeReportPublishSettingStudent",
        reportId: item.reportId,
        repoCd: item.repoCd,
        publishFlg: item.studentPublishFlg,
      }
      await schoolAidHomeLogined(param).then((ret) => {
        item.studentPublishFlg = ret.data.publishFlg;
        item.studentPublishStatus = ret.data.publishStatus;
        item.studentPublishDate = ret.data.publishDate;
      });
    },
    // 保護者状態更新
    async changeParentStatus(item){
      var result = confirm("保護者の公開状態を変更してよろしいですか？");
      if (!result) {
        return;
      }
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      var param = {
        funcName:"changeReportPublishSettingParent",
        reportId: item.reportId,
        repoCd: item.repoCd,
        publishFlg: item.parentPublishFlg,
      }
      await schoolAidHomeLogined(param).then((ret) => {
        item.parentPublishFlg = ret.data.publishFlg;
        item.parentPublishStatus = ret.data.publishStatus;
        item.parentPublishDate = ret.data.publishDate;
      });
    },
    // 帳票一覧をモーダルで表示する
    async openDownLoadModal(item) {
      // 帳票削除のために退避
      this.reportId = item.reportId;
      this.repoCd = item.repoCd;
      
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      var param = {
        funcName:"loadReportPublishList",
        reportId: item.reportId,
        repoCd: item.repoCd,
      }
      this.downloadList = [];
      await schoolAidHomeLogined(param).then((ret) => {
        this.downloadList = ret.data;
      });
      this.downloadDialog = true;
    },
    async onClickDwonload(item) {
      saveAsCommon(item.url, item.fileName);
    },
    // 帳票削除
    async onClickDelete(item){
      var result = confirm("連携された帳票を削除してよろしいですか？");
      if (!result) {
        return;
      }
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      var param = {
        funcName:"deleteReportPublish",
        reportId: this.reportId,
        repoCd: this.repoCd,
        userId: item.userId,
      }
      await schoolAidHomeLogined(param).then((ret) => {
        item.delFlg = ret.data.delFlg;
        item.delStatus = ret.data.delStatus;
      });
    },
    // 成績開示設定(試験結果)画面へ
    toGradePublishSettings() {
      this.$router.push({
        name: "GradePublishSettings",
      });
    },

  },
};
</script>

<style scoped>
.modal-header, .modal-body {
  padding: 5px 25px;
}
.modal-header {
  border-bottom: 1px solid #ddd;
}

</style>