<template>
  <v-container fluid>
    <ValidationObserver ref="obs" v-slot="{ invalid }">
      <v-card
        class="d-flex justify-center flex-column mx-auto my-2 flat"
        width="1200"
        color="#fff"
      >
        <v-card-title class="d-flex justify-center pa-0 my-2">
          連絡登録
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="d-flex flex-column">
          <ValidationProvider name="対象グループ" rules="required" v-slot="{ errors }" :skipIfEmpty="false">
            <v-row dense>
              <v-col cols="12" sm="7" md="8" lg="8" xl="8" class="mb-2">
                <v-autocomplete
                  prepend-icon="$cet_required"
                  v-model="selectGroupId"
                  label="対象グループ"
                  placeholder="対象グループ"
                  item-text="groupName"
                  item-value="groupId"
                  :items="groups"
                  :error-messages="errors[0]"
                  outlined
                  dense
                  @change="clearTarget"
                  hide-details="auto"
                  background-color="white"
                >
                  <template v-slot:item="{ item }">
                    <div class="d-flex col-10">{{ item.groupName }}</div>
                    <div class="d-flex col-1">
                      <v-tooltip bottom v-if="item.canViewFlg || item.groupKbn != '0' || canGroupPattern.includes(item.groupPattern)">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" >mdi-comment-text-outline</v-icon>
                        </template>
                        <span>コメント設定可</span>
                      </v-tooltip>
                    </div>
                    <div class="d-flex col-1">
                      <v-tooltip top v-if="item.favoriteFlg">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" color="orange">mdi-star</v-icon>
                        </template>
                        <span>お気に入り</span>
                      </v-tooltip>
                    </div>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12" sm="5" md="4" lg="4" xl="4" class="d-flex justify-end mb-2">
                <v-switch 
                  v-model="isTargetSelect"
                  dense
                  @change="clearTarget"
                  class="mt-1"
                  label="対象ユーザーを指定する"
                  hide-details="auto"
                />
                <v-btn color="accent" class="ma-2 black--text" :disabled="isTargetSelect == false || !selectGroupId" @click="viewTargetSelectDialog = true">
                  選択
                </v-btn>
              </v-col>
            </v-row>
          </ValidationProvider>
          <ValidationProvider name="ユーザー区分" rules="required|checkboxRequired" v-slot="{ errors }">
            <v-row dense>
              <v-col cols="12" class="d-flex mb-2">
                <v-row>
                  <v-col cols="12" sm="3" md="3" lg="3" xl="3" class="d-flex">
                    <v-icon v-if="isTargetSelect == false">$cet_required</v-icon><div class="ml-2">対象ユーザー区分選択</div>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon class="ml-2" v-bind="attrs" v-on="on" color="orange">mdi-help-circle-outline</v-icon>
                      </template>
                      <div>グループに所属するどのユーザー区分へ</div>
                      <div>連絡を届けるか選択してください</div>
                    </v-tooltip>
                  </v-col>  
                  <v-col cols="4" sm="3" md="3" lg="3" xl="3" class="d-flex">
                    <v-checkbox v-model="selectedUserKbn"
                      label="生徒"
                      value="0"
                      class="mt-0"
                      :disabled="isTargetSelect == true"
                      hide-details="auto"
                    ></v-checkbox><div class="ml-2 mt-1" v-if="isTargetSelect">({{ studentIds.length }}人)</div>
                  </v-col>
                  <v-col cols="4" sm="3" md="3" lg="3" xl="2" class="d-flex">
                    <v-checkbox v-model="selectedUserKbn"
                      label="保護者"
                      value="1"
                      class="mt-0"
                      :disabled="isTargetSelect == true"
                      hide-details="auto"
                    ></v-checkbox><div class="ml-2 mt-1" v-if="isTargetSelect">({{ parentIds.length }}人)</div>
                  </v-col>
                  <v-col cols="4" sm="3" md="3" lg="3" xl="3" class="d-flex">
                    <v-checkbox v-model="selectedUserKbn"
                      label="教員"
                      value="8"
                      class="mt-0"
                      :disabled="isTargetSelect == true"
                      hide-details="auto"
                    ></v-checkbox><div class="ml-2 mt-1" v-if="isTargetSelect">({{ teacherIds.length }}人)</div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <p v-if="isTargetSelect == false" class="error">{{ errors[0] }}</p>
          </ValidationProvider>
          <v-divider></v-divider>
          <v-row dense>
            <v-col cols="12" class="d-flex my-4 justify-end">
              下書き
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="green" v-bind="attrs" v-on="on" small class="black--text mx-2" @click="openDraftSelect">参照</v-btn>
                </template>
                <span>保存されている下書き一覧を表示します</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="green" v-bind="attrs" v-on="on" small class="black--text mx-2" @click="addDraft">保存</v-btn>
                </template>
                <span>タイトルと本文を下書きとして保存します</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <ValidationProvider name="連絡タイトル" rules="required|max:50" v-slot="{ errors }">
            <v-row dense>
              <v-col cols="12" class="d-flex mb-0">
                <v-text-field
                  v-model="title"
                  prepend-icon="$cet_required"
                  label="連絡タイトル"
                  placeholder=""
                  :error-messages="errors[0]"
                  outlined
                  dense
                  :counter="50"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
          </ValidationProvider>
          <ValidationProvider name="連絡本文" rules="required|max:3000" v-slot="{ errors }">
            <v-row dense>
              <v-col cols="12" class="d-flex mb-0">
                <v-textarea
                  v-model="body"
                  prepend-icon="$cet_required"
                  label="連絡本文"
                  placeholder=""
                  :error-messages="errors[0]"
                  outlined
                  dense
                  auto-grow
                  :counter="3000"
                  hide-details="auto"
                ></v-textarea>
              </v-col>
            </v-row>
          </ValidationProvider>

          <ValidationProvider name="公開開始日" rules="required" v-slot="{ errors }">
            <v-row dense>
              <v-col class="d-flex mb-2" cols="12" sm="12" md="12" lg="12" xl="12">
                <v-text-field
                  v-model="openDate"
                  prepend-icon="$cet_required"
                  label="公開開始日"
                  placeholder=""
                  type="datetime-local"
                  :error-messages="errors[0]"
                  outlined
                  dense
                  hide-details="auto"
                >
                </v-text-field>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="green" v-bind="attrs" v-on="on" small class="black--text ml-2 mt-1 hidden-xs-only" @click="setFastOpen">３分後に設定</v-btn>
                  </template>
                  <span>公開開始日に現在時刻の３分後を設定します</span>
                </v-tooltip>
              </v-col>
              <v-col class="d-flex mb-3 justify-end d-sm-none" cols="12">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="green" v-bind="attrs" v-on="on" small class="black--text" @click="setFastOpen">３分後に設定</v-btn>
                  </template>
                  <span>公開開始日に現在時刻の３分後を設定します</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </ValidationProvider>

          <ValidationProvider name="公開終了日" rules="required|datehourAfter:@公開開始日" v-slot="{ errors }">
            <v-row dense>
              <v-col cols="12" class="d-flex mb-2">
                <v-text-field
                  v-model="closeDate"
                  prepend-icon="$cet_required"
                  label="公開終了日"
                  placeholder=""
                  type="datetime-local"
                  :error-messages="errors[0]"
                  outlined
                  dense
                  hide-details="auto"
                ></v-text-field>
              </v-col>
            </v-row>
          </ValidationProvider>
          <v-row no-gutters>
            <v-col class="d-flex justify-end mb-1" cols="12" sm="7" md="7" lg="7" xl="7">
              <v-checkbox class="mt-1" v-model="mailSendFlg" label="公開開始にあわせてメールを配信する" hide-details="auto" />
            </v-col>
            <v-col class="d-flex justify-end mb-1" cols="12" sm="5" md="5" lg="5" xl="5">
              <v-checkbox class="mt-1" v-model="commentFlg" label="コメント設定" hide-details="auto" :disabled="!canCommentFlg" />
              <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="ml-2 mt-2" v-bind="attrs" v-on="on" color="orange">mdi-help-circle-outline</v-icon>
                </template>
                <div>学年・学級・任意グループへの連絡は</div>
                <div>コメントをやり取りする設定が可能です。</div>
                <div>※対象ユーザー全員がコメントの登録・参照を出来ます。</div>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="pt-0">
          <dashboard :uppy="uppy" :props="{
              theme: 'light',
              inline: true,
              width: 3000,
              height: 350,
              disableThumbnailGenerator:true,
              showRemoveButtonAfterComplete:true,
              proudlyDisplayPoweredByUppy:false,
            }" />
            ※添付ファイルは(容量：50MB、個数：10)が上限となっております
        </v-card-text>
        <v-card-actions class="d-flex mx-2 justify-center">
          <v-btn color="accent" class="mt-0 black--text" @click="saveContactRegist" :disabled="invalid" width="100%">登録</v-btn>
        </v-card-actions>  
      </v-card>
    </ValidationObserver>
    <!-- 下書き選択ダイアログ -->
    <v-dialog
      v-model="viewDraftSelectDialog"
      max-width="864"
    >
      <DraftSelect
        draftType="0"
        v-bind:openTime="openTime"
        @closeDialog="closeDialog"
        @setDraft="setDraft"
      />
    </v-dialog>
    <!-- 対象ユーザー選択ダイアログ -->
    <v-dialog
      v-model="viewTargetSelectDialog"
      scrollable
      persistent
    >
      <TargetSelect
        v-bind:groupId="selectGroupId"
        v-bind:studentIds="studentIds"
        v-bind:parentIds="parentIds"
        v-bind:teacherIds="teacherIds"
        @closeDialog="closeDialog"
        @setUserKbns="setUserKbns"
        @setStudentIds="setStudentIds"
        @setParentIds="setParentIds"
        @setTeacherIds="setTeacherIds"
      />
    </v-dialog>

  </v-container>
</template>

<script>
import { Dashboard } from '@uppy/vue'
import Uppy from '@uppy/core'
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import ja_JP from '../../plugins/puppy_lang'
import FirebaseCloudStorage from "../../plugins/FirebaseCloudStorage";
import { getStorage } from "firebase/storage";
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';

import * as Validator from 'validatorjs';
Validator.useLang('ja');

import TargetSelect from './components/TargetSelect.vue'
import DraftSelect from '../common/DraftSelect.vue'

import { httpsCallable, getFunctions } from "firebase/functions";

export default {
  props: [
    'groupName',
    'groupId',
    'paramUserKbns',
    'paramTitle',
    'paramBody',
    'paramOpenDate',
    'paramCloseDate',
    'paramCommentFlg',
    'paramStudentIds',
    'paramParentIds',
    'paramTeacherIds'
  ],
  // ファイルアップロード実装 START-------------------------
  components: {
    Dashboard,
    TargetSelect,
    DraftSelect
  },
  computed: {
    uppy() {
      const storage = getStorage(this.$firebase);
      const storagePath = this.$root.uploadPath;
      var uppy = new Uppy()
      .use(FirebaseCloudStorage, { storage, storagePath });
      return uppy;
    },
  },
  mounted() {
    this.uppy.on('upload', () => {
      this.$root.loading = true;
    });
    this.uppy.on('upload-success', (file) => {
      // 配列にファイルを追加
      this.addFileList.add(file.meta.refId);
    });
    this.uppy.on('complete', () => {
      this.$root.loading = false;
    });
    this.uppy.on('file-removed', (file) => {
      // 配列からファイルを削除
      this.addFileList.delete(file.meta.refId);
    });
    // 50MB制限+10ファイル制限
    this.uppy.setOptions({
      debug: true,
      locale: ja_JP,
      autoProceed: true,
      restrictions: {
        maxFileSize: (50 * 1024 * 1024),
        maxNumberOfFiles: 10,
      },
    });
  },
  beforeDestroy () {
    this.uppy.close({ reason: 'unmount' })
  },
  // ファイルアップロード実装 END-------------------------
  data() {
    return {
      // 対象グループ候補
      groups: [],
      // 対象グループ
      selectGroupId: "",

      // コメントフラグ制御用
      commentCanIds: new Set(),
      canCommentFlg: true,

      // 対象選択フラグ
      isTargetSelect: false,
      // 対象選択ダイアログ
      viewTargetSelectDialog: false,
      // 下書き選択ダイアログ
      viewDraftSelectDialog: false,
      openTime: 0,
      
      // 対象ユーザー区分
      selectedUserKbn: [],

      // 対象選択ユーザーID
      studentIds: [],
      parentIds: [],
      teacherIds: [],

      // コメント可能なグループパターン
      canGroupPattern: ["TC130","TC140"],

      title: "",
      body: "",
      openDate: "" ,
      closeDate : "",
      addFileList: new Set(),
      mailSendFlg: true,
      commentFlg: false,
    };
  },
  async created() {
    this.defaultDateSet() ;
    this.selectGroupId = this.groupId;
    if(this.paramStudentIds)this.studentIds = this.paramStudentIds;
    if(this.paramParentIds)this.parentIds = this.paramParentIds;
    if(this.paramTeacherIds)this.teacherIds = this.paramTeacherIds;
    if(this.studentIds.length > 0 || this.parentIds.length > 0 || this.teacherIds.length > 0){
      this.isTargetSelect = true;
    }
    if(this.paramUserKbns)this.selectedUserKbn = Array.from(this.paramUserKbns);
    if(this.paramTitle)this.title = this.paramTitle;
    if(this.paramBody)this.body = this.paramBody;
    if(this.paramOpenDate)this.openDate = this.paramOpenDate;
    if(this.paramCloseDate)this.closeDate = this.paramCloseDate;
    if(this.paramCommentFlg)this.commentFlg = this.paramCommentFlg;

    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";
    const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
    await schoolAidHomeLogined({funcName:"loadGroupSelect"}).then((groups) => {
      var ret = [];
      groups.data.forEach((group) => {
        var row = {
          groupId : group.groupId,
          groupName: group.groupName,
          groupKbn: group.groupKbn,
          groupPattern: group.groupPattern,
          favoriteFlg: group.favoriteFlg,
          canViewFlg: group.canViewFlg,
        };

        // 学年・学級・任意グループ
        if(group.canViewFlg || group.groupKbn != "0" || this.canGroupPattern.includes(group.groupPattern)){
          this.commentCanIds.add(group.groupId);
        }
        
        ret.push(row);
      });
      this.groups = ret;
    });

    this.canCommentFlg = this.commentCanIds.has(this.selectGroupId);

    window.scrollTo(0,0);
  },
  methods: {
    // 連絡情報登録
    async saveContactRegist() {
      if(this.isTargetSelect){
        if (this.studentIds.length == 0 && this.parentIds.length == 0 && this.teacherIds.length == 0) {
          alert("対象を選択してください");
          return;
        }
      }

      // ローディング表示
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      console.log(this.selectedUserKbn);
      var data = {
        funcName: "saveContactRegist",
        groupId : this.selectGroupId,
        title : this.title,
        body : this.body,
        openDate : this.openDate,
        closeDate : this.closeDate,
        selectedUserKbn : this.selectedUserKbn, 
        addFileList : Array.from(this.addFileList),
        mailSendFlg: this.mailSendFlg,
        commentFlg: this.commentFlg,
        studentIds : this.studentIds,
        parentIds : this.parentIds,
        teacherIds : this.teacherIds,
      };
      await schoolAidHomeLogined(data).then((res) => {
        // ローディング非表示
        this.$root.loading = false;
        console.log(res.data);
        if (res.data == "success"){
          alert("登録しました。");
          this.$router.push({
            name: "ContactList",
          });
        } else {
          alert(res.data);
        }
      });
    },
    defaultDateSet() {
      var dt = new Date() ;
      const dtFrom = new Date(dt.setDate(dt.getDate() + 1));
      this.openDate = this.formatDate(dtFrom)  + "T07:00";
      
      dt = new Date() ;
      const dtTo =new Date( dt.setMonth(dt.getMonth()+1)) ;
      this.closeDate = this.formatDate(dtTo) + "T23:59";
      console.log("1月後",this.closeDate);
    },
    setFastOpen() {
      var dt = new Date() ;
      dt.setMinutes(dt.getMinutes() + 3);
      this.openDate = format(dt, "yyyy-MM-dd'T'HH:mm", { locale: ja });
    },
    formatDate(dt){
      var y = dt.getFullYear();
      var m = ('00' + (dt.getMonth()+1)).slice(-2);
      var d = ('00' + dt.getDate()).slice(-2);
      return (y + '-' + m + '-' + d);
    },

    clearTarget(){
      this.canCommentFlg = this.commentCanIds.has(this.selectGroupId);
      this.commentFlg = false;
      this.selectedUserKbn = [];
      this.studentIds = [];
      this.parentIds = [];
      this.teacherIds = [];
    },
    // 対象選択ダイアログを閉じる
    closeDialog(){
      this.viewTargetSelectDialog = false;
      this.viewDraftSelectDialog = false;
    },
    // ダイアログから対象ユーザー区分受け取り
    setUserKbns(list){
      this.selectedUserKbn = [];
      var set = new Set(list);
      if(set.has("0"))this.selectedUserKbn.push("0");
      if(set.has("1"))this.selectedUserKbn.push("1");
      if(set.has("8"))this.selectedUserKbn.push("8");
    },
    // ダイアログから対象生徒ID受け取り
    setStudentIds(list){
      this.studentIds = list;
    },
    // ダイアログから対象保護者ID受け取り
    setParentIds(list){
      this.parentIds = list;
    },
    // ダイアログから対象教員ID受け取り
    setTeacherIds(list){
      this.teacherIds = list;
    },
    // 下書き選択ダイアログを表示
    async openDraftSelect(){
      this.openTime = new Date().getTime();
      this.viewDraftSelectDialog = true;
    },
    // 下書き選択ダイアログから反映
    setDraft(data){
      this.title = data.title;
      this.body = data.body;
    },
    // 下書き保存
    async addDraft(){
      // 入力値チェック
      let rules = {
        連絡タイトル: 'max:50',
        連絡本文:'max:3000',
      }
      let data = {
        連絡タイトル: this.title,
        連絡本文: this.body,
      }
      let validation = new Validator(data, rules);
      if(validation.fails()){
        var hash = validation.errors.all();
        var errors = [];
        for (let key in hash) {
          errors.push(...hash[key]);
        }
        alert(errors.join("\r\n"));
        return;
      }
      // 両方未入力チェック
      if(!this.title && !this.body){
        alert("タイトルと本文が未入力です");
        return;
      }

      var param = {
        funcName: "addDraft",
        draftType: "0",
        title: this.title,
        body: this.body,
      }
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param).then((ret) => {
        if(ret.data == "success") {
          alert("下書きに登録しました。");
        }
      });
    },
  },
};
</script>